import {Grid, Button} from "@mui/material";
import TextField from "../../../../../components/Formik/TextField";
import NumberField from "../../../../../components/Formik/NumberField";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCardIcon from "@mui/icons-material/AddCard";

const Deposit = ({move, swap, push, insert, unshift, remove, pop, form}) => {
    const {values} = form;
    const maxDeposit = 1000; // Equivalent to infinite
    return (
        <div>
            {values.deposits?.length > 0 ? (
                values.deposits.map((deposit, index) => {
                    if (index < maxDeposit) {
                        return (
                            <div key={index}>
                                <Grid container sx={{mb: 2}}>
                                    <Grid xs={12} md={4} item sx={{mr: 3}}>
                                        <TextField
                                            name={`deposits[${index}].rootDeposit`}
                                            label={`Racine ${index + 1}${index === 0 ? 'er' : 'ème'} acompte`}/>
                                    </Grid>
                                    <Grid xs={12} md={5} item>
                                        <NumberField
                                            name={`deposits[${index}].amount`}
                                            label={'Montant'} asString/>
                                    </Grid>
                                    <Grid xs={12} md={2} item
                                          className={"Grid-item"}>
                                        <IconButton aria-label="add"
                                                    color={'primary'}
                                                    onClick={() => index < maxDeposit - 1 ? insert(index + 1, {}) : alert(`Maximum de ${maxDeposit} acomptes.`)}
                                        >
                                            <ControlPointIcon/>
                                        </IconButton>
                                        <IconButton aria-label="delete"
                                                    color={'primary'}
                                                    onClick={() => remove(index)}
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    }
                })
            ) : (
                <Grid container sx={{mb: 2}} justifyContent={'center'}>
                    <Button variant={'outlined'}
                            startIcon={<AddCardIcon/>}
                            onClick={() => push({})}>
                        Ajouter un acompte
                    </Button>
                </Grid>
            )}
        </div>
    );
}

export default Deposit;