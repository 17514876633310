import React, {useCallback} from 'react';
import _ from "../../../utils/lodash/number/number_format";
import Range from "../../../utils/range";
import {MenuItem} from "@mui/material";
import MuiSelect from "../../Mui/Select";

const YearFilter = ({filters = {}, setFilters = newFilters => null, ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';

    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
        },
        [filters, setFilters],
    );

    return (
        <MuiSelect name={otherProps.name ?? 'year'}
                   label={otherProps.label ?? 'Année'}
                   value={filters[otherProps.name ?? 'year'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {Range(new Date().getFullYear(), new Date().getFullYear() - 4, -1).map((el, i) => (
                <MenuItem key={i} value={el}>{el}</MenuItem>
            ))}
        </MuiSelect>
    );
};

export default YearFilter;