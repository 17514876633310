import React from 'react';
import { enqueueSnackbar } from 'notistack';
import {useUserContext} from "../../context/UserContext";

/**
 *
 * @param string topic
 * @param object currentUser
 * @returns {string}
 */
function topicFormatter(topic = '', currentUser = {}) {
    const objReplace = {
        '{uuid}': currentUser.uuid,
        '{id}': currentUser.id,
        // Add more...
    }
    const regex = new RegExp(Object.keys(objReplace).join('|'), 'g');

    return topic.replace(regex, m => objReplace[m]);
}

function MercureSubscriber({json, update, hub, topics, children}) {

    const {currentUser} = useUserContext();

    const [ready, setReady] = React.useState(false);
    // only executes once this effect (see its dependencies)
    React.useEffect(function () {
        if (!ready) {
            return;
        }

        const url = new URL(hub);

        if (typeof topics === 'string') {
            topics = [topics];
        }
        topics.forEach(function (topic) {
            url.searchParams.append('topic', topicFormatter(topic, currentUser));
        });
        console.log(Array.from(url.searchParams.values()));

        const eventSource = new EventSource(url);

        eventSource.onmessage = function (e) {
            const data = JSON.parse(e.data);
            switch (data.action) {
                case 'message':
                    enqueueSnackbar(data.content, {
                        variant: "messageComplete",
                        anchorOrigin: {horizontal: 'right', vertical: 'top'},
                        autoHideDuration: null,
                        maxSnack: 20,
                        data
                    });
                    break;
                case 'download':
                    enqueueSnackbar(data.content, {
                        variant: "downloadComplete",
                        anchorOrigin: {horizontal: 'right', vertical: 'top'},
                        autoHideDuration: null,
                        maxSnack: 20,
                        data
                    });
                    break;
            }
            if (update) {
                update(json ? JSON.parse(e.data) : e.data);
            }
        };

        window.addEventListener("beforeunload", () => eventSource.close());

        return () => eventSource.close();
    }, [ready]);

    React.useEffect(() => {
        setReady(currentUser.id !== null);
    }, [currentUser]);

    return <React.Fragment>{children}</React.Fragment>;
}

export default MercureSubscriber;