import React, {createContext, useState, useEffect, useContext} from "react";
import {run} from "../utils/services";
import {articleService} from "../services/article.service";
import {catalogService} from "../services/catalog.service";
import {hydraService} from "../utils/hydra";
import {contractService} from "../services/contract.service";
import {ARTICLE_TYPE_CODES, STRAW_CODES} from "../constants/types";

export const ContractContext = createContext(undefined);

export const ContractContextProvider = (props) => {

    const [catalog, setCatalog] = useState({
        articles: [],
        farmingTypes: [],
        contractTypes: [],
        isLoading: true
    });

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
            run(catalogService.getAllFarmingTypes),
            run(catalogService.getAllContractTypes),
        ]).then(values => {
            let [articles, farmingTypes, contractTypes] = values.map(el => el.responseData);
            articles = hydraService.getMembers(articles);
            setCatalog({articles, farmingTypes, contractTypes, isLoading: false});
        });
    }, []);

    return (
        <ContractContext.Provider value={{
            catalog
        }}>
            {props.children}
        </ContractContext.Provider>
    );
}

export function useContractContext() {
    const context = useContext(ContractContext);
    if (!context) {
        throw new Error('useContractContext must be used within a ContractContextProvider');
    }
    return context;
}
