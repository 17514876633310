import {FormControl, FormHelperText, InputLabel, Select, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useCallback} from "react";

const SelectWrapper = ({name, nullIfEmpty = false, callback, ...otherProps}) => {

    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    // useCallback
    // https://stackoverflow.com/questions/59595653/how-to-render-an-editable-table-with-formik-2-and-react-table-7
    const handleChange = useCallback((e) => {
        let {value} = e.target;
        if (nullIfEmpty && !value) {
            value = null;
        }
        setFieldValue(name, value);
        if (callback) {callback(value);}
    }, [field.value]);

    const configSelect = {
        ...field,
        ...otherProps,
        value: field.value ? field.value : '',
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return <FormControl fullWidth disabled={configSelect?.disabled} error={configSelect?.error}>
        <InputLabel>{configSelect.label}</InputLabel>
        <Select {...configSelect}>{otherProps.children}</Select>
        {configSelect?.error && <FormHelperText>{configSelect.helperText}</FormHelperText>}
    </FormControl>
}

export default SelectWrapper;