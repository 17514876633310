import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ArticleTypeService {
  async getAllArticleTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.ARTICLE_TYPE + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getArticleType(id) {
    return axios
      .get(`${ENDPOINTS.ARTICLE_TYPE}/${id}`)
      .then(handleResponse);
  }

  async addArticleType(parameters) {
    return axios
      .post(ENDPOINTS.ARTICLE_TYPE, parameters)
      .then(handleResponse);
  }

  async editArticleType(parameters) {
    return axios
      .put(`${ENDPOINTS.ARTICLE_TYPE}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeArticleType(parameters) {
    return axios
      .delete(`${ENDPOINTS.ARTICLE_TYPE}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const articleTypeService = new ArticleTypeService();
