import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import TextField from "../../../../../components/Formik/TextField";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import {useEffect, useState} from "react";
import {hydraService} from "../../../../../utils/hydra";
import {chenevisKilometricDryerService} from "../../../../../services/chenevisKilometricDryer.service";
import Loader from "../../../../Loader";
import snackBar from "../../../../../components/SnackBar";
import {getViolations} from "../../../../../utils/axios";
import ModalDelete from "../../ModalDelete";
import Box from "@mui/material/Box";
import NumberField from "../../../../../components/Formik/NumberField";
import {run} from "../../../../../utils/services";
import BackButton from "../../../../../components/BackButton";

const KilometricDryerPage = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const initialState = {
        year: currentYear,
        startScale: '',
        endScale: '',
        amount: '',

    }
    const [loading, setLoading] = useState(!!id);
    const [data, setData] = useState(initialState);

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        if (id) {
            run(chenevisKilometricDryerService.getChenevisKilometricDryer, id)
                .then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                });
        }
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(chenevisKilometricDryerService.removeChenevisKilometricDryer, {id});
        }
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(chenevisKilometricDryerService.addChenevisKilometricDryer, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(chenevisKilometricDryerService.editChenevisKilometricDryer, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={12} item>
                                <NumberField label={'Année'} name={'year'} nullIfEmpty/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField asString
                                             label={'De'}
                                             name={'startScale'}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField asString
                                             label={'À'}
                                             name={'endScale'}
                                />
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <NumberField asString
                                             label={'Indemnité (€/T)'}
                                             name={'amount'}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du barème chenevis" handleDelete={handleDelete}/>}
                </Box>
            </>
    )

}

export default KilometricDryerPage