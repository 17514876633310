import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {PARAMETERS} from "../../constants/parameters";
import Loader from "../Loader";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import Button from "../../components/Formik/SubmitButton";
import ReturnButton from "../../components/Mui/RedirectButton";
import Checkbox from "../../components/Formik/Checkbox";
import FromMember from "./FromMember";
import {shareMovementService} from "../../services/shareMovement.service";
import snackBar from "../../components/SnackBar";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {run} from "../../utils/services";
import {strawService} from "../../services/straw.service";
import {hydraService} from "../../utils/hydra";
import PartDetailPage from "./PartDetailPage";
import BackButton from "../../components/BackButton";

const cache = {};
const ShareMovementPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [isLoading, setIsLoading] = useState(!!id);
    const [isBlocked, setIsBlocked] = useState(false);
    const [data, setData] = useState({
        memberFrom: null,
        memberTo: null,
        movementDate: null,
        hempShareNumber: null,
        hempAmount: null,
        totalAmount: null,
        transferredTonnage: null,
        isTransfert: true,
    });

    useEffect(() => {
        if (!isCreation) {
            run(shareMovementService.getMovement, id).then((response) => {
                setData(response.responseData)
                setIsLoading(false);
                setIsBlocked(true);
            });
        }
    }, []);

    const callback = (value) => {
        const year = new Date(value).getFullYear();
        if (year in cache) {
            const socialShare = cache[year];
            setData({...data, hempAmount: socialShare.priceHemp});
            return;
        }
        run(strawService.getAllStrawSocialShares, {year}).then((response) => {
            const socialShare = hydraService.getMembers(response.responseData)[0];
            cache[year] = socialShare;
            setData({...data, hempAmount: socialShare.priceHemp});
        });
    }

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                enableReinitialize
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    values['memberTo'] = values.addParts ? null : values['memberTo'];
                    if (isCreation) {
                        run(shareMovementService.addMovement, values, {setFieldError, setSubmitting}).then(() => {
                            snackBar('Enregistrement effectué');
                            navigate(PARAMETERS.BACK_ROUTE);
                        }).catch(e => e);
                    }
                }}
            >
                {({values}) => (
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'} disabled={isBlocked}>Génération</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <FormDisabler disabled={isBlocked}>
                            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                  className="Grid-container">
                                <FromMember fieldName={'memberFrom'} fieldLabel={"De l'Adhérent..."}>
                                    <Checkbox name={'isTransfert'} label={'Transfert de parts'}/>
                                </FromMember>
                                {values.isTransfert &&
                                    <>
                                        <Grid xs={12} md={12} item sx={{'marginTop': "50px"}}/>
                                        <FromMember fieldName={'memberTo'} fieldLabel={"A l'Adhérent..."}/>
                                    </>
                                }
                                <PartDetailPage setData={setData}/>
                            </Grid>
                        </FormDisabler>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ShareMovementPage