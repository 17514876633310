import {createTheme, ThemeProvider} from "@mui/material";

/**
 * Create a spacial MUI theme, for disabled field in a form.
 * Use with nested theme and default props from a theme.
 * @see : https://mui.com/material-ui/customization/theming/#nesting-the-theme
 * @see : https://mui.com/material-ui/customization/theme-components/#default-props
 *
 * @param children
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
const ThemeFormikDisablerProvider = ({children, disabled = false}) => {
    const formDisabledTheme = (theme) => createTheme({
        ...theme,
        palette: {
            ...(theme.palette),
            text: {
                ...(theme.palette.text),
                ...(disabled && {disabled: '#212020'}),
            },
            action: {
                ...(theme.palette.action),
                ...(disabled && {disabled: '#464040'}),
            }
        },
        components: {
            ...(theme.components),
            MuiTextField: {
                ...(theme.components.MuiTextField),
                defaultProps: {
                    disabled: disabled
                }
            },
            MuiAutocomplete: {
                ...(theme.components.MuiAutocomplete),
                defaultProps: {
                    disabled: disabled
                }
            },
            MuiCheckbox: {
                ...(theme.components.MuiCheckbox),
                defaultProps: {
                    disabled: disabled
                }
            },
            MuiFormControl: {
                ...(theme.components.MuiFormControl),
                defaultProps: {
                    disabled: disabled
                }
            },
            MuiFormControlLabel: {
                ...(theme.components.MuiFormControlLabel),
                defaultProps: {
                    disabled: disabled
                }
            },
            MuiSelect: {
                ...(theme.components.MuiSelect),
                defaultProps: {
                    disabled: disabled
                }
            }
        }
    });

    return (
        <ThemeProvider theme={formDisabledTheme}>
            {children}
        </ThemeProvider>
    );
}

export default ThemeFormikDisablerProvider;