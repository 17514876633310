import {ENDPOINTS} from "../constants/endpoints";
import axios from "../utils/axios";
import {handleResponse} from "../utils/services";

class SenderMailerService {
    async send(parameters) {
        return axios
            .post(ENDPOINTS.SENDER_MAIL, parameters)
            .then(handleResponse);
    }
}

export const senderMailerService = new SenderMailerService();
