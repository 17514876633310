import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import {PARAMETERS} from "../../../../constants/parameters";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import * as Yup from "yup";
import Button from "../../../../components/Formik/SubmitButton";
import NumberField from "../../../../components/Formik/NumberField";
import snackBar from "../../../../components/SnackBar";
import {getViolations} from "../../../../utils/axios";
import {siloService} from "../../../../services/silo.service";
import {run} from "../../../../utils/services";
import BackButton from "../../../../components/BackButton";

const CloneSilos = () => {
    const navigate = useNavigate();
    const lastYear = new Date().getFullYear() -1;
    const currentYear = new Date().getFullYear();
    const INITIAL_FORM_STATE = {
        from_year: lastYear,
        to_year: currentYear,
    };

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        <>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    run(siloService.cloneSilos, values, {setFieldError, setSubmitting})
                        .then(() => {
                            snackBar('Duplication effectuée');
                            setTimeout(() => {
                                navigate(PARAMETERS.BACK_ROUTE);
                            }, 3000);
                        });
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button variant={'outlined'}>Dupliquer</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={6} item>
                            <NumberField label={"Année d'origine"} name={'from_year'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <NumberField label={'Année de destination'} name={'to_year'}/>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    )
}

export default CloneSilos;