import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button as MuiButton, Button, Grid, Stack} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {ticketService} from "../../../services/ticket.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import ImportMenuDialog from "../../../feature/ImportMenuDialog";
import PlcdTable from "./ImportDialog/Plcd";
import AlpixTable from "./ImportDialog/Alpix";
import TabletTable from "./ImportDialog/Tablet";
import FormExport from "./FormExport";
import {hydraService} from "../../../utils/hydra";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {run} from "../../../utils/services";
import {articleService} from "../../../services/article.service";
import {ARTICLE_TYPE_CODES, SENDER_MAIL} from "../../../constants/types";
import {useDialog} from "../../../feature/DialogProvider";
import _ from "../../../utils/lodash/number/number_format";
import {toLocaleDate} from "../../../utils/i18n";
import SenderMail from "../../../feature/SenderMail";
import HarvestYearFilter from "../../../components/Filters/HarvestYearFilter";
import MemberFilter from "../../../components/Filters/MemberFilter";
import NumberInvoiceFilter from "../../../components/Filters/NumberInvoiceFilter";
import {useFilterContext} from "../../../context/FilterContext";
import FabricationValidateTable from "./Fabrication/FabricationValidate";

const StrawTickets = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isGranted, currentUser} = useUserContext();
    const {getFilterHash} = useFilterContext();
    const [filters, setFilters] = useState(getFilterHash)
    const [catalog, setCatalog] = useState({
        tickets: [],
    });
    const [openDialog] = useDialog();

    const importMenu = useMemo(() => (
        [
            {title: 'Import ticket bascule', content: <AlpixTable setFilters={setFilters}/>, roles: [ROLES.ADMIN]},
            {title: 'Import bons tablette', content: <TabletTable setFilters={setFilters}/>, roles: [ROLES.ADMIN]},
            {title: 'Import PLCD', content: <PlcdTable setFilters={setFilters}/>, roles: [ROLES.ADMIN]},
        ]
    ), []);

    let columns = [
        {field: 'number', headerName: 'N° Bon', flex: 1},
        {field: 'harvestYear', headerName: 'Année', flex: 1},
        {
            field: 'member.company',
            headerName: 'Adhérent',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'driver.name',
            headerName: 'Chauffeur',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'weighingDate',
            headerName: 'Date de livraison',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {
            field: 'fabrication.fabricationDate',
            headerName: 'Date de fabrication',
            flex: 1,
            valueGetter: (params) => {
                if (!isGranted(ROLES.MEMBER) ||
                    (isGranted(ROLES.MEMBER) && _.get(params.row, 'fabrication.isFabricationValidate'))
                ){
                    const value = _.get(params.row, params.field);
                    return toLocaleDate(value);
                }
                return null;
            }
        },
        {
            field: 'deliveredWeight', headerName: 'Poids livré', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {field: 'ballNumber', headerName: 'Nombre de balles', flex: 1},
    ];

    if (!(isGranted(ROLES.MEMBER))) {
        columns = columns.concat([
            {
                field: 'isFabricationOK',
                headerName: 'Fabrication ?',
                flex: 1,
                type: 'boolean',
                sortable: false,
                valueGetter: (params) => _.get(params.row, 'fabrication.isFabricationValidate')
                    && _.get(params.row, 'fabrication.fabricationDate')
            },
        ]);
    }

    useEffect(() => {
        run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE})
            .then(response => {
                const articles = hydraService.getMembers(response.responseData);
                setCatalog({
                    articles,
                });
            });
        return () => null;
    }, []);

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={6} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <HarvestYearFilter filters={filters}
                                           setFilters={setFilters}
                                           name={'harvestYear'}
                                           label={'Année contrat'}
                        />
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MemberFilter filters={filters}
                                          setFilters={setFilters}
                            />
                        </ProtectedComponent>
                        <NumberInvoiceFilter filters={filters}
                                             setFilters={setFilters}
                                             request={ticketService.getAllStrawTicket}
                        />
                    </Stack>
                </Grid>
                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <SenderMail typeSender={SENDER_MAIL.STRAW_TICKETS}/>
                        </ProtectedComponent>
                        <MuiButton onClick={() => openDialog({
                            fullWidth: true,
                            maxWidth: 'md',
                            children: <FormExport catalog={catalog}
                                                  isGranted={isGranted}
                                                  currentUser={currentUser}
                                                  title={'Exporter un/des ticket(s) paille'}/>
                        })}>
                            Edition
                        </MuiButton>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MuiButton onClick={() => openDialog({
                                fullWidth: true,
                                maxWidth: false,
                                children: <FabricationValidateTable/>
                            })}>
                                Rendre visible l'onglet fabrication
                            </MuiButton>
                            <ImportMenuDialog data={importMenu}/>
                            <Button component={Link} variant={'contained'} to={`${location.pathname}/add`}
                                    sx={{mb: 0, mt: "0!important"}}>
                                Ajouter un ticket paille
                            </Button>
                        </ProtectedComponent>
                    </Stack>
                </Grid>
            </Grid>
            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={ticketService.getAllStrawTicket}
                filters={filters}
                customPagination
            />
        </>
    )
}

export default StrawTickets