import snackBar from "../../../components/SnackBar";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Button as MuiButton,
    Box
} from "@mui/material";
import {Form, Formik} from "formik";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import SubmitButton from "../../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {supplyInvoiceService} from "../../../services/supplyInvoice.service";
import {useDialog} from "../../../feature/DialogProvider";
import {run} from "../../../utils/services";

const GenerationDialog = ({catalog, setFilters}) => {
    const INITIAL_FORM_STATE = {
        invoice_type: 'Approvisionnement',
        article_type_id: null,
        from_member_id: null,
        to_member_id: null,
        year: null,
        invoice_date: null,
        expiry_date: null,
        deposit: null,
    }
    const FORM_VALIDATION = Yup.object().shape({});

    const [, closeDialog] = useDialog();

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={INITIAL_FORM_STATE}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(supplyInvoiceService.generateInvoicePdf, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar('Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.');
                        setFilters(prevState => ({...prevState}));
                        closeDialog();
                    });
            }}
        >
            {({values}) => (
                <Form>
                    <DialogTitle className="headerModal">
                        <div><h3>Génération</h3></div>
                    </DialogTitle>
                    <DialogContent className={"contentModal"}>
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'from_member_id'}
                                        label={'De l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'ASC'}}
                                        hydra
                                        className="Autocomplete"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'to_member_id'}
                                        label={'A l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                        hydra
                                        className="Autocomplete"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className="Grid-grid">
                                    <SelectYear
                                        name={'year'}
                                        label={'Année'}
                                        reverse
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'article_type_id'}
                                            label={'Type d\'article'}
                                            disabled={values?.articles?.length > 0}
                                    >
                                        <MenuItem
                                            value={null}
                                        >
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {catalog.articleTypeList.map((el, i) => (
                                            <MenuItem
                                                key={i}
                                                value={el['@id']}
                                            >
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6} className="Grid-grid">
                                    <Select name={'deposit'} label={'Dépôt'} nullIfEmpty>
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {catalog.depositList
                                            ?.sort((a, b) => a.name.localeCompare(b.name))
                                            .map((el, i) => {
                                                return <MenuItem key={i}
                                                                 value={el.name}>{el.name}</MenuItem>
                                            })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'invoice_date'} label={'Date de facture'} nullIfEmpty/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'expiry_date'} label={'Date d\'échéance'} nullIfEmpty/>
                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default GenerationDialog;