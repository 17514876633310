export const FORM = {
    ADD: "Ajouter",
    EDIT: "Mettre à jour",
    CANCEL: "Annuler",
    EXPORT: "Exporter",
    BUTTON_CANCEL: 'cancel',
    BUTTON_EXPORT: 'export',
    BUTTON: 'button',
    SEPARATOR: 'separator',
    SPACE: 'space',
    CUSTOM: 'custom',
    SUBMIT: "submit"
}

