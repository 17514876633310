import {ENDPOINTS} from "../constants/endpoints";
import axios from "../utils/axios";
import {handleResponse} from "../utils/services";
import moment from "moment";

class MemberService {
    async getAllMembers(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.MEMBER + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getMemberByUrl(url) {
        return axios
            .get(url)
            .then(handleResponse)
    }

    async getMember(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}`)
            .then(handleResponse);
    }

    async addMember(parameters) {
        return axios
            .post(ENDPOINTS.MEMBER, parameters)
            .then(handleResponse);
    }

    async editMember(parameters) {
        return axios
            .put(`${ENDPOINTS.MEMBER}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeMember(parameters) {
        return axios
            .delete(`${ENDPOINTS.MEMBER}/${parameters.id}`)
            .then(handleResponse);
    }

    async getMemberContracts(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/contracts`)
            .then(handleResponse);
    }

    async getMemberContacts(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/contacts?timestamp=${moment().unix()}`)
            .then(handleResponse);
    }

    async getMemberAreas(id, searchParameters = {}) {
        const queryString = (new URLSearchParams(searchParameters)).toString();
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/areas` + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async addSiloDistance(parameters) {
        return axios
            .post(`${ENDPOINTS.SILO_DISTANCE}`, parameters)
            .then(handleResponse);
    }

    async editSiloDistance(parameters) {
        return axios
            .put(`${ENDPOINTS.SILO_DISTANCE}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeSiloDistance(parameters) {
        return axios
            .delete(`${ENDPOINTS.SILO_DISTANCE}/${parameters.id}`)
            .then(handleResponse);
    }

    async getMemberCapital(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/capital?timestamp=${moment().unix()}`)
            .then(handleResponse)
            .catch(e => e); // Important for Member/CapitalPage.js
    }

    async getMemberCapitalHistories(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/capital_histories?timestamp=${moment().unix()}`)
            .then(handleResponse);
    }

    async getMemberSiloDistances({id, searchParameters = {}}) {
        const queryString = (new URLSearchParams(searchParameters)).toString();
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/silo/distances` + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getMemberEngagements(id) {
        return axios
            .get(`${ENDPOINTS.MEMBER}/${id}/engagements`)
            .then(handleResponse);
    }
}

export const memberService = new MemberService();