import {
    Autocomplete, Button, Divider,
    Grid,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import ContactDialog from "./ContactDialog";
import {memberService} from "../../services/member.service";
import {useParams} from "react-router-dom";
import Loader from "../Loader";
import {hydraService} from "../../utils/hydra";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import {run} from "../../utils/services";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {useUserContext} from "../../context/UserContext";

const ContactPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [contactSelected, setContactSelected] = useState(null);

    /**
     * Dialog State
     */
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpenDialog = (isAdd = false) => (e) => {
        if (isAdd) {
            setContactSelected(null);
        }
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    /**
     * End dialog State
     */

    useEffect(() => {
        Promise.all([
            run(memberService.getMemberContacts, id),
        ]).then((values) => {
            setContacts(hydraService.getMembers(values[0].responseData));
            setIsLoading(false);
        });
    }, []);

    const handleContactChange = (e, value) => {
        setContactSelected(value);
    }

    return (
        isLoading ? <Loader/> :
            <FormDisabler disabled={isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                <ContactDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    data={contactSelected}
                />
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid xs={12} md={12} item>
                        <Autocomplete
                            disablePortal
                            value={contactSelected}
                            options={contacts}
                            getOptionLabel={(option) => `${option.lastname} ${option.firstname}`}
                            onChange={handleContactChange}
                            renderInput={(params) => <TextField {...params} label="Selectionner un contact" fullWidth/>}
                            disabled={false}
                        />
                    </Grid>
                    {contactSelected &&
                        <Grid xs={12} md={12} item>
                            <Button variant={'outlined'} onClick={handleClickOpenDialog(false)}>Voir ce contact</Button>
                        </Grid>}
                </Grid>
                <Divider/>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container"
                      sx={{"paddingLeft": "24px"}}>
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                        <Button variant={'contained'} onClick={handleClickOpenDialog(true)}>Ajouter un contact</Button>
                    </ProtectedComponent>
                </Grid>
            </FormDisabler>
    )
}

export default ContactPage;