import { ENDPOINTS } from "../constants/endpoints";
import axios, {setToken} from "../utils/axios";
import { handleResponse } from "../utils/services";

class AuthentificationService {
  async login(payload) {
    return axios
      .post(`${ENDPOINTS.AUTHENTIFICATION}`, payload)
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        setToken(responseData.token);
        return response;
      })
      .catch((error) => error);
  }

  async register(payload) {
    return axios
      .post(`${ENDPOINTS.AUTHENTIFICATION}/Register`, payload)
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        setToken(responseData.token);
        return response;
      })
      .catch((error) => error);
  }

  async logout() {
    setToken(null);
    return { responseData: null, statusText: "OK" };
  }

  async forgottenPassword({ email }) {
    return axios
      .post(`${ENDPOINTS.RESET_PASSWORD}`, { email })
      .then(handleResponse);
  }

  async resetPassword(values) {
    return axios
      .post(`${ENDPOINTS.UPDATE_PASSWORD}`, values)
      .then(handleResponse)
  }

  async autoconnection(token) {
      return axios
          .post(`${ENDPOINTS.AUTO_CONNECTION}`, {member_token: token})
          .then(handleResponse);
  }
}

export const authentificationService = new AuthentificationService();
