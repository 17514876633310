import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import {strawService} from "../../../../../services/straw.service";
import {PARAMETERS} from "../../../../../constants/parameters";
import Loader from "../../../../Loader";
import {Form, Formik} from "formik";
import snackBar from "../../../../../components/SnackBar";
import {hydraService} from "../../../../../utils/hydra";
import {Grid, Stack} from "@mui/material";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import Box from "@mui/material/Box";
import ModalDelete from "../../ModalDelete";
import NumberField from "../../../../../components/Formik/NumberField";
import {run} from "../../../../../utils/services";
import {FilterContext} from "../../../../../context/FilterContext";
import BackButton from "../../../../../components/BackButton";

const SocialSharesPage = () => {
    const navigate = useNavigate();
    const {getHashRef} = useContext(FilterContext);
    const {id} = useParams();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const [data, setData] = useState({
        year: currentYear,
        priceHemp: '',
        priceFlax: '',
        numberUnitPerTon: '',
    });
    const [loading, setLoading] = useState(true);
    const FORM_VALIDATION = Yup.object().shape({
        year: Yup.number().required("L'année est obligatoire"),
        priceHemp: Yup.string().required("Le prix d'une part chanvre doit être renseigné"),
        priceFlax: Yup.string().required("Le prix d'une part lin doit être renseigné"),
        numberUnitPerTon: Yup.string().required("Le nombre de part doit être renseigné"),
    });

    useEffect(() => {
        (async () => {
            if (!isCreation) {
                run(strawService.getStrawSocialShares, id).then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(strawService.removeStrawSocialShares, {id});
        }
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(strawService.addStrawSocialShares, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('L\'ajout à bien été effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(strawService.editStrawSocialShares, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('La modification a bien été effectuée');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={6} item>
                                <NumberField label={'Année'} name={'year'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField asString name={'priceHemp'} label={'Prix - Part chanvre'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <NumberField asString name={'priceFlax'} label={'Prix - Part lin'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField asString name={'numberUnitPerTon'} label={'Nombre de parts à la tonne'}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du barème" handleDelete={handleDelete}/>}
                </Box>
            </>
    )


}

export default SocialSharesPage;