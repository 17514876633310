import Table from "../../components/Mui/DataGrid";
import {useFormikContext, getIn} from "formik";
import {Typography, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useEffect, useMemo, useState, useContext} from "react";
import AddEditStrawTable from "./AddEditStrawTable";
import {run} from "../../utils/services";
import {strawService} from "../../services/straw.service";
import {hydraService} from "../../utils/hydra";
import Alert from "../../components/Alert";
import {colorDriverService} from "../../services/colorDriver.service";
import {GridActionsCellItem} from "@mui/x-data-grid";
import * as React from "react";
import {ROLES} from "../../constants/roles";
import {useUserContext} from "../../context/UserContext";

const StockStrawTable = ({name, remove, push, replace}) => {
    const {values} = useFormikContext();
    const [editRow, setEditRow] = useState();
    const [colorDrivers, setColorDrivers] = useState([]);
    const formikSlice = useMemo(() => getIn(values, name) || [], [values, name]);
    const {isGranted} = useUserContext();
    ; // Create index

    const rows = useMemo(() => {
        const data = formikSlice
            .map((el, i) => ({ ...el, id: i }));
        const sum = data.reduce((acc, i) => {
            const tonnage = acc.tonnage + Number(i.tonnage);
            const balesNumber = acc.balesNumber + Number(i.balesNumber);
            const averageBaleWeight = balesNumber > 0 ? tonnage / balesNumber : tonnage;
            return {
                tonnage: tonnage,
                balesNumber: balesNumber,
                averageBaleWeight: averageBaleWeight
            }
        }, {
            tonnage: 0,
            balesNumber: 0,
            averageBaleWeight: 0,
        });

        return data.concat({
            id: formikSlice.length,
            color: 'Total',
            action: 'Total',
            tonnage: sum.tonnage.toFixed(2),
            balesNumber: sum.balesNumber.toFixed(2),
            averageBaleWeight: sum.averageBaleWeight.toFixed(2)

        });
    }, [formikSlice]);

    useEffect(() => {
        let active = true;
        loadDriverColors();
        return () => {
            active = false
        }

        async function loadDriverColors() {
            const response = await run(colorDriverService.getAllColorsDrivers, {pagination: false, year: values.year});
            setColorDrivers(hydraService.getMembers(response.responseData));
        }
    }, [values.year]);


    const [open, setOpen] = useState(false);
    const [row, setRow] = useState();

    const handleClickOpen = (row) => {
        setRow(row)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRowClick = (params) => {
        setEditRow({...params.row});
    };

    const handleRemove = () => {
        run(strawService.removeDeclarationStrawStock, {id: hydraService.getIdFromIri(row)}).then(() => {
            remove(row.id);
            handleClose();
        });
    }

    const columns = [
        {
            field: 'color',
            headerName: '',
            flex: 1,
            sortable: false,
        },
        {field: 'tonnage', headerName: 'Tonnage', flex: 1},
        {field: 'balesNumber', headerName: 'Nombre de balles', flex: 1},
        {field: 'averageBaleWeight', headerName: 'Poids moyen des balles', flex: 1},
        {
            field: 'action',
            type: 'actions',
            headerName: '',
            flex: 1,
            sortable: false,
            getActions: ({row, id}) => {
                if (row['action'] || isGranted(ROLES.MEMBER)) {
                    return [];
                }
                const index = rows.findIndex(el => el.id === id);
                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        onClick={() => handleRowClick({row, index})}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={() => handleClickOpen(row, index)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    
    return (
        <>
            <Alert handleClose={handleClose} open={open} handleRemove={handleRemove} />
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography color="primary" fontWeight="bold">Stock paille</Typography>
                <AddEditStrawTable colorDrivers={colorDrivers} title="Stock paille" replace={replace} rows={rows} push={push} name={name} toUpdatedData={editRow} />
            </Stack>
            <Table
                className={"testTableClass"}
                rows={rows}
                columns={columns}
                hideFooter
            />
        </>
    );
}
export default StockStrawTable;