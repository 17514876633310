import {Grid} from "@mui/material";
import {FieldArray, useFormikContext} from "formik";
import NumberField from "../../../components/Formik/NumberField";

const HumidityPage = ({humidities}) => {
    return (
        <>
            <h4 style={{paddingLeft: 24+'px'}}>Taux humidité</h4>
            <Grid container spacing={3} sx={{marginTop: -15+'px'}}>
                <FieldArray name={'humidities'}>
                    <>
                        {humidities.map((el, i) => (
                            <Grid key={i} item xs={12} md={2}>
                                <NumberField asString
                                             key={i}
                                             name={`humidities[${i}].value`}
                                             label={`Taux ${i + 1}`}
                                />
                            </Grid>
                        ))}
                    </>
                </FieldArray>
            </Grid>
        </>
    );
}

export default HumidityPage;