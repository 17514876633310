import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, IconButton, InputAdornment, Stack} from "@mui/material";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import {useEffect, useState} from "react";
import {driverService} from "../../../../services/driver.service";
import snackBar from "../../../../components/SnackBar";
import {hydraService} from "../../../../utils/hydra";
import Checkbox from "../../../../components/Formik/Checkbox";
import ModalDelete from "../ModalDelete";
import Loader from "../../../Loader";
import Box from "@mui/material/Box";
import {run} from "../../../../utils/services";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BackButton from "../../../../components/BackButton";

const DriversPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [loading, setLoading] = useState(!!id);
    const [showPlainPassword, setShowPlainPassword] = useState(false);
    const [data, setData] = useState({
        code: '',
        name: '',
        company: '',
        active: true,
        // plainPassword: '', CDL-326
        tabletPassword: '',
        memberTransport: false,
    });

    useEffect(() => {
        (async () => {
            if (!isCreation) {
                run(driverService.getDriver, id).then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                })
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        run(driverService.removeDriver, {id})
            .then(() => {
                navigate(PARAMETERS.BACK_ROUTE);
            })
    }

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setFieldValue, setSubmitting}) => {
                        if (!id) {
                            run(driverService.addDriver, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    setFieldValue('plainPassword', '');
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(driverService.editDriver, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    setFieldValue('plainPassword', '');
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={6} item>
                                <TextField label={'Code'} name={'code'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Nom de la société'} name={'company'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Nom du chauffeur'} name={'name'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField type={'text'} label={'Mot de passe tablette'} name={'tabletPassword'}/>
                            </Grid>
                            {/*<Grid xs={12} md={12} item>*/}
                            {/*    <TextField type={!showPlainPassword ? 'password' : 'text'}*/}
                            {/*               label={'Mot de passe chauffeur'}*/}
                            {/*               name={'plainPassword'}*/}
                            {/*               InputProps={{*/}
                            {/*                   endAdornment:*/}
                            {/*                       <InputAdornment position="end">*/}
                            {/*                           <IconButton*/}
                            {/*                               aria-label="toggle password visibility"*/}
                            {/*                               onClick={() => setShowPlainPassword(prevState => !prevState)}*/}
                            {/*                               edge="end"*/}
                            {/*                           >*/}
                            {/*                               {showPlainPassword ? <VisibilityOff/> : <Visibility/>}*/}
                            {/*                           </IconButton>*/}
                            {/*                       </InputAdornment>*/}
                            {/*               }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid xs={12} md={12} item>
                                <Checkbox name={'active'} label={'Actif'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <Checkbox name={'memberTransport'} label={'Transport adhérent'}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du chauffeur" handleDelete={handleDelete}/>}
                </Box>
            </>
    )

}

export default DriversPage