import {Button} from "@mui/material";
import {useFormikContext} from "formik";

const SubmitButtonWrapper = ({children, ...otherProps}) => {
    const {submitForm, isSubmitting} = useFormikContext();
    const handleSubmit = () => {
        submitForm().finally((response) => {
        });
    }
    const configButton = {
        color: 'primary',
        variant: 'contained',
        disabled: isSubmitting,
        onClick: handleSubmit,
        ...otherProps,
    }
    return (
        <Button {...configButton}>
            {children ?? 'Enregistrer'}
        </Button>
    );
}

export default SubmitButtonWrapper;