import {Box, Grid, Paper, styled} from "@mui/material";
import RedirectButton from "../../components/Mui/RedirectButton";
import {Link} from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ParkIcon from '@mui/icons-material/Park';
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";

const Home = () => {
    const primaryColor = "#065826";

    const ItemButton = styled(RedirectButton)(({theme}) => ({
        fontSize: 58,
        width: '300px',
    }));

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        lineHeight: '60px',
    }));

    const gridStyles = {
        backgroundColor: "transparent!important",
        padding: '0!important',
    };

    const boxStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: "20px",
    };

    const itemStyle = {
        backgroundColor: "#fff",
        boxShadow: "0 2px 8px 0 hsl(0deg 0% 47% / 10%)",
        padding: "40px 20px 55px",
        color: primaryColor,
        fontSize: "13px"
    };

    const iconItem = {
        width: "125px",
        height: "125px",
        borderRadius: "50%",
        backgroundColor: primaryColor,
        border: "2px solid #D9D9D9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 'auto',
        color: "#FFF",
        marginBottom: "15px"
    }

    const linkStyle = {
        textDecoration: "none",
    }

    return (
        <Grid container spacing={3} sx={gridStyles} className="Grid-root">
            <Grid item xs={12} sx={gridStyles}>
                <Box sx={boxStyle}>
                    <Link style={linkStyle} to={"/members"}>
                        <Item elevation={12} sx={itemStyle}>
                            <Box sx={iconItem}><GroupIcon /></Box>
                            Adhérents
                        </Item>
                    </Link>
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                        <Link style={linkStyle} to={"/straw/tickets"}>
                            <Item elevation={12} sx={itemStyle}>
                                <Box sx={iconItem}><ConfirmationNumberIcon /></Box>
                                Tickets paille
                            </Item>
                        </Link>
                        <Link style={linkStyle} to={"/chenevis/tickets"}>
                            <Item elevation={12} sx={itemStyle}>
                                <Box sx={iconItem}><ParkIcon /></Box>
                                Tickets chenevis
                            </Item>
                        </Link>
                    </ProtectedComponent>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Home;