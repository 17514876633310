import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class DriverService {
  async getAllDrivers(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.DRIVER + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getDriver(id) {
    return axios
      .get(`${ENDPOINTS.DRIVER}/${id}`)
      .then(handleResponse);
  }

  async addDriver(parameters) {
    return axios
      .post(ENDPOINTS.DRIVER, parameters)
      .then(handleResponse);
  }

  async editDriver(parameters) {
    return axios
      .put(`${ENDPOINTS.DRIVER}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeDriver(parameters) {
    return axios
      .delete(`${ENDPOINTS.DRIVER}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const driverService = new DriverService();
