import * as Yup from "yup";
import {FieldArray, Form, Formik} from "formik";
import Button from "../../../components/Formik/SubmitButton";
import ReturnButton from "../../../components/Mui/RedirectButton";
import {Button as MuiButton, Grid, MenuItem, Stack} from "@mui/material";
import TextField from "../../../components/Formik/TextField";
import DateNative from "../../../components/Formik/Date/Native";
import Select from "../../../components/Formik/Select";
import NumberField from "../../../components/Formik/NumberField";
import {useEffect, useState} from "react";
import {memberService} from "../../../services/member.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../Loader";
import snackBar from "../../../components/SnackBar";
import {catalogService} from "../../../services/catalog.service";
import {supplyInvoiceService} from "../../../services/supplyInvoice.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import {run} from "../../../utils/services";
import PrintIcon from "@mui/icons-material/Print";
import Download from "../../../components/Download";
import {SelectYear} from "../../../components/Formik";
import ActionInvoice from "../ActionInvoice";
import FormDisabler from "../../../components/Formik/ThemeFormikDisablerProvider";
import {useUserContext} from "../../../context/UserContext";
import {ROLES} from "../../../constants/roles";
import ArticleTable from "./ArticleTable";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {articleTypeService} from "../../../services/articleType.service";
import {vatCodeService} from "../../../services/vatCode.service";

const SupplyInvoice = () => {
    const {isGranted} = useUserContext();
    const {id} = useParams();
    const navigate = useNavigate();
    const INITIAL_FORM_STATE = {
        member: null,
        number: null,
        invoiceDate: null,
        credit: false,
        reference: null,
        expiryDate: null,
        amountIncludingVat: null,
        amountExcludingVat: null,
        vatAmount: null,
        articleType: null,
        articles: [],
        tickets: []
    }
    const FORM_VALIDATION = Yup.object().shape({});

    const [loading, setLoading] = useState(!!id);
    const [catalog, setCatalog] = useState({
        'articles': [],
        'articleTypes': [],
        'invoiceTypes': [],
        'vatCodes': []

    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [memberSelected, setMemberSelected] = useState(null);
    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false}),
            run(articleTypeService.getAllArticleTypes, {pagination: false}),
            run(catalogService.getAllCatalogInvoicesTypes, {pagination: false}),
            run(vatCodeService.getAllVatCodes, {pagination: false}),
        ]).then((values) => {
            let [articles, articleTypes, invoiceTypes, vatCodes] = values.map(el => el.responseData);
            articles = hydraService.getMembers(articles);
            articleTypes = hydraService.getMembers(articleTypes);
            vatCodes = hydraService.getMembers(vatCodes);
            setCatalog({articles, articleTypes, invoiceTypes, vatCodes});

            if (!!id) {
                run(supplyInvoiceService.getSupplyInvoice, id)
                    .then((response) => {
                        setData(response.responseData);
                        memberService.getMember(hydraService.getIdFromIri(response.responseData.member))
                            .then((response) => {
                                setMemberSelected(response.responseData);
                                setLoading(false);
                            });
                    })
            } else {
                setLoading(false);
            }
        });
        return () => null;
    }, [id]);

    const INVOICE_TYPE_FILTER = [
        'Approvisionnement',
    ].concat([data.reference]);

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting, setValues}) => {
                        if (!id) {
                            run(supplyInvoiceService.addSupplyInvoice, values, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                    setValues(response.responseData)
                                    navigate(response.responseData['@id']);
                                });
                        } else {
                            run(supplyInvoiceService.editSupplyInvoice, {id, ...values}, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <FormDisabler disabled={data.isBlocked || isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                            <Grid container spacing={3} className="Grid Grid-root">
                                <Grid item xs={6} className="Grid">
                                </Grid>
                                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                                    <Stack spacing={1} direction={'row'}>
                                        {data.file &&
                                            <MuiButton variant={'outlined'}
                                                       startIcon={<PrintIcon/>}
                                                       onClick={() => Download(data.file.hostFilename)}
                                            >Imprimer</MuiButton>}
                                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                            <Button variant={'outlined'} disabled={data.isBlocked}></Button>
                                        </ProtectedComponent>
                                        <ReturnButton to={'/supply/invoices'}></ReturnButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                  className="Grid-container">
                                <Grid item xs={12} md={6}>
                                    <TextField name={'number'} label={'N° Facture'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectYear
                                        name={'harvestYear'}
                                        label={'Année'}
                                        reverse
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'invoiceDate'} label={'Date Facture'}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'expiryDate'} label={'Date échéance'}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'member'}
                                        label={'Adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        value={memberSelected}
                                        hydra
                                        nullIfEmpty
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'reference'}
                                            label={'Référence'}
                                    >
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {catalog.invoiceTypes
                                            .filter(el => INVOICE_TYPE_FILTER.includes(el))
                                            .map((el, i) => <MenuItem key={i} value={el}>{el}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid item md={6}/>
                                <Grid item xs={12} md={6}>
                                    <Select name={'articleType'}
                                            label={'Type d\'article'}
                                    >
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {catalog.articleTypes
                                            .filter(el => ['SEMENCES', 'FICELLES'].includes(el.label))
                                            .sort((a, b) => a.label.localeCompare(b.label))
                                            .map((el, i) => <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString name={'amountExcludingVat'} label={'Montant HT'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString name={'vatAmount'} label={'Montant TVA'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString name={'amountIncludingVat'} label={'Montant TTC'} disabled/>
                                </Grid>
                            </Grid>
                            <FieldArray name={'articles'}
                                        render={props => <ArticleTable {...props} catalog={catalog}
                                                                       isManual={data.tickets.length === 0}
                                        />}
                            />
                        </FormDisabler>
                    </Form>
                </Formik>
                {id && <ActionInvoice data={data}/>}
            </>
    );
}

export default SupplyInvoice;