import {TextField} from "@mui/material";
import {useField, useFormikContext} from 'formik';

/**
 * This component is based on TextField Component of MUI
 * You can add all props defined for this component.
 *
 * This MUI TextField is displayed with type = "number"
 * If asString props is added, then value is converted with type "String" by function toFixed.
 * You can define digit decimal with "decimal" props (default : 2).
 *
 * @param string name
 * @param bool asString
 * @param integer decimal
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const NumberFieldWrapper = ({name, asString = false, decimal = 2, nullIfEmpty = false, ...otherProps}) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name)

    const handleBlur = (e) => {
        let value = e.target.value;
        if (isNaN(value)) {
            value = '';
        }
        if (value === '' && nullIfEmpty) {
            value = null;
        }
        if (value) {
            value = Number(value).toFixed(decimal);
            if (asString === false) {
                value = Number(value);
            }
        }
        if (field.value !== value) {
            setFieldValue(name, value);
        }
    }

    const configTextField = {
        ...field,
        type: 'tel', // FIX : https://stackoverflow.com/questions/49187448/html-input-type-number-is-not-taking-0
        value: field.value !== null ? field.value : '' ,
        fullWidth: true,
        variant: otherProps.variant ?? 'outlined',
        onBlur: handleBlur,
        inputProps: {
            onFocus: (e) => e.target.select(),
        },
        ...otherProps
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    return <TextField {...configTextField} />
}

export default NumberFieldWrapper;