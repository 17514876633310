import {Autocomplete, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useCallback} from "react";

/**
 * This component is based on Autocomplete Component of MUI
 * You can add all props defined for this component.
 *
 * If hydra props is added, then only the @id property will be saved in formik
 *
 * @param string name
 * @param array options
 * @param func getOptionLabel
 * @param func isOptionEqualToValue
 * @param bool hydra
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const AutocompleteWrapper = ({name, options, getOptionLabel, isOptionEqualToValue, hydra, ...otherProps}) => {

    options = options||[];
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    // https://stackoverflow.com/questions/59249886/react-formik-material-ui-autocomplete-how-can-i-populate-value-inside-of-autoco
    const handleChange = useCallback((e, value) => {
        setFieldValue(name, isHydra(value))
    }, [field.value]);

    const isHydra = (value) => {
        if (hydra) {
            return value ? value['@id'] : null;
        }
        return value;
    }

    const configAutocomplete = {
        disablePortal: true,
        fullWidth: true,
        value: hydra && field.value ? options.find(el => el['@id'] === field.value) : (field.value !== '' ? field.value : null),
        ...(getOptionLabel && {getOptionLabel: getOptionLabel}), // Callback
        ...(isOptionEqualToValue && {isOptionEqualToValue: isOptionEqualToValue}),
        onChange: handleChange,
    }

    const configTextField = {
        ...field,
        ...otherProps,
        variant: otherProps.variant ?? 'outlined',
        placeholder: otherProps.placeholder ?? "Rechercher..."
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    return <Autocomplete
        {...configAutocomplete}
        options={options}
        noOptionsText="Pas de résultat"
        renderInput={(params) => (
            <TextField {...params} {...configTextField} onChange={()=>null} />
        )}
    />
}

export default AutocompleteWrapper;