import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ChevenisInvoiceService {
  async getAllChevenisInvoices(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.CHENEVIS_INVOICE + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getChevenisInvoice(id) {
    return axios
      .get(`${ENDPOINTS.CHENEVIS_INVOICE}/${id}`)
      .then(handleResponse);
  }

  async addChevenisInvoice(parameters) {
    return axios
      .post(ENDPOINTS.CHENEVIS_INVOICE, parameters)
      .then(handleResponse);
  }

  async editChevenisInvoice(parameters) {
    return axios
      .put(`${ENDPOINTS.CHENEVIS_INVOICE}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeChevenisInvoice(parameters) {
    return axios
      .delete(`${ENDPOINTS.CHENEVIS_INVOICE}/${parameters.id}`)
      .then(handleResponse);
  }

  async generateInvoicePdf(parameters) {
    return axios
        .post(`${ENDPOINTS.INVOICE}/chenevis/generate/payment`, parameters)
        .then(handleResponse);
  }

  async generateInvoiceCreditPdf(parameters) {
    return axios
        .post(`${ENDPOINTS.INVOICE}/chenevis/generate/payment/credit`, parameters)
        .then(handleResponse);
  }

  async generateInvoiceCredit(parameters) {
    return axios
        .post(`${ENDPOINTS.CHENEVIS_INVOICE}/${parameters.id}/credit`, parameters)
        .then(handleResponse);
  }
}

export const chenevisInvoiceService = new ChevenisInvoiceService();
