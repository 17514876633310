import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class TicketService {
  async getAllStrawTicket(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.STRAW_TICKET + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getStrawTicket(id) {
    return axios
      .get(`${ENDPOINTS.STRAW_TICKET}/${id}`)
      .then(handleResponse);
  }

  async addStrawTicket(parameters) {
    return axios
      .post(ENDPOINTS.STRAW_TICKET, parameters)
      .then(handleResponse);
  }

  async editStrawTicket(parameters) {
    return axios
      .put(`${ENDPOINTS.STRAW_TICKET}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeStrawTicket(parameters) {
    return axios
      .delete(`${ENDPOINTS.STRAW_TICKET}/${parameters.id}`)
      .then(handleResponse);
  }

  async getAllSupplyTicket(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.SUPPLY_TICKET + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getNotVisibleSupplyTicket(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.NOTVISIBLE_SUPPLY_TICKET + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getSupplyTicket(id) {
    return axios
        .get(`${ENDPOINTS.SUPPLY_TICKET}/${id}`)
        .then(handleResponse);
  }

  async addSupplyTicket(parameters) {
    return axios
        .post(ENDPOINTS.SUPPLY_TICKET, parameters)
        .then(handleResponse);
  }

  async editSupplyTicket(parameters) {
    return axios
        .put(`${ENDPOINTS.SUPPLY_TICKET}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeSupplyTicket(parameters) {
    return axios
        .delete(`${ENDPOINTS.SUPPLY_TICKET}/${parameters.id}`)
        .then(handleResponse);
  }

  async removeTicketArticle(id) {
    return axios
        .delete(`${ENDPOINTS.SUPPLY_TICKET_ARTICLES}/${id}`)
        .then(handleResponse);
  }

  async getAllTabletTicket(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.TABLET_TICKET + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async editTabletTicket(parameters) {
    return axios
        .put(`${ENDPOINTS.TABLET_TICKET}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeTabletTicket(parameters) {
    return axios
        .delete(`${ENDPOINTS.TABLET_TICKET}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawTicketFabrication(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_TICKET_FABRICATION + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async updateFabricationValidate(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_TICKET_FABRICATION_VALIDATE, parameters)
        .then(handleResponse);
  }
}

export const ticketService = new TicketService();
