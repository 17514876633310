import {useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton} from "@mui/material";

const Confirmation = ({
                          buttonLabel = 'Supprimer',
                          modalTitle = 'Your confirmation',
                          modalContent = 'Execute this code ?',
                          handleConfirm = () => {},
                          ...otherProps
                      }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onConfirm = () => {
        handleConfirm();
        handleClose();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "700px",
        maxHeight: "85vh",
        minHeight: '20vh',
        backgroundColor: '#FFF',
        boxShadow: 24,
    };

    const configButton = {
        variant: otherProps.variant || 'contained',
        color: otherProps.color || 'error',
        onClick: handleOpen,
        startIcon: otherProps.startIcon || <DeleteIcon/>,
        ...otherProps
    }

    const configIconButton = {
        variant: otherProps.variant || 'contained',
        color: otherProps.color || 'error',
        onClick: handleOpen,
        ...otherProps
    }

    return (
        <div>
            {buttonLabel ? <Button {...configButton}>{buttonLabel}</Button> : <IconButton {...configIconButton}>{configButton.startIcon}</IconButton>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={style}>
                    <Box className="headerModal">
                        <Box className="titleModal">{modalTitle}</Box>
                    </Box>
                    <Box className="contentModal">
                        <p>{modalContent}</p>
                    </Box>
                    <Box className="footerModal">
                        <Button className="buttonDelete" onClick={onConfirm}>Oui</Button>
                        <Button sx={{marginTop: 0, marginLeft: "10px"}} onClick={handleClose}>Non</Button>
                    </Box>
                </Box>
            </Modal>
        </div>

    )
}

export default Confirmation