import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class InvoicingCompaniesService {
    async getAllInvoicingCompanies(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.INVOICING_COMPANIES + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getInvoicingCompanies(id) {
        return axios
            .get(`${ENDPOINTS.INVOICING_COMPANIES}/${id}`)
            .then(handleResponse);
    }

    async addInvoicingCompanies(parameters) {
        return axios
            .post(ENDPOINTS.INVOICING_COMPANIES, parameters)
            .then(handleResponse);
    }

    async editInvoicingCompanies(parameters) {
        return axios
            .put(`${ENDPOINTS.INVOICING_COMPANIES}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeInvoicingCompanies(parameters) {
        return axios
            .delete(`${ENDPOINTS.INVOICING_COMPANIES}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const invoicingCompaniesService = new InvoicingCompaniesService();
