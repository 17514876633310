import {
    Button as MuiButton,
    Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from "@mui/material";
import {Formik, Form} from "formik";
import TextField from "../../components/Formik/TextField";
import Button from "../../components/Formik/SubmitButton";
import {useLocation} from "react-router-dom";
import {contactService} from "../../services/contact.service";
import {hydraService} from "../../utils/hydra";
import snackBar from "../../components/SnackBar";
import ProtectedComponent from "../../feature/ProtectedComponent";
import { ROLES } from "../../constants/roles";
import {run} from "../../utils/services";

const ContactDialog = ({open, handleClose, data}) => {
    const location = useLocation();
    const INITIAL_FORM_STATE = data || {
        member: location.pathname,
        lastname: '',
        firstname: '',
        job: '',
        address: '',
        additionalAddress: '',
        postcode: '',
        city: '',
        phone: '',
        mobile: '',
        email: ''
    }

    //const FORM_VALIDATION = Yup.object().shape({});

    return (
        <Dialog open={open} onClose={handleClose}>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (!data) {
                        run(contactService.addContact, values, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Contact ajouté avec succès');
                            });
                    } else {
                        const id = hydraService.getIdFromIri(values);
                        run(contactService.editContact, {id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Contact modifié avec succès');
                            });
                    }
                }}
            >
                <Form>
                    <DialogTitle className="headerModal">
                        <div><h3>{data?.lastname && data?.firstname ? `${data?.lastname} ${data?.firstname}` : 'Ajouter un contact'}</h3></div>
                    </DialogTitle>
                    <DialogContent sx={{'paddingLeft': 0}}>
                        <Grid container spacing={3} sx={{"marginTop": "0px"}}>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Nom'} name={'lastname'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Prénom'} name={'firstname'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Fonction'} name={'job'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Adresse'} name={'address'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Adresse additionelle'} name={'addtionalAddress'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Code postal'} name={'postcode'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Ville'} name={'city'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Téléphone'} name={'phone'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Portable'} name={'mobile'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'E-mail'} name={'email'}/>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions className={"footerModal"}>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <Button>{data ? 'Modifier' : 'Ajouter'}</Button>
                        </ProtectedComponent>
                        <MuiButton onClick={handleClose}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    )
}

export default ContactDialog;