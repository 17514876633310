import React, {useCallback} from 'react';
import _ from "../../utils/lodash/number/number_format";
import Range from "../../utils/range";
import {MenuItem} from "@mui/material";
import MuiSelect from "../Mui/Select";
import {useFilterContext} from "../../context/FilterContext";
import {useUserContext} from "../../context/UserContext";
import {ROLES} from "../../constants/roles";

const HarvestYearFilter = ({filters = {}, setFilters = newFilters => null, ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';
    const {setFilterHash} = useFilterContext();
    const {isGranted} = useUserContext();

    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
            setFilterHash(newFilters);
        },
        [filters, setFilters, setFilterHash],
    );

    return (
        <MuiSelect name={otherProps.name ?? 'harvestYear'}
                   label={otherProps.label ?? 'Année contrat'}
                   value={filters[otherProps.name ?? 'harvestYear'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {Range(new Date().getFullYear(), isGranted(ROLES.MEMBER) ? 2023 : 2021, -1).map((el, i) => (
                <MenuItem key={i} value={el}>{el}</MenuItem>
            ))}
        </MuiSelect>
    );
};

export default HarvestYearFilter;
