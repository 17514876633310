import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#065826',
            hover: '#04441c',
        },
        secondary: {
            main: '#7cb342',
        },
        breadcrumb: {
            background: "#d8e3e0",
            color: "#abafb3",
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
            dark: '#c62828',
            constrastText: '#fff',
        }
    },
    typography: {
        fontFamily: [
            "Helvetica",
        ].join(','),
        fontSize: 13,
        color: '#6a707e',
    },
})

const chanvriereTheme = createTheme({
    palette: theme.palette,
    typography: theme.typography,
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.fontSize,
                    color: theme.typography.color,
                },
                inherit: {
                    color: `${theme.palette.breadcrumb.color}!important`,
                    textDecoration: "none!important"
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.breadcrumb.background,
                    padding: "28px 20px",
                },
            }
        },
        MuiLink: {
            root: {
                color: "red",
                backgroundColor: "orange"
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.fontSize,
                    marginTop: "20px!important"
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    paddingTop: "12px",
                    paddingBottom: '12px',
                    "&.Mui-selected": {
                        backgroundColor: "#d6e1df",
                        fontWeight: '900',
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main, 
                    height: "70px"
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#464a53",
                    fontSize: theme.typography.fontSize,
                    "&.Mui-focusVisible": {outline: "orange"}
                },
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.fontSize
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "0",
                },
                notchedOutline: {
                    borderColor: "#dddfe1",
                    "&:focus": {
                        outline: `1px solid ${theme.palette.secondary.main}`,
                    }
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: "100%",
                    borderRight: "none!important"
                },
                rounded: {
                    "& svg": {
                        width: "40px",
                        height: "40px"
                    }
                },
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: "20px",
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: "40px",
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    //marginTop: "0!important",
                    position: "relative"
                },
                container: {
                    backgroundColor: "#FFF",
                    // padding: "30px 25px 100px 0",
                    width: "100%!important",
                    marginLeft: "0!important",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "inherit",
                },
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.hover,
                        }
                    }
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    border: "none",
                    marginTop: "20px",
                    fontSize: theme.typography.fontSize
                },
                columnHeaders: {
                    borderRadius: 0,
                },
                columnHeader: {
                    "&:focus": {
                        outline: "none",
                    }
                },
                columnHeadersInner: {
                    backgroundColor: '#FFF',
                    width: "100%"
                },
                columnHeaderTitle: {
                    padding: "0 0 0 5px",
                    fontWeight: "bold"
                },
                row: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: "#fafafa"
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: "#fff"
                    },
                    '&:hover': {
                        backgroundColor: "#eceaea",
                    }
                },
                cell: {
                    cursor: "pointer",
                    '&:first-of-type': {
                        padding: "0 15px",
                    },
                    '&:focus': {
                        outline: "none"
                    }
                },
                autoHeight: {
                    width: "100%"
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginTop: "5px",
                    backgroundColor: "#FFF",
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    marginTop: "20px",
                    '&.Mui-selected': {
                        backgroundColor: "#FFF",
                        color: theme.palette.primary.main,
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: theme.palette.primary.main,
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: "calc(100% - 50px)",
                    margin: '0 auto'
                },
                fullWidth: {
                    margin: "25px auto"
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff',
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    width: "inherit",
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // '& label.Mui-focused': {
                    //     color: theme.palette.primary.main,
                    // },
                    // '& .MuiInput-underline:after': {
                    //     borderBottomColor: theme.palette.primary.main,
                    // },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#dddfe1',
                        },
                        // '&:hover fieldset': {
                        //     borderColor: theme.palette.primary.main,
                        // },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                            borderWidth: '1px'
                        },
                    },
                }
            }
        }
    }
});

export default chanvriereTheme;