import {
    Grid, MenuItem,
} from "@mui/material";
import Autocomplete from "../../components/Formik/Autocomplete";
import TextField from "../../components/Formik/TextField";
import {Form, Formik} from "formik";
import Button from "../../components/Formik/SubmitButton";
import Checkbox from "../../components/Formik/Checkbox";
import * as Yup from "yup";
import {useEffect, useState} from "react";
import {memberService} from "../../services/member.service";
import {useParams} from "react-router-dom";
import Loader from "../Loader";
import {siloService} from "../../services/silo.service";
import {hydraService} from "../../utils/hydra";
import {departmentService} from "../../services/department.service";
import {catalogService} from "../../services/catalog.service";
import snackBar from "../../components/SnackBar";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import {TYPE_SILO} from "../../constants/types";
import {invoicingCompaniesService} from "../../services/InvoicingCompanies.service";
import InvoicingCompaniesSelect from "./InvoicingCompaniesSelect";
import {run} from "../../utils/services";
import Select from "../../components/Formik/Select";
import Range from "../../utils/range";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {useUserContext} from "../../context/UserContext";

const DetailPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const INITIAL_FORM_STATE = {
        title: null,
        particule: '',
        company: '',
        address: '',
        additionalAddress: '',
        postcode: '',
        city: '',
        phone: '',
        mobile: '',
        email: '',
        managerName: '',
        intracommunityVatNumber: '',
        siretNumber: '',
        department: null,
        attachedSilo: null,
        dryerSilo: null,
        vivesciaCode: '',
        sageCode: '',
        status: false,
        contribution: false,
        invoicingCompany: '',
        distributionGroup: null,
    }
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [catalog, setCatalog] = useState({
        'departments': [],
        'silos': [],
        'memberTitles': [],
        'companies': [],
    });

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        Promise.all([
            run(departmentService.getAllDepartments, {pagination: false}),
            run(siloService.getAllSilos, {pagination: false}),
            run(catalogService.getTitles, {pagination: false}),
            run(invoicingCompaniesService.getAllInvoicingCompanies, {pagination: false}),
        ]).then((values) => {
            let [departments, silos, memberTitles, companies] = values.map(el => el.responseData)
            departments = hydraService.getMembers(departments);
            silos = hydraService.getMembers(silos);
            companies = hydraService.getMembers(companies);
            setCatalog({departments, silos, companies, memberTitles});
            if (!!id) {
                run(memberService.getMember, id)
                    .then((response) => {
                        const member = response.responseData;
                        setData(member);
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        })
    }, []);

    return (
        isLoading ? <Loader/> :
            <FormDisabler disabled={isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(memberService.addMember, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(memberService.editMember, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <InvoicingCompaniesSelect catalog={catalog}/>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Code adhérent unique'} inputProps={{maxLength: '10'}} name={'code'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <Autocomplete
                                    name={'title'}
                                    label={'Titre'}
                                    options={catalog.memberTitles}
                                />
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Particule'} inputProps={{maxLength: '50'}} name={'particle'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Nom de la société'} required name={'company'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Adresse'} name={'address'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={`Complément d'adresse`} name={'additionalAddress'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Code postal'} name={'postcode'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Ville'} name={'city'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Téléphone'} name={'phone'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Portable'} name={'mobile'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'E-mail'} name={'email'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Nom du gérant'} name={'managerName'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'TVA Intracommunautaire'} name={'intracommunityVatNumber'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'N° Siret'} name={'siretNumber'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Autocomplete
                                    name={'department'}
                                    label={'Département'}
                                    options={catalog.departments}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    hydra
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Checkbox name={'contribution'} label={'Cotisation'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Autocomplete
                                    name={'attachedSilo'}
                                    label={'Silo rattachement'}
                                    options={catalog.silos.filter(el => [TYPE_SILO.ATTACHMENT, TYPE_SILO.ATTACHMENT_DRYER].includes(el.type))}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    hydra
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Autocomplete
                                    name={'dryerSilo'}
                                    label={'Silo sécheur'}
                                    options={catalog.silos.filter(el => [TYPE_SILO.DRYER, TYPE_SILO.ATTACHMENT_DRYER].includes(el.type))}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    hydra
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Code VIVESCIA'} name={'vivesciaCode'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Code SAGE unique'} required name={'sageCode'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Select label={'Groupe enlèvement'}
                                        required
                                        name={'distributionGroup'}
                                        nullIfEmpty
                                >
                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                    {Range(1, 4).map((item, i) => <MenuItem key={i}
                                                                            value={String(item)}>{item}</MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <Checkbox name={'status'} label={'Inactif'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField
                                    name={'comment'}
                                    label={'Commentaires'}
                                    multiline
                                    minRows={5}
                                />
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'}>
                                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                    <Button>{id ? "Mettre à jour" : "Ajouter"}</Button>
                                </ProtectedComponent>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </FormDisabler>
    )
}

export default DetailPage;