import {Link, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useUserContext} from "../../context/UserContext";
import {Button, Grid, MenuItem, Stack, TextField} from "@mui/material";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import TableAsync from "../../components/Mui/DataGridAsync";
import {shareMovementService} from "../../services/shareMovement.service";
import {hydraService} from "../../utils/hydra";
import Range from "../../utils/range";
import MuiSelect from "../../components/Mui/Select";
import {toLocaleDate} from "../../utils/i18n";
import _ from "../../utils/lodash/number/number_format";

const ShareMovements = () => {
    const location = useLocation();
    const {isGranted} = useUserContext();
    const [filters, setFilters] = useState({});

    const columns = [
        {
            field: '@id', headerName: 'N°', flex: 1,
            valueGetter: (params) => {
                return hydraService.getIdFromIri(params.row['@id']);
            }
        },
        {
            field: 'movementDate', headerName: 'Date mouvement', flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {
            field: 'memberFrom.company', headerName: 'Adhérent', flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'memberTo.company', headerName: 'À adhérent', flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {field: 'hempShareNumber', headerName: 'Nb de part', flex: 1},
        {field: 'totalAmount', headerName: 'Montant total', flex: 1},
        {field: 'movementType', headerName: 'Type', flex: 1},
    ];

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={8} className="Grid">
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN, ROLES.ACCOUNTANT]}>
                        <Stack direction={'row'} spacing={1}>
                            <TextField fullWidth name={'memberFrom'} label={'Rechercher un mouvement...'}
                                       onChange={(e) => {
                                           setFilters({...filters, [e.target.name]: e.target.value});
                                       }}/>

                            <MuiSelect name={'movementYear'}
                                       label={'Année'}
                                       value={filters.movementYear ?? ''}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           const newFilters = value ? {
                                               ...filters,
                                               [name]: value
                                           } : delete filters[name];
                                           setFilters(newFilters);
                                       }}
                            >
                                <MenuItem value={''}><em>Aucune</em></MenuItem>
                                {Range(new Date().getFullYear(), 2021, -1).map((el, i) => (
                                    <MenuItem key={i} value={el}>{el}</MenuItem>
                                ))}
                            </MuiSelect>

                            <MuiSelect name={'movementType'}
                                       label={'Type'}
                                       value={filters.movementType ?? ''}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           const newFilters = value ? {
                                               ...filters,
                                               [name]: value
                                           } : delete filters[name];
                                           setFilters(newFilters);
                                       }}
                            >
                                <MenuItem value={''}><em>Aucune</em></MenuItem>
                                <MenuItem value={'Ajout'}>Ajout</MenuItem>
                                <MenuItem value={'Transfert'}>Transfert</MenuItem>
                                <MenuItem value={'Diminution'}>Diminution</MenuItem>
                            </MuiSelect>
                        </Stack>
                    </ProtectedComponent>
                </Grid>

                {isGranted(ROLES.ADMIN, ROLES.ACCOUNTANT) &&
                    <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} flexDirection="row" alignItems="center" alignSelf="center">
                            <Button
                                component={Link}
                                variant='contained'
                                to={`${location.pathname}/add`}
                                sx={{mb: 0, "marginTop": "0!important"}}>
                                Effectuer un mouvement
                            </Button>
                        </Stack>
                    </Grid>
                }
            </Grid>
            <TableAsync
                columns={columns}
                // onRowClick={(props) => navigate(props.id)}
                request={shareMovementService.getAllMovements}
                filters={filters}
                customPagination
            />
        </>);
}

export default ShareMovements