import axios, {getErrors, getViolations} from "./axios";
import snackBar from "../components/SnackBar";

export const handleResponse = (response) => {
    if (![200, 201, 202, 203, 204, 205].includes(response.status)) {
        if (response.status === 401) {
            axios.defaults.headers.common.Authorization = null;
        }
        return Promise.reject(response);
    }
    return {responseData: response.data, statusText: "OK"};
};

/**
 *
 * @param func - Axios function
 * @param parameters
 * @param otherArgs
 */
export const run = (func, parameters = {}, otherArgs = {}) => {
    return func(parameters)
        .then(response => response)
        .catch((error) => {
            // Form errors
            if ('setFieldError' in otherArgs) {
                const formErrors = getViolations(error);
                if (formErrors !== undefined && formErrors.length > 0) {
                    formErrors.forEach(el => {
                        otherArgs.setFieldError(el.propertyPath, el.message);
                    });
                    snackBar('Il existe des erreurs', 'error');
                } else {
                    const message = error.response?.data?.message;
                    if (message) {
                        snackBar(message, 'error');
                    }
                    if (error.response?.data instanceof Blob) {
                        error.response?.data.text().then(text => {
                            const message = JSON.parse(text)?.message;
                            snackBar(message, 'error');
                        });
                    }
                }
            } else {
                const errorMessage = getErrors(error);
                if (errorMessage) {
                    snackBar(errorMessage, 'error');
                }
            }
            return Promise.reject();
        })
        .finally(() => {
            if ('setSubmitting' in otherArgs) {
                otherArgs.setSubmitting(false);
            }
        });
}