import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ArticleService {
  async getAllArticles(parameters = {}) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.ARTICLE + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getArticle(id) {
    return axios
      .get(`${ENDPOINTS.ARTICLE}/${id}`)
      .then(handleResponse);
  }

  async addArticle(parameters) {
    return axios
      .post(ENDPOINTS.ARTICLE, parameters)
      .then(handleResponse);
  }

  async editArticle(parameters) {
    return axios
      .put(`${ENDPOINTS.ARTICLE}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeArticle(id) {
    return axios
      .delete(`${ENDPOINTS.ARTICLE}/${id}`)
      .then(handleResponse);
  }

  async getArticleYears(id) {
    return axios
        .get(`${ENDPOINTS.ARTICLE}/${id}/years`)
        .then(handleResponse);
  }

}

export const articleService = new ArticleService();
