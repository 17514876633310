import {ENTITY} from "../../../../../constants/entity";
import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";


const SocialShares = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'priceHemp', headerName: 'Prix - Part chanvre', flex: 1},
        {field: 'priceFlax', headerName: 'Prix - Part lin', flex: 1},
        {field: 'numberUnitPerTon', headerName: 'Tonnage de paille pour 10 parts', flex: 1},
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawSocialShares}
        addLabel={'Ajouter un barème parts sociales'}
        cloneEntity={String(ENTITY.STRAW_SOCIAL_SHARES.namespace)}
    />
}

export default SocialShares;