import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useCallback} from "react";

const CheckboxWrapper = ({name, label, legend, ...otherProps}) => {

    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = useCallback((e) => {
        setFieldValue(name, e.target.checked);
    }, [field.value]);

    // console.log(field);
    const configCheckbox = {
        ...field,
        ...otherProps,
        checked: !!field.value,
        value: !!field.value,
        onChange: handleChange,
    }

    const configFormControl = {}
    if (meta && meta.touched && meta.error) {
        configFormControl.error = meta.error;
    }

    return (
        <FormControl {...configFormControl}>
            {legend && <FormLabel component={'legend'}>{legend}</FormLabel>}
            <FormGroup>
                <FormControlLabel
                    label={label}
                    disabled={otherProps.disabled ?? false}
                    control={<Checkbox {...configCheckbox}/>}
                />
            </FormGroup>
        </FormControl>
    );
}

export default CheckboxWrapper;
