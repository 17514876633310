import {Button, Menu, MenuItem} from "@mui/material";
import {useMemo, useState} from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useDialog} from "../DialogProvider";
import {useUserContext} from "../../context/UserContext";

const ImportMenuDialog = ({buttonLabel = 'Import', variant = 'outlined', data}) => {
    const [openDialog] = useDialog();
    const [anchorEl, setAnchorEl] = useState(null);
    const {currentUser: {roles: currentRoles}} = useUserContext();


    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const menuItems = useMemo(() => {
        return (data || [])
            .filter(el => (el.roles || []).some(i => currentRoles.includes(i)))
            .map(i => ({
                title: i['title'],
                action: () => {
                    openDialog({
                        children: i['content'],
                        maxWidth: 'xl',
                        scroll: 'paper'
                    })
                },
            }));
    }, [data, openDialog]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant={variant}
                onClick={handleClick}
                // style={{marginTop: 0, marginRight: "5px"}}
                endIcon={open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            >
                {buttonLabel}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems.map((menu, index) => ([
                    <MenuItem key={index} onClick={menu.action}>{menu.title}</MenuItem>
                ]))}
            </Menu>
        </>
    );
}

export default ImportMenuDialog;