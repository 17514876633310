import React, {useCallback} from 'react';
import _ from "../../utils/lodash/number/number_format";
import {MenuItem} from "@mui/material";
import MuiSelect from "../Mui/Select";
import {useFilterContext} from "../../context/FilterContext";

const DepositFilter = ({filters, setFilters, depositList, ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';
    const {setFilterHash} = useFilterContext();


    
    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
            setFilterHash(newFilters);
        },
        [filters, setFilters, setFilterHash]
    )

    return (
        <MuiSelect name={otherProps.name ?? 'deposit'}
                   label={otherProps.label ?? 'Dépôt'}
                   value={filters['deposit'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {depositList.sort((a, b) => a.name.localeCompare(b.name)).map((el, i) => <MenuItem key={i} value={el.name}>{el.name}</MenuItem>)}
        </MuiSelect>
    );
};

export default DepositFilter;
