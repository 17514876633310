import {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik, useFormikContext} from "formik";
import {FormHelperText, Grid, MenuItem} from "@mui/material";
import TextField from "../../../components/Formik/TextField";
import DateNative from "../../../components/Formik/Date/Native";
import Autocomplete from "../../../components/Formik/Autocomplete";
import Select from "../../../components/Formik/Select";
import {memberService} from "../../../services/member.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../Loader";
import {strawInvoiceService} from "../../../services/strawInvoice.service";
import {vatCodeService} from "../../../services/vatCode.service";
import snackBar from "../../../components/SnackBar";
import {catalogService} from "../../../services/catalog.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import NumberField from "../../../components/Formik/NumberField";
import {ROLES} from "../../../constants/roles";
import {run} from "../../../utils/services";
import {ARTICLE_TYPE_CODES, INVOICE_TYPE} from "../../../constants/types";
import SelectYear from "../../../components/Formik/SelectYear";
import {useUserContext} from "../../../context/UserContext";
import FormDisabler from "../../../components/Formik/ThemeFormikDisablerProvider";
import ActionInvoice from "../ActionInvoice";
import NavigationButtons from "../NavigationButtons";

const StrawInvoice = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const INITIAL_FORM_STATE = {
        member: null,
        tickets: [],
        quantity: "",
        unitPrice: "",
        number: "",
        invoiceDate: null,
        credit: false,
        reference: null,
        vat: null,
        expiryDate: null,
        amountIncludingVat: "",
        amountExcludingVat: "",
        vatAmount: "",
        article: null,
        canGenerateCredit: false,
        contract: null,
        harvestYear: null,
        isBlocked: false
    }
    const FORM_VALIDATION = Yup.object().shape({});
    const {isGranted} = useUserContext();

    const [loading, setLoading] = useState(!!id);
    const [catalog, setCatalog] = useState({
        'articles': [],
        'vatCodes': [],
        'invoiceTypes': [],
        'exportInvoicesTypes': []
    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [memberSelected, setMemberSelected] = useState(null);

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
            run(vatCodeService.getAllVatCodes, {pagination: false}),
            run(catalogService.getAllCatalogInvoicesTypes),
            run(catalogService.getAllCatalogExportInvoicesTypes)
        ]).then((values) => {
            let [articles, vatCodes, invoiceTypes, exportInvoicesTypes] = values.map(el => el.responseData);
            articles = hydraService.getMembers(articles);
            vatCodes = hydraService.getMembers(vatCodes);
            setCatalog({articles, vatCodes, invoiceTypes, exportInvoicesTypes});

            if (!!id) {
                run(strawInvoiceService.getStrawInvoice, id)
                    .then((response) => {
                        setData(response.responseData);
                        memberService.getMember(hydraService.getIdFromIri(response.responseData.member))
                            .then((response) => {
                                setMemberSelected(response.responseData);
                                setLoading(false);
                            });
                    })
            }
        });
        return () => null;
    }, [id]);

    const INVOICE_TYPE_FILTER = [
        '1er acompte',
        '2ème acompte',
        'Xème acompte',
        'Solde',
        'Cotisation',
        'Transport',
    ].concat([data.reference]);

    const FormObserver = () => {
        const {values, setFieldValue} = useFormikContext();
        const [ready, setReady] = useState(false);
        useEffect(() => {
            setReady(true);
        }, []);

        const getVatRate = () => catalog.vatCodes.find(el => el['@id'] === values.vat)?.rate;
        const calculate = (quantity, unitPrice, vatRate) => {
            const amountExcludingVat = (+quantity * +unitPrice).toFixed(2);
            const vatAmount = (+amountExcludingVat * (+vatRate / 100)).toFixed(2);
            const amountIncludingVat = (+amountExcludingVat + +vatAmount).toFixed(2);
            setFieldValue('amountExcludingVat', amountExcludingVat);
            setFieldValue('vatAmount', vatAmount);
            setFieldValue('amountIncludingVat', amountIncludingVat);
        }
        useEffect(() => {
            if (!ready || (!values.quantity || !values.unitPrice || !values.vat)) {
                return;
            }
            const timeoutId = setTimeout(() => {
                const rate = getVatRate();
                calculate(values.quantity, values.unitPrice, rate);
            }, 600);
            return () => {
                clearTimeout(timeoutId);
            }
        }, [values.quantity, values.unitPrice, values.vat]);
    }

    const FormContractObserver = () => {
        const {values, setFieldValue} = useFormikContext();
        useEffect(() => {
            if (!(memberSelected && values.member && values.harvestYear)) {
                return;
            }
            const memberContracts = memberSelected.contractInfos;
            const contractSelected = memberContracts.find(el => el.year === values.harvestYear)?.contract['@id'];
            setFieldValue('contract', contractSelected ?? null);
        }, [values.member, values.harvestYear, memberSelected])
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(strawInvoiceService.addStrawInvoice, values, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effetué');
                                    navigate(response.responseData['@id']);
                                });
                        } else {
                            run(strawInvoiceService.editStrawInvoice, {id, ...values}, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effetué');
                                    setData({...response.responseData});
                                })
                        }
                    }}
                >
                    <Form>
                        <FormObserver/>
                        <FormContractObserver/>
                        <NavigationButtons data={data}/>
                        <FormDisabler disabled={data.isBlocked || isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                  className="Grid-container">
                                <Grid item xs={12} md={6}>
                                    <TextField name={'number'} label={'N° Facture'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectYear
                                        name={'harvestYear'}
                                        label={'Année'}
                                        reverse
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DateNative
                                        name={'invoiceDate'}
                                        label={'Date Facture'}
                                        nullIfEmpty
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'expiryDate'} label={'Date échéance'}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'member'}
                                        label={'Adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        value={memberSelected}
                                        callback={setMemberSelected}
                                        hydra
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'reference'} label={'Référence'}>
                                        <MenuItem value={''}>
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {catalog.invoiceTypes
                                            .filter(el => INVOICE_TYPE_FILTER.includes(el))
                                            .map((el, i) => <MenuItem key={i} value={el}>{el}</MenuItem>)}
                                    </Select>
                                    {data.reference === INVOICE_TYPE.X_DEPOSIT &&
                                        <FormHelperText
                                            sx={{textAlign: 'center'}}>{data.referenceLabel}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        name={'article'}
                                        label={'Articles'}
                                        options={catalog.articles}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        hydra
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField asString
                                                 name={'quantity'}
                                                 label={'Quantité'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberField asString name={'unitPrice'} label={'Prix unitaire'}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'vat'} label={'TVA'}>
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {catalog.vatCodes.map((el, i) => <MenuItem key={i}
                                                                                   value={el['@id']}>{el.label}</MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString
                                                 name={'amountExcludingVat'}
                                                 label={'Montant HT'}
                                                 disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString
                                                 name={'vatAmount'}
                                                 label={'Montant TVA'}
                                                 disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <NumberField asString
                                                 name={'amountIncludingVat'}
                                                 label={'Montant TTC'}
                                                 disabled
                                    />
                                </Grid>
                            </Grid>
                        </FormDisabler>
                    </Form>
                </Formik>
                {id && <ActionInvoice data={data}/>}
            </>
    );
}

export default StrawInvoice;