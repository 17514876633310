import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";
import _ from "lodash";

const ColorManufacturing = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 0.7},
        {field: 'code', headerName: 'Code', flex: 0.7},
        {field: 'label', headerName: 'Nom', flex: 1.3},
        {field: 'article.label', headerName: 'Article', flex: 1.3,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {field: 'plcdCode', headerName: 'Code PLCD', flex: 1.1},
        {field: 'orderBy', headerName: "Ordre d'affichage", flex: 1},
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawColorManufacturings}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_COLOR_MANUFACTURINGS.namespace)}
    />
}

export default ColorManufacturing;