import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class EngagementsService {
    async getAllEngagements(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.ENGAGEMENTS + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async generateEngagement(parameters) {
        return axios
            .post(ENDPOINTS.GENERATE_ENGAGEMENT, parameters)
            .then(handleResponse);
    }
}

export const engagementsService = new EngagementsService();
