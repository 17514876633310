import {Grid} from "@mui/material";
import DateNative from "../../components/Formik/Date/Native";
import NumberField from "../../components/Formik/NumberField";
import {useFormikContext} from "formik";
import {run} from "../../utils/services";
import {strawService} from "../../services/straw.service";
import {hydraService} from "../../utils/hydra";
import {useEffect, useCallback} from "react";

const cache = {};

const calculateTransferredTonnage = (values, socialShare) => {
    if (values?.hempShareNumber && socialShare?.numberUnitPerTon) {
        return (values.hempShareNumber * socialShare.numberUnitPerTon).toFixed(2);
    }
    return null;
}

const calculateTotalAmount = (values) => {
    if (values?.hempShareNumber && values?.hempAmount) {
        return (values.hempShareNumber * values.hempAmount).toFixed(2);
    }
    return null;
}

const PartDetailPage = ({setData}) => {
    const {values} = useFormikContext();

    useEffect(() => {
        if (!values.hempShareNumber) {
            return;
        }
        const timeId = setTimeout(() => {
            const year = new Date(values.movementDate).getFullYear();
            if (year in cache) {
                const socialShare = cache[year];
                setData({
                    ...values,
                    'movementDate': values.movementDate,
                    'totalAmount': calculateTotalAmount(values),
                    'transferredTonnage': calculateTransferredTonnage(values, socialShare)
                });
                return;
            }
        }, 600);

        return () => {
            clearTimeout(timeId);
        };
    }, [values.hempShareNumber, values.hempAmount]);

    const handleSetData = useCallback( (values, socialShare) => {
            setData({
                ...values,
                'movementDate': values.movementDate,
                'hempAmount': socialShare?.priceHemp ?? null,
                'totalAmount': calculateTotalAmount(values),
                'transferredTonnage': calculateTransferredTonnage(values, socialShare)
            });
        }, [setData]);

    useEffect(() => {
        if (!values.movementDate) {
            return;
        }
        const timeId = setTimeout(() => {
            const year = new Date(values.movementDate).getFullYear();
            if (year in cache) {
                const socialShare = cache[year];
                handleSetData(values, socialShare);
                return;
            }
            run(strawService.getAllStrawSocialShares, {year}).then((response) => {
                const socialShare = hydraService.getMembers(response.responseData)[0];
                cache[year] = socialShare;
                handleSetData(values, socialShare);
            });
        }, 600);

        return () => {
            clearTimeout(timeId);
        };
    }, [values.movementDate]);

    return (
        <>
            <Grid xs={12} item sx={{'marginTop': "50px"}}/>
            <Grid xs={12} md={4} item>
                <DateNative name={'movementDate'} label={"Date"}/>
            </Grid>
            <Grid xs={12} md={4} item>
                <NumberField name={'hempShareNumber'} label={"Nombre de parts chanvre"} asString nullIfEmpty/>
            </Grid>
            <Grid xs={12} md={4} item>
                <NumberField name={'hempAmount'} label={"Montant chanvre"} disabled asString nullIfEmpty/>
            </Grid>
            <Grid xs={12} md={4} item>
                <NumberField name={'totalAmount'} label={"Montant total"} disabled asString nullIfEmpty/>
            </Grid>
            <Grid xs={12} md={4} item>
                <NumberField name={'transferredTonnage'} label={"Tonnage transféré"} disabled asString nullIfEmpty/>
            </Grid>
        </>
    );
}

export default PartDetailPage;