import {DataGrid, frFR} from "@mui/x-data-grid";
import CustomPagination from "./CustomPagination";

const DataGridWrapper = ({rows, columns, customPagination, onRowClick, ...otherProps}) => {

    const configDataGrid = {
        autoHeight: true,
        disableColumnFilter: true,
        ...otherProps,
        ...(customPagination && {
            components: {
                Pagination: CustomPagination,
            }
        })
    }

    return (
        <div style={{height: otherProps.height ?? 'auto', width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                onRowClick={onRowClick}
                {...configDataGrid}
            />
        </div>
    );
}

export default DataGridWrapper;