import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";
import _ from "../../../../../utils/lodash/number/number_format";

const BallWeight = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'label', headerName: 'Nom', flex: 1},
        {
            field: 'startScale', headerName: 'De', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'endScale', headerName: 'À', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'amount', headerName: 'Montant', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawBallWeights}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_BALL_WEIGHTS.namespace)}
    />
}

export default BallWeight;