import * as React from 'react';
import {Button as MuiButton, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function Alert({handleClose, open, handleRemove}) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={"headerModal"}>
                    <div><h3>Suppression de la ligne</h3></div>
                </DialogTitle>
                <DialogContent className={"contentModal"} sx={{"padding": "20px 24px!important"}}>
                    <Typography sx={{"color": "rgba(0, 0, 0, 0.87)"}}>Confirmer la suppression ?</Typography>
                </DialogContent>
                <DialogActions className={"footerModal"}>
                    <MuiButton onClick={handleClose}>Annuler</MuiButton>
                    <MuiButton  color="primary" variant="contained" onClick={handleRemove} autoFocus>Confirmer</MuiButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}