import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class SimulationService {
  async addSimulation(parameters) {
    return axios
      .post(ENDPOINTS.SIMULATION, parameters, {
          responseType: 'blob'
      })
      .then(handleResponse);
  }
}

export const simulationService = new SimulationService();
