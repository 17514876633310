import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ChevenisKilometricService {
    async getAllChevenisKilometrics(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.CHENEVIS_KILOMETRIC + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getChevenisKilometric(id) {
        return axios
            .get(`${ENDPOINTS.CHENEVIS_KILOMETRIC}/${id}`)
            .then(handleResponse);
    }

    async addChevenisKilometric(parameters) {
        return axios
            .post(ENDPOINTS.CHENEVIS_KILOMETRIC, parameters)
            .then(handleResponse);
    }

    async editChevenisKilometric(parameters) {
        return axios
            .put(`${ENDPOINTS.CHENEVIS_KILOMETRIC}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeChevenisKilometric(parameters) {
        return axios
            .delete(`${ENDPOINTS.CHENEVIS_KILOMETRIC}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const chenevisKilometricService = new ChevenisKilometricService();
