import axios from "../utils/axios";
import {ENDPOINTS} from "../constants/endpoints";
import {handleResponse} from "../utils/services";

class ColorDriverService {
    async getAllColorsDrivers(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.COLOR_DRIVER + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }
}

export const colorDriverService = new ColorDriverService();
