import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";
import _ from "../../../../../utils/lodash/number/number_format";

const LoadingTime = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {
            field: 'startScale', headerName: 'De', flex: 1, valueFormatter: (params) => {
                return _.numberFormat(params.value);
            }
        },
        {
            field: 'endScale', headerName: 'À', flex: 1, valueFormatter: (params) => {
                return _.numberFormat(params.value);
            }
        },
        {
            field: 'amount', headerName: 'Montant', flex: 1, valueFormatter: (params) => {
                return _.numberFormat(params.value);
            }
        },
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawLoadingTimes}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_LOADING_TIMES.namespace)}
    />
}

export default LoadingTime;