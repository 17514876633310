import {Grid, TextField} from "@mui/material";
import AutocompleteAsync from "../../components/Formik/AutocompleteAsync";
import {memberService} from "../../services/member.service";
import {useCallback, useState} from "react";

const FromMember = ({fieldName, fieldLabel, children}) => {
    const [capitals, setCapitals] = useState({});

    const handleChange = useCallback((value) => {
            setCapitals({...value?.capital});
    }, [setCapitals]);

    return (
        <>
            <Grid xs={12} md={6} item>
                <AutocompleteAsync
                    name={fieldName}
                    label={fieldLabel}
                    filterName={'company'}
                    request={memberService.getAllMembers}
                    getOptionLabel={(option) => `${option.company} ${option.code}`}
                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                    value={null}
                    hydra
                    callback={handleChange}
                />
            </Grid>
            <Grid xs={12} md={6} item>
                {children}
            </Grid>
            <Grid xs={12} md={4} item>
                <TextField
                    name={'socialSharesNumber'}
                    label={"Parts capital"}
                    value={capitals?.socialSharesNumber ?? ''}
                    fullWidth
                    disabled/>
            </Grid>
            <Grid xs={12} md={4} item>
                <TextField
                    name={'capital'}
                    label={"Capital"}
                    value={capitals?.capital ?? ''}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid xs={12} md={4} item/>
        </>
    )
}

export default FromMember