import {useEffect} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useUserContext} from "../../../context/UserContext";
import {authentificationService} from "../../../services/authentification.service";

const Autoconnect = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {storeToken} = useUserContext();
    const {token} = useParams();

    useEffect(() => {
        try {
            const from = location.state?.from?.pathname || "/";
            authentificationService.autoconnection(token)
                .then((response) => {
                    if (response.statusText === 'OK'
                        && 'token' in response.responseData) {
                        storeToken(response.responseData.token);
                        navigate(from, {replace: true});
                    } else {
                        console.log("Failed", response.message, "error")
                        document.querySelector('.error-credentials').innerHTML = 'Identifiant ou mot de passe incorrect'
                    }
                });
        } catch (error) {
            console.error(error);
        }
        navigate('/', {replace: true});
    }, []);
}

export default Autoconnect;