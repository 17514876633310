import {ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from "./utils/routes";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";
import chanvriereTheme from "./utils/theme";
import {UserContextProvider} from "./context/UserContext";
import "./assets/App.scss";
import {SnackbarProvider} from "notistack";
import DialogProvider from "./feature/DialogProvider";
import MercureSubscriber from "./components/Mercure/Subscriber";
import DownloadComplete from "./components/SnackBar/Variant/DownloadComplete";
import MessageComplete from "./components/SnackBar/Variant/MessageComplete";

function App() {
    return (
        <ThemeProvider theme={chanvriereTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DialogProvider>
                    <UserContextProvider>
                        <SnackbarProvider maxSnack={3} Components={{
                            downloadComplete: DownloadComplete,
                            messageComplete: MessageComplete
                        }}>
                            <MercureSubscriber
                                hub={process.env.REACT_APP_MERCURE_URL}
                                topics={[
                                    `${process.env.REACT_APP_API_URL}/download/{uuid}`,
                                    `${process.env.REACT_APP_API_URL}/message/{uuid}`,
                                    `${process.env.REACT_APP_API_URL}/groups`
                                ]}
                            >
                                <CssBaseline/>
                                <AppRouter/>
                            </MercureSubscriber>
                        </SnackbarProvider>
                    </UserContextProvider>
                </DialogProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
