import {useMemo, useState} from "react";
import {Badge, Button, IconButton, Link, Menu, MenuItem, styled, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {AccountCircle} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom"
import Box from "@mui/material/Box";
import {useUserContext} from "../context/UserContext";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ProtectedComponent from "../feature/ProtectedComponent";
import {ROLES} from "../constants/roles";

const drawerWidth = 270;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Header = ({open, toggleDrawer}) => {
    const {currentUser, notificationUnread, isGranted} = useUserContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const isLogged = useMemo(() => {
        return Boolean(localStorage.getItem('token'))
    }, [])


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar sx={{backgroundColor: "#FFF", justifyContent: "space-between"}}>
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                            color: "#065826"
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Box>
                <Box sx={{display: "flex", color: "#6a707e"}}>
                    <ProtectedComponent excludedRoles={[ROLES.MEMBER]}>
                        <IconButton color="inherit"
                                    component={RouterLink}
                                    to={'/notifications'}
                        >
                            <Badge badgeContent={notificationUnread} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                    </ProtectedComponent>
                    {isLogged && (
                        <div className="containerLogOut">
                            {isGranted(ROLES.MEMBER) && <Button variant={'outlined'}>
                                <Link component={RouterLink} variant={'button'} underline={'none'} color={'primary'}
                                      to={'/logout'}>Retour extranet adhérents</Link>
                            </Button>
                            }
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle/>
                                <p className="currentNameUser">
                                    {currentUser?.fullname}
                                    {!isGranted(ROLES.MEMBER) && <ExpandMoreRoundedIcon/>}
                                </p>
                            </IconButton>
                            <ProtectedComponent excludedRoles={[ROLES.MEMBER]}>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem className="MenuItem">
                                        <Link component={RouterLink} to={'/logout'}>Se déconnecter</Link>
                                    </MenuItem>
                                </Menu>
                            </ProtectedComponent>
                        </div>
                    )}
                </Box>

            </Toolbar>
        </AppBar>
    );
}

export default Header;