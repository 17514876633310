import {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import NumberField from "../../../../components/Formik/NumberField";
import SnackBar from "../../../../components/SnackBar";

const HumidityRateField = ({catalog = {}, name = 'fabrication', loading = false}) => {
    const {values, setFieldValue} = useFormikContext();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!ready) { // Hack reloading
            setReady(true);
            return;
        }
        const timeoutId = setTimeout(() => {
            if (!catalog.humidities.length) {
                SnackBar(`Le barème humidité est indéfini en ${values.harvestYear}`, 'error');
                return;
            }
            const humidity = catalog.humidities.find(el => +values[name].humidityRate <= +el.endScale) ?? [...catalog.humidities].pop();
            setFieldValue(`${name}.humidity`, humidity['@id']);
            setFieldValue(`${name}.hasNoSupplement`, humidity.hasPriceSupplement);
        }, 600);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [
        values[name].humidityRate
    ]);

    return (
        <NumberField
            asString
            name={`${name}.humidityRate`}
            label={'Taux d\'humidité de fabrication'}
            nullIfEmpty
        />
    );
}

export default HumidityRateField;