import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, Stack, Typography} from "@mui/material";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import {useEffect, useState} from "react";
import {vatCodeService} from "../../../../services/vatCode.service";
import Loader from "../../../Loader";
import snackBar from "../../../../components/SnackBar";
import {getViolations} from "../../../../utils/axios";
import ModalDelete from "../ModalDelete";
import {hydraService} from "../../../../utils/hydra";
import Box from "@mui/material/Box";
import {run} from "../../../../utils/services";
import BackButton from "../../../../components/BackButton";

const VatCodePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({
        code: '',
        label: '',
        rate: '',
        deductibleVatAccount: '',
        collectedVatAccount: '',
        canBeChange: true
    });

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        (async () => {
            if (!isCreation) {
                run(vatCodeService.getVatCode, id).then((response) => {
                    setData(response.responseData);
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(vatCodeService.removeVatCode, {id});
        }
    }

    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (!id) {
                        run(vatCodeService.addVatCode, values, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                                navigate(PARAMETERS.BACK_ROUTE);
                            });
                    } else {
                        const id = hydraService.getIdFromIri(values);
                        run(vatCodeService.editVatCode, {id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                            });
                    }
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button disabled={!data.canBeChange}
                                    variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={12} item>
                            <TextField label={'Nom du code'} name={'label'}/>
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <TextField label={'Code'} name={'code'} type={"number"}/>
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <TextField label={'Taux'} name={'rate'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Compte TVA déductible'} name={'deductibleVatAccount'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Compte TVA collectée'} name={'collectedVatAccount'}/>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>

            <Box textAlign={'center'}>
                {!data.canBeChange ? (
                    <Typography color={'red'}
                                align={'center'}
                                sx={{mt: '10px', fontWeight: 'bold'}}
                    >
                        Modification et suppression impossible pour cette TVA, car elle est utilisée dans des process.
                    </Typography>
                ) : (
                    !isCreation && <ModalDelete title="du code TVA" handleDelete={handleDelete}/>
                )}
            </Box>
        </>
    )

}

export default VatCodePage