import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ExportService {
  async getAllExports() {
    return axios
      .get(ENDPOINTS.EXPORT)
      .then(handleResponse);
  }

  async getExport(id) {
    return axios
      .get(`${ENDPOINTS.EXPORT}/${id}`)
      .then(handleResponse);
  }

  async addExport(parameters) {
    return axios
      .post(ENDPOINTS.EXPORT, parameters)
      .then(handleResponse);
  }

  async editExport(parameters) {
    return axios
      .put(`${ENDPOINTS.EXPORT}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeExport(parameters) {
    return axios
      .delete(`${ENDPOINTS.EXPORT}/${parameters.id}`)
      .then(handleResponse);
  }

  async exportMembers(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_MEMBERS, parameters)
        .then(handleResponse);
  }

  async exportChenevisTicket(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_CHENEVIS_TICKET, parameters)
        .then(handleResponse);
  }

  async exportStrawTicket(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_STRAW_TICKET, parameters)
        .then(handleResponse);
  }

  async exportSupplyTicket(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_SUPPLY_TICKET, parameters)
        .then(handleResponse);
  }

  async exportChenevisInvoice(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_CHENEVIS_INVOICE, parameters)
        .then(handleResponse);
  }

  async exportStrawInvoice(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_STRAW_INVOICE, parameters)
        .then(handleResponse);
  }

  async exportSupplyInvoice(parameters) {
    return axios
        .post(ENDPOINTS.EXPORT_SUPPLY_INVOICE, parameters)
        .then(handleResponse);
  }
}

export const exportService = new ExportService();
