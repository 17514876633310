import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class NotificationService {
  async markAsRead(parameters) {
    return axios
        .post(`${ENDPOINTS.NOTIFICATION}/mark-as-read`, parameters)
        .then(handleResponse);
  }
  async removeNotification(parameters) {
    return axios
      .delete(`${ENDPOINTS.NOTIFICATION}/delete/${parameters.id}`)
      .then(handleResponse);
  }
}

export const notificationService = new NotificationService();
