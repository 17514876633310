import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Button as MuiButton, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Stack} from "@mui/material";
import NumberField from "../../../../../components/Formik/NumberField";
import Button from "../../../../../components/Formik/SubmitButton";
import {vatCodeService} from "../../../../../services/vatCode.service";
import {articleService} from "../../../../../services/article.service";
import {chenevisPaymentConditionService} from "../../../../../services/chenevisPaymentConditions.service";
import {useEffect, useState} from "react";
import Select from "../../../../../components/Formik/Select";
import {hydraService} from "../../../../../utils/hydra";
import Loader from "../../../../Loader";
import {TYPE} from "../../../../../constants/types";
import TextField from "../../../../../components/Formik/TextField";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import * as yup from "yup";
import ModalClone from "../../ModalClone";
import {ENTITY} from "../../../../../constants/entity";
import _ from "lodash"
import MuiTableAsync from "../../../../../components/Mui/DataGridAsync";
import MuiSelect from "../../../../../components/Mui/Select";
import Range from "../../../../../utils/range";
import {run} from "../../../../../utils/services";
import Alert from "../../../../../components/Alert";
import snackBar from "../../../../../components/SnackBar";


const ChenevisPaymentCondition = () => {
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [catalog, setCatalog] = useState({
        'vatCodes': [],
        'articlesSeed': [],
        'paymentConditions': [],
    });
    const [rowsSeed, setRowsSeed] = useState([]);
    const [formState, setFormState] = useState(null);
    const [filters, setFilters] = useState([]);
    const [row, setRow] = useState();

    useEffect(() => {
        Promise.all([
            run(vatCodeService.getAllVatCodes, {pagination: false}),
            run(articleService.getAllArticles, {pagination: false}),
            run(chenevisPaymentConditionService.getAllChevenisPaymentConditions, {pagination: false}),
        ]).then((values) => {
            let [vatCodes, articles, paymentConditions] = values.map(el => el?.responseData)
            vatCodes = hydraService.getMembers(vatCodes);
            articles = hydraService.getMembers(articles);
            paymentConditions = hydraService.getMembers(paymentConditions)

            const articlesSeed = articles.filter(el => el.type === TYPE.SEED);

            setCatalog({vatCodes, articlesSeed});

            setRowsSeed(paymentConditions.map(el => ({...el, id: el['@id']})));

            setLoading(false);
        })
        return () => {
            setRowsSeed([]);
        }
    }, []);

    const columnsSeed = [
        {field: 'year', headerName: 'Année', flex: 0.7},
        {
            field: 'article.label', headerName: 'Article', flex: 1,
            valueGetter: (params) => {
                return _.get(params.row, params.field);
            }
        },
        {
            field: 'vatAdvance.label', headerName: 'Code TVA', flex: 0.8,
            valueGetter: (params) => {
                return _.get(params.row, params.field);
            }
        },
        {field: 'sagePurchaseAccount', headerName: 'Compte achat SAGE', flex: 1},
        {field: 'section', headerName: 'Section', flex: 0.8},
        {field: 'accountingJournal', headerName: 'Journal comptable', flex: 1},
        {field: 'firstPayment', headerName: '1er accompte', flex: 1.2},
        {field: 'secondPayment', headerName: '2ème accompte', flex: 1.2},
        {field: 'balancePayment', headerName: 'Complément de prix', flex: 1.2},
        {
            field: "actions",
            cellClassName: "actions",
            type: "actions",
            headerName: "Actions",
            getActions: ({row, id}) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={() => handleClickOpen(row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const deleteRow = () => {
        run(chenevisPaymentConditionService.removeChevenisPaymentCondition, {id: hydraService.getIdFromIri(row["@id"])
        }).then(() => {
            snackBar('La ligne a bien été supprimé')
            setRowsSeed(prev => prev.filter(i => i.id !== row["@id"]));
            setOpenAlert(false);
        });
    };

    const updateRow = (values, resetForm) => {
        run(chenevisPaymentConditionService.editChevenisPaymentCondition, {
            ...values,
            id: hydraService.getIdFromIri(values['@id'])
        }).then((response) => {
            const id = hydraService.getIdFromIri(response.responseData);
            setRowsSeed(prev => [...prev.filter(i => i.id !== values.id), {...values, id}]);
            resetForm();
            handleCloseDialog();
        });
    };

    const createRow = (values, resetForm) => {
        run(chenevisPaymentConditionService.addChevenisPaymentCondition, values).then((response) => {
            const id = hydraService.getIdFromIri(response.responseData);
            setRowsSeed([...rowsSeed, {...values, id}]);
            resetForm();
            handleCloseDialog();
        });
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setFormState(null);
    };

    const handleOpenDialog = (data) => {
        setOpen(true);
        setFormState(data);
    };

    const handleRowClick = (params) => {
        const id = hydraService.getIdFromIri(params.id);
        run(chenevisPaymentConditionService.getChevenisPaymentCondition, id).then(response => {
            handleOpenDialog(response.responseData)
        });
    };

    const handleClose = () => {
        setOpenAlert(false);
    };

    const handleClickOpen = (row) => {
        setRow(row);
        setOpenAlert(true);
    };

    const FORM_VALIDATION_SEED = Yup.object().shape({
        article: yup.string().required("Le choix d'un article est obligatoire"),
        year: yup.number().required('L\'année est obligatoire'),
        vatAdvance: yup.string().required("Le code TVA est obligatoire"),
        sagePurchaseAccount: yup.string().required('Le code Sage est obligatoire'),
        section: yup.string().required('La section est obligatoire'),
        accountingJournal: yup.string().required('Le journal comptable est obligatoire'),
        firstPayment: yup.string().required('Le 1er montant est obligatoire'),
        secondPayment: yup.string().required('Le 2ème montant est obligatoire'),
        balancePayment: yup.string().required('Le montant solde est obligatoire'),
        rootFirstPaymentInvoice: yup.string().required('La racine 1er accompte est obligatoire'),
        rootSecondPaymentInvoice: yup.string().required('La racine 2ème accompte est obligatoire'),
        rootBalancePaymentInvoice: yup.string().required('La racine montant solde est obligatoire'),
    });

    return (
        loading ? <Loader/> :
            <>
                <Grid container spacing={3} className="Grid Grid-root">
                    <Grid item xs={3} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <MuiSelect name={'year'}
                                       label={'Année '}
                                       value={filters.year ?? ''}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           const newFilters = value ? {
                                               ...filters,
                                               [name]: value
                                           } : delete filters[name];
                                           setFilters(newFilters);
                                       }}
                            >
                                <MenuItem value={''}><em>Aucune</em></MenuItem>
                                {Range(new Date().getFullYear(), 2021, -1).map((el, i) => (
                                    <MenuItem key={i} value={el}>{el}</MenuItem>
                                ))}
                            </MuiSelect>
                        </Stack>
                    </Grid>
                    <Grid container item xs={9} justifyContent={'flex-end'} className="Grid">
                        <Stack sx={{width: "100%"}} flexDirection="row" justifyContent="flex-end">
                            <ModalClone cloneEntity={String(ENTITY.CHENEVIS_PAYMENT_CONDITIONS.namespace)}/>
                            <MuiButton
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    const form = {
                                        article: "",
                                        year: "",
                                        vatAdvance: "",
                                        sagePurchaseAccount: "",
                                        section: "",
                                        accountingJournal: "",
                                        firstPayment: "",
                                        secondPayment: "",
                                        balancePayment: "",
                                        rootInvoice: "",
                                        rootFirstPaymentInvoice: "",
                                        rootSecondPaymentInvoice: "",
                                        rootBalancePaymentInvoice: "",
                                        vatBalance: null
                                    };
                                    handleOpenDialog(form);
                                }}>Ajouter</MuiButton>
                        </Stack>
                    </Grid>
                </Grid>
                <MuiTableAsync
                    columns={columnsSeed}
                    onRowClick={handleRowClick}
                    request={chenevisPaymentConditionService.getAllChevenisPaymentConditions}
                    filters={filters}
                    customPagination
                />
                <Dialog open={Boolean(open && formState)} onClose={handleCloseDialog}>
                    <DialogTitle className={"headerModal"}>
                        <div><h3 className={"titleModal"}>Condition de paiement</h3></div>
                    </DialogTitle>
                    <DialogContent className={"contentModalArticle"}>
                        {formState && (
                            <Formik
                                initialValues={formState}
                                validationSchema={FORM_VALIDATION_SEED}
                                onSubmit={(values, {resetForm}) => {
                                    if (values['id'] || values['@id']) {
                                        updateRow(values, resetForm);
                                    } else {
                                        createRow(values, resetForm);
                                    }
                                }}
                            >
                                <Form>
                                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                        <Grid xs={12} md={12} item>
                                            <Select name={'article'} label={'Article'}>
                                                {catalog.articlesSeed.map((el, i) => <MenuItem key={i}
                                                                                               value={el['@id']}>{el.label}</MenuItem>)}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <NumberField name={'year'} label={'Année'}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Select name={'vatAdvance'} label={'Code TVA'}>
                                                <MenuItem value={''}><em>Aucun</em></MenuItem>
                                                {catalog.vatCodes.map((el, i) => <MenuItem key={i}
                                                                                           value={el['@id']}>{el.label}</MenuItem>)}
                                            </Select>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <TextField label={"Compte d'achat Sage"} name={'sagePurchaseAccount'}/>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <TextField label={"Section"} name={'section'}/>
                                        </Grid>
                                        <Grid xs={12} md={12} item>
                                            <TextField label={'Journal comptable'} name={'accountingJournal'}/>
                                        </Grid>

                                        <Grid xs={12} md={6} item>
                                            <NumberField label={'Montant 1er accompte'} name={'firstPayment'} asString/>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <TextField label={'Racine facture 1er accompte'}
                                                       name={'rootFirstPaymentInvoice'}/>
                                        </Grid>

                                        <Grid xs={12} md={6} item>
                                            <NumberField label={'Montant 2nd accompte'} name={'secondPayment'}
                                                         asString/>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <TextField label={'Racine facture 2nd accompte'}
                                                       name={'rootSecondPaymentInvoice'}/>
                                        </Grid>

                                        <Grid xs={12} md={6} item>
                                            <NumberField label={'Montant Solde'} name={'balancePayment'} asString/>
                                        </Grid>
                                        <Grid xs={12} md={6} item>
                                            <TextField label={'Racine facture montant solde'}
                                                       name={'rootBalancePaymentInvoice'}/>
                                        </Grid>

                                    </Grid>
                                    <Grid className={"footerModalArticle"} item xs={12} md={12} rowSpacing={2}
                                          display='flex' flexDirection='row' justifyContent='flex-end'>
                                        <Button>{formState.year ? 'Modifier' : 'Ajouter'}</Button>
                                        <MuiButton
                                            onClick={() => {
                                                handleCloseDialog();
                                            }}
                                        >
                                            Annuler
                                        </MuiButton>
                                    </Grid>
                                </Form>
                            </Formik>
                        )}
                    </DialogContent>
                </Dialog>

                <Alert handleClose={handleClose} open={openAlert} handleRemove={deleteRow} />
            </>
    )
}

export default ChenevisPaymentCondition;