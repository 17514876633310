import {useEffect, useMemo, useState} from "react";
import {Button, Grid, Stack} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {memberService} from "../../services/member.service";
import TableAsync from "../../components/Mui/DataGridAsync";
import {ROLES} from "../../constants/roles";
import ProtectedComponent from "../../feature/ProtectedComponent";
import ImportMenu from "../../feature/ImportMenu";
import {toLocaleDate} from "../../utils/i18n";
import CompanyFilter from "../../components/Filters/CompanyFilter";
import {useFilterContext} from "../../context/FilterContext";
import CodeMemberFilter from "../../components/Filters/CodeMemberFilter";
import ManagerFilter from "../../components/Filters/ManagerFilter";
import {run} from "../../utils/services";
import {strawService} from "../../services/straw.service";
import moment from "../../utils/moment";
import {hydraService} from "../../utils/hydra";

const Members = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {getFilterHash} = useFilterContext();
    const [filters, setFilters] = useState(getFilterHash);
    const [data, setData] = useState({
        hasScaleSocialShares: false,
        loading: true
    });

    const importMenu = useMemo(() => {
        return [
            {title: 'Ajout et/ou modification de coordonnées', type: "member"},
            {title: 'Import Capital', type: "capital", disabled: !data.hasScaleSocialShares}]
    }, [data])

    const columns = [
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'title', headerName: 'Titre', flex: 1},
        {field: 'company', headerName: 'Nom', flex: 1},
        {field: 'managerName', headerName: 'Gérant', flex: 1},
        {
            field: 'joiningDate',
            headerName: 'Date contrat',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'city', headerName: 'Ville', flex: 1},
        {field: 'phone', headerName: 'Téléphone', flex: 1},
        {field: 'email', headerName: 'E-mail', flex: 1},
    ];

    useEffect(() => {
        Promise.all([
            run(strawService.getAllStrawSocialShares, { year: moment().year()  }),
        ]).then((values) => {
            setData({
                hasScaleSocialShares: hydraService.getTotalItems(values[0].responseData) > 0,
                loading: false
            });
        });
        return () => null;
    }, []);

    return (
        data.loading ? '' :
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <ProtectedComponent acceptedRoles={[ROLES.ADMIN, ROLES.ACCOUNTANT]}>
                    <Grid item xs={8} className="Grid">
                        <Stack direction={'row'} spacing={1}>
                            <CodeMemberFilter filters={filters} setFilters={setFilters}/>
                            <CompanyFilter filters={filters} setFilters={setFilters}/>
                            <ManagerFilter filters={filters} setFilters={setFilters}/>
                        </Stack>
                    </Grid>
                </ProtectedComponent>
                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                    <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <ImportMenu data={importMenu} callback={() => {
                                setFilters({...filters});
                            }}/>
                            <Button
                                component={Link}
                                variant='contained'
                                to={`${location.pathname}/add`}
                            >
                                Ajouter un adhérent
                            </Button>
                        </Stack>
                    </Grid>
                </ProtectedComponent>
            </Grid>
            <TableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={memberService.getAllMembers}
                filters={filters}
                customPagination
            />
        </>);
}

export default Members