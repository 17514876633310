import {createBrowserRouter, Navigate, Outlet, RouterProvider} from "react-router-dom";
import Home from "../pages/home";
import MainLayout from "../pages/MainLayout";
import Members from "../pages/member";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import ParkIcon from "@mui/icons-material/Park";
import SpaIcon from "@mui/icons-material/Spa";
import PersonIcon from '@mui/icons-material/Person';
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CalculateIcon from "@mui/icons-material/Calculate";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import MemberPage from "../pages/member/Page";
import ChenevisTickets from "../pages/ticket/chenevis";
import ChenevisPage from "../pages/ticket/chenevis/Page";
import StrawTickets from "../pages/ticket/straw";
import StrawPage from "../pages/ticket/straw/Page";
import SupplyTickets from "../pages/ticket/supply";
import SupplyPage from "../pages/ticket/supply/Page";
import ChenevisInvoices from "../pages/invoice/chenevis";
import ChenevisInvoice from "../pages/invoice/chenevis/Page";
import StrawInvoices from "../pages/invoice/straw";
import StrawInvoice from "../pages/invoice/straw/Page";
import SupplyInvoices from "../pages/invoice/supply";
import SupplyInvoice from "../pages/invoice/supply/Page";
import Contracts from "../pages/contract";
import ContractPage from "../pages/contract/Page";
import Parameters from "../pages/parameters";
import Articles from "../pages/parameters/referentials/Articles";
import Login from "../pages/security/login";
import Logout from "../pages/security/logout";
import Users from "../pages/parameters/users/Users";
import UserPage from "../pages/parameters/users/UserPage";
import Toolkits from "../pages/parameters/Toolkits";
import Drivers from "../pages/parameters/referentials/Drivers/Drivers"
import DriversPage from "../pages/parameters/referentials/Drivers/DriversPage"
import Kilometric from "../pages/parameters/referentials/Chenevis/Kilometric"
import KilometricPage from "../pages/parameters/referentials/Chenevis/Kilometric/KilometricPage"
import KilometricDryer from "../pages/parameters/referentials/Chenevis/KilometricDryer"
import KilometricDryerPage from "../pages/parameters/referentials/Chenevis/KilometricDryer/KilometricDryerPage"
import Humidity from "../pages/parameters/referentials/Chenevis/Humidity"
import HumidityPage from "../pages/parameters/referentials/Chenevis/Humidity/HumidityPage"
import ChenevisPaymentCondition from "../pages/parameters/referentials/Chenevis/PaymentCondition";
import VatCode from "../pages/parameters/referentials/VatCode/VatCode";
import VatCodePage from "../pages/parameters/referentials/VatCode/VatCodePage";
import DepartmentPage from "../pages/parameters/referentials/Departments/DepartmentPage";

import {PARAMETERS} from "../constants/parameters";
import Department from "../pages/parameters/referentials/Departments/Departments";
import ArticlePage from "../pages/parameters/referentials/Articles/ArticlePage";
import ArticleType from "../pages/parameters/referentials/ArticleTypes";
import ArticleTypePage from "../pages/parameters/referentials/ArticleTypes/ArticleTypePage";
import SilosPage from "../pages/parameters/referentials/Silos/SilosPage";
import Silos from "../pages/parameters/referentials/Silos/Silos";
import BallWeight from "../pages/parameters/referentials/Straw/BallWeight/BallWeight";
import BallWeightPage from "../pages/parameters/referentials/Straw/BallWeight/BallWeightPage";
import Pebbles from "../pages/parameters/referentials/Straw/Pebbles/Pebbles";
import PaymentCondition from "../pages/parameters/referentials/Straw/PaymentCondition";
import ColorManufacturing from "../pages/parameters/referentials/Straw/ColorManufacturing/ColorManufacturing";
import ColorDriver from "../pages/parameters/referentials/Straw/ColorDriver/ColorDriver";
import Uniformities from "../pages/parameters/referentials/Straw/Uniformities/Uniformities";
import StrawHumidity from "../pages/parameters/referentials/Straw/Humidity/Humidity";
import Pollution from "../pages/parameters/referentials/Straw/Pollution/Pollution";
import Presentation from "../pages/parameters/referentials/Straw/Presentation/Presentation";
import LoadingTime from "../pages/parameters/referentials/Straw/LoadingTime/LoadingTime";
import PebblesPage from "../pages/parameters/referentials/Straw/Pebbles/PebblesPage";
import ColorDriverPage from "../pages/parameters/referentials/Straw/ColorDriver/ColorDriverPage";
import ColorManufacturingPage from "../pages/parameters/referentials/Straw/ColorManufacturing/ColorManufacturingPage";
import UniformitiesPage from "../pages/parameters/referentials/Straw/Uniformities/UniformitiesPage";
import StrawHumidityPage from "../pages/parameters/referentials/Straw/Humidity/HumidityPage";
import PollutionPage from "../pages/parameters/referentials/Straw/Pollution/PollutionPage";
import PresentationPage from "../pages/parameters/referentials/Straw/Presentation/PresentationPage";
import LoadingTimePage from "../pages/parameters/referentials/Straw/LoadingTime/LoadingTimePage";
import Autoconnect from "../pages/security/login/Autoconnect";
import NotFound from "../pages/notFound";
import Simulation from "../pages/simulation";
import ResetPassword from "../pages/security/reset-password";
import InvoicingCompaniesPage from "../pages/parameters/referentials/InvoicingCompanies/InvoicingCompaniesPage";
import InvoicingCompanies from "../pages/parameters/referentials/InvoicingCompanies/InvoicingCompanies";
import SocialShares from "../pages/parameters/referentials/Straw/SocialShares";
import SocialSharesPage from "../pages/parameters/referentials/Straw/SocialShares/SocialSharesPage";
import CloneSilos from "../pages/parameters/referentials/CloneSilos";
import ShareMovements from "../pages/shareMovements";
import ShareMovementPage from "../pages/shareMovements/Page";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import UpdatePassword from "../pages/security/update-password";
import {ALL_ROLES, ROLES} from "../constants/roles";
import Notification from "../pages/notification";
import Engagements from "../pages/engagements";

export const routes = [
    {
        element: <MainLayout/>,
        children: [
            {
                path: '/',
                element: <Home/>,
                icon: <HomeIcon/>,
                handle: {
                    name: 'Accueil',
                    roles: ALL_ROLES,
                }
            },
            /**
             * MEMBERS
             */
            {
                path: '/members',
                element: <Outlet/>,
                icon: <PeopleIcon/>,
                handle: {
                    name: 'Adhérents',
                    roles: ALL_ROLES,
                },
                children: [
                    {
                        path: 'add',
                        element: <MemberPage/>,
                        handle: {
                            name: 'Ajouter un adhérent',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <MemberPage/>,
                        handle: {
                            name: 'Modifier un adhérent',
                            roles: ALL_ROLES,
                        },
                    },
                    {
                        index: true,
                        element: <Members/>,
                        handle: {
                            roles: ALL_ROLES,
                        },
                    }
                ]
            },
            /**
             * CONTRACTS
             */
            {
                path: '/contracts',
                element: <Outlet/>,
                icon: <ReceiptIcon/>,
                handle: {
                    name: 'Contrat / Déclaration',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
                children: [
                    {
                        path: 'add',
                        element: <ContractPage/>,
                        handle: {
                            name: 'Ajouter un contrat',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <ContractPage/>,
                        handle: {
                            name: 'Modifier un contrat',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    },
                    {
                        index: true,
                        element: <Contracts/>,
                        handle: {
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    }
                ]
            },

            /**
             * TICKETS
             */
            {
                path: '/chenevis',
                element: <Navigate to={'/chenevis/tickets'}/>,
                handle: {
                    name: 'Tickets Chenevis',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
            },
            {
                path: '/chenevis/tickets',
                element: <Outlet/>,
                icon: <ParkIcon/>,
                handle: {
                    name: 'Tickets Chenevis',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
                children: [
                    {
                        path: 'add',
                        element: <ChenevisPage/>,
                        handle: {
                            name: 'Ajout ticket chenevis',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <ChenevisPage/>,
                        handle: {
                            name: 'Modifier ticket chenevis',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    },
                    {
                        index: true,
                        element: <ChenevisTickets/>,
                        handle: {
                            name: 'Ticket chenevis',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    }
                ]
            },
            {
                path: '/straw',
                element: <Navigate to={'/straw/tickets'}/>,
                handle: {
                    name: 'Tickets Paille',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
            },
            {
                path: '/straw/tickets',
                element: <Outlet/>,
                icon: <SpaIcon/>,
                handle: {
                    name: 'Tickets Paille',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
                children: [
                    {
                        path: 'add',
                        element: <StrawPage/>,
                        handle: {
                            name: 'Ajout ticket paille',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <StrawPage/>,
                        handle: {
                            name: 'Modifier ticket paille',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    },
                    {
                        index: true,
                        element: <StrawTickets/>,
                        handle: {
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    }
                ]
            },
            {
                path: '/supply',
                element: <Navigate to={'/supply/tickets'}/>,
                handle: {
                    name: 'Modifier ticket paille',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
            },
            {
                path: '/supply/tickets',
                element: <Outlet/>,
                icon: <Inventory2Icon/>,
                handle: {
                    name: 'Tickets Approvisionnement',
                    roles: [ROLES.ADMIN, ROLES.MEMBER],
                },
                children: [
                    {
                        path: 'add',
                        element: <SupplyPage/>,
                        handle: {
                            name: 'Ajout ticket Approvisionnement',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <SupplyPage/>,
                        handle: {
                            name: 'Modifier ticket Approvisionnement',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    },
                    {
                        index: true,
                        element: <SupplyTickets/>,
                        handle: {
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    }
                ]
            },
            /**
             * INVOICES
             */
            {
                path: '/chenevis/invoices',
                element: <Outlet/>,
                icon: <DescriptionIcon/>,
                handle: {
                    name: 'Factures Chenevis',
                    roles: ALL_ROLES,
                },
                children: [
                    {
                        path: 'add',
                        element: <ChenevisInvoice/>,
                        handle: {
                            name: 'Ajout facture chenevis',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <ChenevisInvoice/>,
                        handle: {
                            name: 'Modifier facture chenevis',
                            roles: ALL_ROLES,
                        },
                    },
                    {
                        index: true,
                        element: <ChenevisInvoices/>,
                        handle: {
                            roles: ALL_ROLES,
                        },
                    }
                ]
            },
            {
                path: '/straw/invoices',
                element: <Outlet/>,
                icon: <DescriptionIcon/>,
                handle: {
                    name: 'Factures Paille',
                    roles: ALL_ROLES,
                },
                children: [
                    {
                        path: 'add',
                        element: <StrawInvoice/>,
                        handle: {
                            name: 'Ajout facture paille',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <StrawInvoice/>,
                        handle: {
                            name: 'Modifier facture paille',
                            roles: ALL_ROLES,
                        },
                    },
                    {
                        index: true,
                        element: <StrawInvoices/>,
                        handle: {
                            roles: ALL_ROLES,
                        },
                    }
                ]
            },
            {
                path: '/supply/invoices',
                element: <Outlet/>,
                icon: <DescriptionIcon/>,
                handle: {
                    name: 'Factures approvisionnement',
                    roles: ALL_ROLES,
                },
                children: [
                    {
                        path: 'add',
                        element: <SupplyInvoice/>,
                        handle: {
                            name: 'Ajout facture approvisionnement',
                            roles: [ROLES.ADMIN],
                        },
                    },
                    {
                        path: ':id',
                        element: <SupplyInvoice/>,
                        handle: {
                            name: 'Modifier facture approvisionnement',
                            roles: ALL_ROLES,
                        },
                    },
                    {
                        index: true,
                        element: <SupplyInvoices/>,
                        handle: {
                            roles: ALL_ROLES,
                        },
                    }
                ]
            },
            {
                path: '/straw/simulation',
                element: <Outlet/>,
                icon: <CalculateIcon/>,
                handle: {
                    name: 'Simulation de solde paille',
                    roles: [ROLES.ADMIN],
                },
                children: [
                    {
                        index: true,
                        element: <Simulation/>,
                        handle: {
                            roles: [ROLES.ADMIN],
                        },
                    }
                ]
            },
            {
                path: '/share-movements',
                element: <Outlet/>,
                icon: <CompareArrowsIcon/>,
                handle: {
                    name: 'Mouvements de parts',
                    roles: [ROLES.ADMIN, ROLES.ACCOUNTANT],
                },
                children: [
                    {
                        path: 'add',
                        element: <ShareMovementPage/>,
                        handle: {
                            name: 'Ajout transferts de parts',
                            roles: [ROLES.ADMIN, ROLES.ACCOUNTANT],
                        },
                    },
                    {
                        path: ':id',
                        element: <ShareMovementPage/>,
                        handle: {
                            name: 'Modifier transferts de parts',
                            roles: [ROLES.ADMIN, ROLES.MEMBER],
                        },
                    },
                    {
                        index: true,
                        element: <ShareMovements/>,
                        handle: {
                            roles: [ROLES.ADMIN, ROLES.ACCOUNTANT],
                        },
                    }
                ]
            },
            {
                path: '/engagements',
                element: <Outlet/>,
                icon: <DescriptionIcon/>,
                handle: {
                    name: 'Situation des engagements',
                    roles: ['ROLE_ADMIN'],
                },
                children: [
                    {
                        index: true,
                        element: <Engagements/>,
                        handle: {
                            roles: [ROLES.ADMIN],
                        },
                    }
                ]
            },
            /**
             * NOTIFICATIONS
             */
            {
                path: '/notifications',
                element: <Outlet/>,
                icon: null,
                handle: {
                    name: 'Notifications',
                    roles: [ROLES.ADMIN],
                },
                children: [
                    {
                        index: true,
                        element: <Notification/>,
                        handle: {},
                    }
                ]
            },
            /**
             * PARAMETERS
             */
            {
                path: '/parameters',
                element: <Outlet/>,
                icon: <SettingsIcon/>,
                handle: {
                    name: 'Paramètres',
                    roles: [ROLES.ADMIN],
                },
                children: [
                    {
                        index: true,
                        element: <Parameters/>,
                        handle: {},
                    },
                    {
                        path: 'users',
                        element: <Outlet/>,
                        icon: <PersonIcon/>,
                        handle: {
                            name: 'Utilisateurs'
                        },
                        children: [
                            {
                                index: true,
                                element: <Users/>,
                                handle: {},
                            },
                            {
                                path: PARAMETERS.ROUTE_ADD,
                                element: <UserPage/>,
                                handle: {
                                    name: 'Ajout d\'un utilisateur'
                                },
                            },
                            {
                                path: PARAMETERS.ROUTE_UPDATE,
                                element: <UserPage/>,
                                handle: {
                                    name: 'Modifier un utilisateur'
                                },
                            },
                        ]
                    },
                    {
                        path: 'toolkits',
                        element: <Outlet/>,
                        name: 'Référentiels',
                        icon: <SettingsIcon/>,
                        handle: {
                            name: 'Référentiels'
                        },
                        children: [
                            {
                                index: true,
                                element: <Toolkits/>,
                                handle: {},
                            },
                            {
                                path: 'articles',
                                element: <Outlet/>,
                                handle: {
                                    name: "Articles"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Articles/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <ArticlePage/>,
                                        name: "Ajout d'un article",
                                        handle: {
                                            name: "Ajout d'un article"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <ArticlePage/>,
                                        name: "Modification d'un article",
                                        handle: {
                                            name: "Modification d'un article"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'article-types',
                                element: <Outlet/>,
                                handle: {
                                    name: "Types d'article"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <ArticleType/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <ArticleTypePage/>,
                                        handle: {
                                            name: "Ajout d'un type d'article"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <ArticleTypePage/>,
                                        handle: {
                                            name: "Modification d'un type d'article"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'drivers',
                                element: <Outlet/>,
                                handle: {
                                    name: "Chauffeurs"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Drivers/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <DriversPage/>,
                                        handle: {
                                            name: "Ajout d'un chauffeur"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <DriversPage/>,
                                        name: "Modification d'un chauffeur",
                                        handle: {
                                            name: "Modificaition d'un chauffeur"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'chenevis/kilometric-allowances',
                                element: <Outlet/>,
                                handle: {
                                    name: "Chènevis - Barème indemnité kilométrique"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Kilometric/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <KilometricPage/>,
                                        handle: {
                                            name: "Ajout d'un barème indemnité kilométric"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <KilometricPage/>,
                                        handle: {
                                            name: "Modification d'un barème indemnité kilométric"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'chenevis/kilometrage-dryer-silos',
                                element: <Outlet/>,
                                handle: {
                                    name: "Chènevis - Barème indemnité kilométrique silo sécheur"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <KilometricDryer/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <KilometricDryerPage/>,
                                        handle: {
                                            name: "Ajout d'un barème indemnité kilométrique silo sécheur"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <KilometricDryerPage/>,
                                        handle: {
                                            name: "Modification d'un barème indemnité kilométrique silo sécheur"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'chenevis/humidity',
                                element: <Outlet/>,
                                handle: {
                                    name: "Chènevis - Barème humidité"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Humidity/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <HumidityPage/>,
                                        handle: {
                                            name: "Ajout d'un barème humidité"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <HumidityPage/>,
                                        handle: {
                                            name: "Modification d'un barème humidité"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'chenevis/payment-conditions',
                                element: <Outlet/>,
                                handle: {
                                    name: "Chènevis - Conditions de paiement"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <ChenevisPaymentCondition/>,
                                        handle: {},
                                    },
                                ]
                            },
                            {
                                path: 'vat-codes',
                                element: <Outlet/>,
                                handle: {
                                    name: "Codes TVA"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <VatCode/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <VatCodePage/>,
                                        handle: {
                                            name: "Ajout d'un code TVA"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <VatCodePage/>,
                                        handle: {
                                            name: "Modification d'un code TVA"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'departments',
                                element: <Outlet/>,
                                handle: {
                                    name: "Départements"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Department/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <DepartmentPage/>,
                                        handle: {
                                            name: "Ajout d'un département"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <DepartmentPage/>,
                                        handle: {
                                            name: "Modification d'un département"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'clone-silos',
                                element: <Outlet/>,
                                handle: {
                                    name: "Duplication des distances silos"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <CloneSilos/>,
                                        handle: {},
                                    },
                                ]
                            },
                            {
                                path: 'straw/pebbles',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barème cailloux"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Pebbles/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <PebblesPage/>,
                                        handle: {
                                            name: "Ajout d'un barème cailloux"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <PebblesPage/>,
                                        handle: {
                                            name: "Modification d'un barème cailloux"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/ball-weights',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barèmes poids de balle"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <BallWeight/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <BallWeightPage/>,
                                        handle: {
                                            name: "Ajout d'un barème poids de balle"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <BallWeightPage/>,
                                        handle: {
                                            name: "Modification d'un barème poids de balle"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/manufacturing-color',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Couleurs fabrication"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <ColorManufacturing/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <ColorManufacturingPage/>,
                                        handle: {
                                            name: "Ajout d'un barème couleurs fabrication"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <ColorManufacturingPage/>,
                                        handle: {
                                            name: "Modification d'un barème couleurs fabrication"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/driver-colors',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Couleurs chauffeur"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <ColorDriver/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <ColorDriverPage/>,
                                        handle: {
                                            name: "Ajout d'un barème couleurs des chauffeurs"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <ColorDriverPage/>,
                                        handle: {
                                            name: "Modification d'un barème couleurs des chauffeurs"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/uniformities',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barèmes homogénéité"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Uniformities/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <UniformitiesPage/>,
                                        handle: {
                                            name: "Ajout d'un barème homogénéité"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <UniformitiesPage/>,
                                        handle: {
                                            name: "Modification d'un barème homogénéité"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/humidities',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barème humidité"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <StrawHumidity/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <StrawHumidityPage/>,
                                        handle: {
                                            name: "Ajout d'un barème humidités paille"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <StrawHumidityPage/>,
                                        handle: {
                                            name: "Modification d'un barème humidités paille"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/pollutions',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barème pollution"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Pollution/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <PollutionPage/>,
                                        handle: {
                                            name: "Ajout d'un barème pollution"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <PollutionPage/>,
                                        handle: {
                                            name: "Modification d'un barème pollution"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/presentations',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barème présentation"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Presentation/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <PresentationPage/>,
                                        handle: {
                                            name: "Ajout d'un barème présentation"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <PresentationPage/>,
                                        handle: {
                                            name: "Modification d'un barème présentation"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/loading-times',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Barème temps de chargement"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <LoadingTime/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <LoadingTimePage/>,
                                        handle: {
                                            name: "Ajout d'un barème temps de chargement"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <LoadingTimePage/>,
                                        handle: {
                                            name: "Modification d'un barème temps de chargement"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'straw/payment-conditions',
                                element: <Outlet/>,
                                handle: {
                                    name: "Paille - Conditions de paiement"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <PaymentCondition/>,
                                        handle: {},
                                    },
                                ]
                            },
                            {
                                path: 'paille/social-parts',
                                element: <Outlet/>,
                                handle: {
                                    name: "Barème parts sociales"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <SocialShares/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <SocialSharesPage/>,
                                        handle: {
                                            name: "Ajout d'une part sociale"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <SocialSharesPage/>,
                                        handle: {
                                            name: "Modification d'une part social"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'silos',
                                element: <Outlet/>,
                                handle: {
                                    name: "Silos"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Silos/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <SilosPage/>,
                                        handle: {
                                            name: "Ajout d'un silo"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <SilosPage/>,
                                        handle: {
                                            name: "Modification d'un silo"
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'invoicing-companies',
                                element: <Outlet/>,
                                handle: {
                                    name: "Société"
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <InvoicingCompanies/>,
                                        handle: {},
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_ADD,
                                        element: <InvoicingCompaniesPage/>,
                                        handle: {
                                            name: "Ajout d'une société"
                                        },
                                    },
                                    {
                                        path: PARAMETERS.ROUTE_UPDATE,
                                        element: <InvoicingCompaniesPage/>,
                                        handle: {
                                            name: "Modification d'une société"
                                        },
                                    },
                                ]
                            },
                        ]

                    },
                ]
            },
        ],
    },
    {
        element: <Outlet/>,
        children: [
            {
                path: '/login',
                element: <Login/>,
            },
            {
                path: '/logout',
                element: <Logout/>,
            },
            {
                path: '/reset-password',
                element: <ResetPassword/>,
            },
            {
                path: '/autoconnect/:token',
                element: <Autoconnect/>
            },
            {
                path: '/set-password/:token',
                element: <UpdatePassword/>,
            },
        ]
    },
    {
        path: '*',
        element: <NotFound/>
    }
];

const AppRouter = () => {
    const router = createBrowserRouter(routes);
    return <RouterProvider router={router}/>
}

export default AppRouter;