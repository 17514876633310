import {
    matchRoutes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Table from "../../components/Mui/DataGrid";
import {routes} from "../../utils/routes";
import _ from "lodash";


function getChildrenRoutes(routes, location) {
    const matches = matchRoutes(routes, location);
    const route = matches.find(el => el.pathname === location.pathname);
    return route?.route?.children?.filter(el => !!el.path) ?? [];
}


const Toolkits = () => {
    const location = useLocation();
    const children = getChildrenRoutes(routes, location);
    const navigate = useNavigate();
    const rows = children.map((el, i) => ({...el, id: i})); // Just adding ID
    const columns = [
        {
            field: 'handle.name',
            headerName: 'Nom référentiels',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
    ];

    return (
        <>
            <Table
                rows={rows}
                columns={columns}
                pageSize={10}
                onRowClick={(params) => navigate(params.row.path)}
                customPagination
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'handle.name', sort: 'asc' }],
                    },
                }}
            />
        </>
    );
}

export default Toolkits