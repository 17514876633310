import {Link, useLocation, useNavigate} from "react-router-dom";
import {hydraService} from "../../utils/hydra";
import {Button, Grid, MenuItem, Stack} from "@mui/material";
import {PARAMETERS} from "../../constants/parameters";
import _ from "lodash";
import ModalClone from "../../pages/parameters/referentials/ModalClone";

import {useState} from "react";
import MuiTableAsync from "../Mui/DataGridAsync";
import {frFR} from "@mui/x-data-grid";
import HarvestYearFilter from "../Filters/HarvestYearFilter";
import {useFilterContext} from "../../context/FilterContext";

const ReferentialTable = ({columns, addLabel, callback, cloneEntity, enableFilters = true, ...otherProps}) => {
    if (!columns && !_.isArray(columns)) {
        throw new Error(`"columns" must be an array et not be empty.`)
    }
    if (!callback && !_.isFunction(callback)) {
        throw new Error(`"callback" must be a function et not be empty.`)
    }

    const location = useLocation();
    const navigate = useNavigate();

    const {getFilterHash} = useFilterContext();
    const [filters, setFilters] = useState(getFilterHash);

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={3} className="Grid">
                    {enableFilters &&
                        <Stack spacing={1} direction={'row'}>
                            <HarvestYearFilter name={'year'} label={'Année'} filters={filters} setFilters={setFilters}/>
                        </Stack>
                        }
                </Grid>
                <Grid container item xs={9} justifyContent={'flex-end'} className="Grid">
                    {cloneEntity && <ModalClone cloneEntity={cloneEntity} setFilters={setFilters} />}
                    <Button component={Link} variant="contained" to={PARAMETERS.ROUTE_ADD}>{addLabel}</Button>
                </Grid>
            </Grid>
            <MuiTableAsync
                columns={columns}
                request={callback}
                onRowClick={(params) => navigate(`${location.pathname}/${hydraService.getIdFromIri(params.row)}`)}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                customPagination
                filters={filters}
                {...otherProps}
            />
        </>
    );
}

export default ReferentialTable;