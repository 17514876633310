import {ROLES} from "../../constants/roles";
import {Stack} from "@mui/material";
import {strawInvoiceService} from "../../services/strawInvoice.service";
import ConfirmationAction from "./ConfirmationAction";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {useNavigate} from "react-router-dom";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {chenevisInvoiceService} from "../../services/chenevisInvoice.service";
import { supplyInvoiceService } from "../../services/supplyInvoice.service";

const ActionInvoice = ({data}) => {
    const navigate = useNavigate();

    const generateInvoiceCreditRequests = {
        'ChenevisInvoice': chenevisInvoiceService.generateInvoiceCredit,
        'StrawInvoice':  strawInvoiceService.generateInvoiceCredit,
        'SupplyInvoice': supplyInvoiceService.generateInvoiceCredit,
    }

    const removeInvoiceRequests = {
        'ChenevisInvoice': chenevisInvoiceService.removeChevenisInvoice,
        'StrawInvoice':  strawInvoiceService.removeStrawInvoice,
        'SupplyInvoice': supplyInvoiceService.removeSupplyInvoice,
    }

    const backRoutes = {
        'ChenevisInvoice': '/chenevis/invoices',
        'StrawInvoice':  '/straw/invoices',
        'SupplyInvoice': '/supply/invoices',
    }

    const generateInvoiceCreditRequest = generateInvoiceCreditRequests[data['@type']];
    const removeInvoiceRequest = removeInvoiceRequests[data['@type']];
    const backRoute = backRoutes[data['@type']];

    return (
        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
            <Stack direction={'row'}
                   spacing={2}
                   justifyContent={'center'}
                   my={3}
            >
                {data.credit === false && (
                    <ConfirmationAction request={generateInvoiceCreditRequest}
                                        callback={() => navigate(backRoute)}
                                        buttonLabel={'Créer un avoir'}
                                        modalTitle={'Créer un avoir'}
                                        modalContent={'Voulez vous créer un avoir ?'}
                                        startIcon={<CardGiftcardIcon/>}
                                        color={'info'}
                                        disabled={!data.canGenerateCredit}
                    />
                )}
                <ConfirmationAction request={removeInvoiceRequest}
                                    callback={() => navigate(backRoute)}
                                    buttonLabel={'Supprimer cette facture'}
                                    modalTitle={'Supprimer cette facture'}
                                    modalContent={'Voulez vous supprimer cette facture ?'}
                                    startIcon={<DeleteForeverIcon/>}
                                    color={'error'}
                                    disabled={!data.canBeRemove}
                />
            </Stack>
        </ProtectedComponent>
    );
}

export default ActionInvoice;