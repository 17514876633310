import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import {useEffect, useState} from "react";
import Loader from "../../../Loader";
import snackBar from "../../../../components/SnackBar";
import ModalDelete from "../ModalDelete";
import {hydraService} from "../../../../utils/hydra";
import {articleTypeService} from "../../../../services/articleType.service";
import Checkbox from "../../../../components/Formik/Checkbox";
import Box from "@mui/material/Box";
import {run} from "../../../../utils/services";
import BackButton from "../../../../components/BackButton";

const ArticleTypePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [isLoading, setIsLoading] = useState(!!id);
    const [data, setData] = useState({
        code: '',
        label: '',
        status: true
    });

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        (async () => {
            if(!isCreation) {
                run(articleTypeService.getArticleType, id).then((response) => {
                    setData(response.responseData);
                    setIsLoading(false);
                });
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if(!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(articleTypeService.removeArticleType,{id});
        }
    }

    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (!id) {
                        run(articleTypeService.addArticleType,values, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                                navigate(PARAMETERS.BACK_ROUTE);
                            });
                    } else {
                        const id = hydraService.getIdFromIri(values);
                        run(articleTypeService.editArticleType,{id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                            });
                    }
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={12} item>
                            <TextField label={'Code'} name={'code'} />
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <TextField label={"Nom du type d'article"} name={'label'}/>
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Checkbox name={'status'} label={'Actif'}/>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
            <Box>
                {!isCreation && <ModalDelete title="du type d'article" handleDelete={handleDelete} />}
            </Box>

        </>
    )

}

export default ArticleTypePage