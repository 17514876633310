import {
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiDrawer from "@mui/material/Drawer";
import {Link as RouterLink, useLocation} from "react-router-dom"
import {forwardRef, useContext, useMemo} from "react";
import {routes} from "../utils/routes";
import logo from "../assets/images/logo-chanvriere-blanc.png";
import {useUserContext} from "../context/UserContext";

const drawerWidth = 270;

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);

function ListItemLink(props) {
    const {icon, handle: {name}, path, location} = props;

    const renderLink = useMemo(
        () =>
            forwardRef(function Link(itemProps, ref) {
                return <RouterLink to={path} ref={ref} {...itemProps} role={undefined}/>;
            }),
        [path],
    );

    return (
        <li>
            <ListItemButton component={renderLink} selected={path === '/' ? location.pathname === path : location.pathname.startsWith(path)}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={name}/>
            </ListItemButton>
        </li>
    );
}

function Mapping(props) {
    const location = useLocation();
    const {routes} = props;
    const {currentUser: {roles: currentRoles}} = useUserContext();
    let links = [];

    const generateMapping = (routes) => {
        for (let i in routes) {
            const route = routes[i];
            if (route.icon && route.handle.roles?.some(v => (currentRoles || []).includes(v))) {
                links.push(<ListItemLink key={i} location={location} {...route} />);
            }
            if (!route.children) continue;
            generateMapping(route.children)
        }
        return links;
    }

    return generateMapping(routes, '/');
}

const DrawerLayout = ({open, toggleDrawer}) => {
    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <img src={logo} alt={'logo'} height={50}/>
                <IconButton onClick={toggleDrawer} sx={{color: "#FFF"}}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>

            <List component="nav" className="MuiList">
                <Mapping routes={routes}/>
            </List>
        </Drawer>
    );
}

export default DrawerLayout