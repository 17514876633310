import {useGridApiContext} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import {hydraService} from "../../../utils/hydra";
import _ from "lodash";
import {useState} from "react";

const DeleteSelection = ({request, callback = null}) => {
    const apiRef = useGridApiContext();
    const selectedRows = apiRef.current.getSelectedRows();
    const {size} = selectedRows;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleDelete = () => {
        setIsSubmitting(true);
        Promise.all(
            Array.from(selectedRows.keys(), (key) => {
                const id = hydraService.getIdFromIri(key);
                return request({id, status: false});
            })
        ).then(() => {
            apiRef.current.setSelectionModel([]);
            if (_.isFunction(callback) === true) {
                callback();
            }
        }).finally(() => {
            setIsSubmitting(false);
        });
    }

    return request && size > 0 ? (
        <Button variant={'contained'} color={'error'} disabled={isSubmitting} onClick={handleDelete}>
            {`Supprimer (${size})`}
        </Button>
    ) : <div/>
}

export default DeleteSelection;