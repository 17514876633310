import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {hydraService} from "../../../utils/hydra";
import _ from "lodash";

const AutocompleteAsyncWrapper = ({
                                      name,
                                      label,
                                      filterName,
                                      request,
                                      callback,
                                      getOptionLabel,
                                      isOptionEqualToValue,
                                      value,
                                      callbackOnClear,
                                      ...otherProps
                                  }) => {
    if (!request) {
        throw new Error(`"request" must be a string et not be empty.`)
    }
    if (!callback && !_.isFunction(callback)) {
        throw new Error(`"callback" must be a function et not be empty.`)
    }

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    const configAutocompleteAsync = {
        fullWidth: true,
        autoComplete: true,
        includeInputInList: true,
        filterSelectedOptions: true,
        filterOptions: (x) => x,
        onChange: (e, value, reason) => {
            setSelected(value);
            setInputValue('');
            callback(e, value);
            if (reason === 'clear') {
                setSelected(null);
                if (callbackOnClear && _.isFunction(callbackOnClear)) {
                    callbackOnClear();
                }
            }
        },
        ...(getOptionLabel && {getOptionLabel: getOptionLabel}),
        ...(isOptionEqualToValue && {isOptionEqualToValue: isOptionEqualToValue}),
        ...otherProps
    }

    useEffect(() => {
        let active = true;
        let timeOutId = null;

        if (!inputValue) {
            setOptions(selected ? [selected] : []);
            return;
        }

        timeOutId = setTimeout(() => {
            request({[filterName ?? name]: inputValue})
                .then((response) => {
                    if (active) {
                        const members = hydraService.getMembers(response.responseData);
                        setOptions(members);
                    }
                });
        }, 600);

        return () => {
            clearTimeout(timeOutId);
            active = false;
        };
    }, [inputValue, selected])

    return (
        <>
            <Autocomplete
                name={name}
                options={options}
                value={selected||value}
                onInputChange={(e, value) => {
                    setInputValue(_.trim(value));
                }}
                renderInput={(params) => <TextField {...params} label={label} placeholder={"Rechercher..."}/>}
                noOptionsText="Pas de résultat"
                {...configAutocompleteAsync}
            />
        </>

    );
}

export default AutocompleteAsyncWrapper;