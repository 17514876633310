import {Grid, MenuItem} from "@mui/material";
import Select from "../../components/Formik/Select";
import {useFormikContext} from "formik";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {ENDPOINTS} from "../../constants/endpoints";

const InvoicingCompaniesSelect = ({catalog}) => {
    const {id} = useParams();
    const {values, setFieldValue} = useFormikContext();
    const {invoicingCompany} = values;
    const {companies} = catalog

    useEffect(() => {
        if(!id) {
            setFieldValue('invoicingCompany', `${ENDPOINTS.INVOICING_COMPANIES}/1`)
        }
    }, [invoicingCompany, companies])

    return (
        <Grid xs={12} md={12} item>
            <Select name={'invoicingCompany'} label={'Nom société de rattachement à la chanvrière'}>
                <MenuItem value="">
                    <em>Aucun</em>
                </MenuItem>
                {companies.map((el, i) => <MenuItem key={i} value={el['@id']}>{el.code}</MenuItem>)}
            </Select>
        </Grid>
    )
}

export default InvoicingCompaniesSelect