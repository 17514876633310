import _ from "lodash";

class Hydra {
    /**
     *
     * @param object hydraResponse
     * @returns {*}
     */
    getMembers(hydraResponse) {
        try {
            return hydraResponse['hydra:member'];
        } catch (e) {
            console.error(e);
        }
    }

    /**
     *
     * @param object hydraResponse
     * @returns {*}
     */
    getTotalItems(hydraResponse) {
        return hydraResponse['hydra:totalItems'];
    }

    /**
     *
     * @param object object
     * @returns {undefined|*}
     */
    getIriFromItem(object) {
        if (_.isObject(object) && _.has(object, '@id')) {
            return _.get(object, '@id');
        }
        return object;
        // throw new Error(`This object is not a valid hydra implementation.`);
    }

    /**
     *
     * @param string iri
     * @param array options
     */
    getItemFromIri(iri, options) {
        if (_.isArray(options) && options.every(el => _.has(el, '@id'))) {
            return options.find(el => el['@id'] === iri);
        }
        return iri;
        // throw new Error(`Options is not an array for Hydra Service.`);
    }

    /**
     *
     * @param object|string mixed
     * @returns {*}
     */
    getIdFromIri(mixed) {
        if (_.isString(mixed)) {
            return mixed.split('/').pop();
        }
        if (_.isObject(mixed) && _.has(mixed, '@id')) {
            return _.get(mixed, '@id').split('/').pop();
        }
        return mixed;
        // throw new Error(`This object is not a valid hydra implementation.`);
    }
}

export const hydraService = new Hydra();