import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";
import moment from "moment";

class ContractService {
  async getAllContracts(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.CONTRACT + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getContract(id) {
    return axios
      .get(`${ENDPOINTS.CONTRACT}/${id}?timestamp=${moment().unix()}`)
      .then(handleResponse);
  }

  async addContract(parameters) {
    return axios
      .post(ENDPOINTS.CONTRACT, parameters)
      .then(handleResponse);
  }

  async editContract(parameters) {
    return axios
      .put(`${ENDPOINTS.CONTRACT}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeContract(parameters) {
    return axios
      .delete(`${ENDPOINTS.CONTRACT}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const contractService = new ContractService();
