import {articleTypeService} from "../../../../services/articleType.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const ArticleType = () => {
    const columns = [
        {field: 'code', headerName: 'Code', flex: 0.7},
        {field: 'label', headerName: 'Nom', flex: 1.3},
    ];

    return <ReferentialTable
        columns={columns}
        callback={articleTypeService.getAllArticleTypes}
        addLabel={'Ajouter un type d\'article'}
        enableFilters={false}
    />
}

export default ArticleType