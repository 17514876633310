import axios from "axios";

export const setToken = (token) => {
    window.localStorage.setItem("token", token);
};

export const getViolations = (axiosError) => axiosError?.response?.data?.violations;
export const getErrors = (axiosError) => axiosError?.response?.data['hydra:description'];

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/ld+json',
        post: {
            'Accept': 'application/ld+json'
        },
        put: {
            'Accept': 'application/ld+json'
        },
        get: {
            'Accept': 'application/ld+json'
        }
    },
    timeout: 20000
});
instance.interceptors.request.use(function(request) {

    request.headers['Content-Type'] = 'application/ld+json';

    const token = localStorage.getItem('token');
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
}, function(error){
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (localStorage.getItem('token') && error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login?disconnected';
        return;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
// instance.defaults.headers['Content-Type'] = 'application/ld+json';
// instance.defaults.headers.post['Accept'] = 'application/ld+json';
// instance.defaults.headers.put['Accept'] = 'application/ld+json';
// instance.defaults.timeout = 2000;
instance.defaults.raxConfig = {
    instance: instance
};
// rax.attach(instance);

export default instance;
