import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class SupplyService {
  async getAllSupplies(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.SUPPLY + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getSupply(id) {
    return axios
      .get(`${ENDPOINTS.SUPPLY}/${id}`)
      .then(handleResponse);
  }

  async addSupply(parameters) {
    return axios
      .post(ENDPOINTS.SUPPLY, parameters)
      .then(handleResponse);
  }

  async editSupply(parameters) {
    return axios
      .put(`${ENDPOINTS.SUPPLY}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async validateSupply(parameters) {
    console.log(parameters, 'params')
    return axios
      .post(ENDPOINTS.VALIDATE_SUPPLY_TICKET, parameters)
      .then(handleResponse);
  }

  async removeSupply(parameters) {
    return axios
      .delete(`${ENDPOINTS.SUPPLY}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const supplyService = new SupplyService();
