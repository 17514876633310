import {articleService} from "../../../../services/article.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const Articles = () => {

    const columns = [
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'label', headerName: 'Libellé', flex: 1},
        {field: 'status', headerName: 'Actif ?', flex: 1,
            valueFormatter: (params) => params.value ? 'Oui' : 'Non'},
    ];

    return <ReferentialTable
            columns={columns}
            callback={articleService.getAllArticles}
            addLabel={'Ajouter un article'}
            enableFilters={false}
    />
}

export default Articles;