import {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import NumberField from "../../../../components/Formik/NumberField";
import SnackBar from "../../../../components/SnackBar";
import {useStrawTicketContext} from "../../../../context/StrawTicketContext";

const ManufacturedBallField = ({catalog = {}, name = 'fabrication', loading = false}) => {
    const {values, setValues} = useFormikContext();
    const {calculateBallWeight} = useStrawTicketContext()
    const [ready, setReady] = useState(false);
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!ready) { // Hack reloading
            setReady(true);
            return;
        }
        const timeoutId = setTimeout(async () => {
            if (!catalog.ballWeights.length) {
                SnackBar(`Le barème du poids des balles est indéfini en ${values.harvestYear}`, 'error');
                return;
            }
            const ballWeight = await calculateBallWeight(catalog, values, values[name].manufacturedBall);
            setValues(prevValues => ({
                ...prevValues,
                fabrication: {
                    ...prevValues.fabrication,
                    ballWeight: ballWeight
                }
            }));
        }, 600);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [
        values[name].manufacturedBall
    ]);

    return (
        <NumberField
            name={`${name}.manufacturedBall`}
            label={'Nombre de balles fabriquées'}
            decimal={0}
            nullIfEmpty
        />
    );
}

export default ManufacturedBallField;