import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ShareMovementService {
    async getAllMovements(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.SHARE_MOVEMENTS_ALL + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async addMovement(parameters) {
        return axios
            .post(ENDPOINTS.SHARE_MOVEMENTS, parameters)
            .then(handleResponse);
    }

    async getMovement(id) {
        return axios
            .get(`${ENDPOINTS.SHARE_MOVEMENTS_ALL}/${id}`)
            .then(handleResponse);
    }
}

export const shareMovementService = new ShareMovementService();
