import {useNavigate, useParams} from "react-router-dom";
import ReturnButton from "../../../components/Mui/RedirectButton";
import {Form, Formik} from "formik";
import {Divider, Grid, IconButton, InputAdornment, MenuItem, Stack} from "@mui/material";
import TextField from "../../../components/Formik/TextField";
import Select from "../../../components/Formik/Select";
import Checkbox from "../../../components/Formik/Checkbox";
import Button from "../../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {PARAMETERS} from "../../../constants/parameters";
import {FORM} from "../../../constants/form";
import {useEffect, useState} from "react";
import {userService} from "../../../services/user.service";
import Loader from "../../Loader";
import snackBar from "../../../components/SnackBar";
import ModalDelete from "../referentials/ModalDelete";
import {run} from "../../../utils/services";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BackButton from "../../../components/BackButton";

const UserPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [isLoading, setIsLoading] = useState(!!id)
    const [data, setData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        plainPassword: '',
        confirmPassword: '',
        roles: '',
        status: true,
    });
    const [showPlainPassword, setShowPlainPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        if (!!id) {
            run(userService.getUser, id).then((response) => {
                setData({...response.responseData, roles: response.responseData.roles[0]});
                setIsLoading(false);
            });
        }
    }, []);

    let optionsValues = [
        {id: 0, value: "ROLE_ADMIN", label: "Administrateur"},
        {id: 1, value: "ROLE_ACCOUNTANT", label: "Comptable"}
    ];

    const handleClickShowPassword = (setPassword) => setPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDelete = () => {
        if (!!id) {
            (async () => {
                await run(userService.removeUser,{id});
                snackBar('Suppression effectuée');
                navigate('/parameters/users');
            })();
        }
    }

    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    const parameters = {...values, roles: [values.roles].filter(el => !!el), id};
                    if (!id) {
                        run(userService.addUser, parameters, {setFieldError, setSubmitting})
                            .then((response) => {
                                snackBar('Enregistrement effectué');
                                navigate(`/parameters${response.responseData['@id']}`);
                                values.plainPassword = null;
                                values.confirmPassword = null;
                                return;
                            });
                    } else {
                        run(userService.editUser, parameters, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                                values.plainPassword = null;
                                values.confirmPassword = null;
                            });
                    }
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={6} item>
                            <TextField label={'Nom'} name={'firstname'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Prénom'} name={'lastname'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Email'} name={'email'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Select name={'roles'} label={'Rôle'}>
                                <MenuItem value={''}><em>Aucun</em></MenuItem>
                                {optionsValues.map((el, i) => <MenuItem key={i} value={el.value}>{el.label}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid xs={12} md={12} item>
                            <Checkbox name={'status'} label={'Actif'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Mot de passe'} name={'plainPassword'} type={showPlainPassword ? 'text' : 'password'}
                               InputProps={{
                                   endAdornment:
                                       <InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={() => handleClickShowPassword(setShowPlainPassword)}
                                               onMouseDown={handleMouseDownPassword}
                                               edge="end"
                                           >
                                               {showPlainPassword ? <VisibilityOff/> : <Visibility/>}
                                           </IconButton>
                                       </InputAdornment>
                               }}
                            />
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <TextField label={'Confirmer le mot de passe'} name={'confirmPassword'} type={showConfirmPassword ? 'text' : 'password'}
                               InputProps={{
                                   endAdornment:
                                       <InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={() => handleClickShowPassword(setShowConfirmPassword)}
                                               onMouseDown={handleMouseDownPassword}
                                               edge="end"
                                           >
                                               {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                           </IconButton>
                                       </InputAdornment>
                               }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>

            {!isCreation && <ModalDelete handleDelete={handleDelete}/>}
        </>
    )
}

export default UserPage