import React, {createContext, useCallback, useContext, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export const FilterContext = createContext();

export const FilterContextProvider = (props) => {
    const filterHashName = '#filters:';
    const navigate = useNavigate();
    const {hash, pathname, } = useLocation();
    const hashRef = useRef('');
    const pathRouteRef = useRef(null);

    // Reset filters
    if (!pathname.startsWith(pathRouteRef.current)) {
        hashRef.current = '';
        pathRouteRef.current = pathname
    }

    const setFilterHash = useCallback((object) => {
        const f = new URLSearchParams(object);
        hashRef.current = f.toString().length > 0 ? filterHashName + f.toString() : '';
        navigate(pathname + hashRef.current)
    }, [hashRef, filterHashName, pathname]);

    const getFilterHash = useCallback(() => {
        const f = new URLSearchParams(hash.slice(filterHashName.length));
        return Object.fromEntries(f);
    }, [hash, filterHashName]);

    const getHashRef = useCallback(() => hashRef.current, [hashRef]);

    return (
        <FilterContext.Provider value={{setFilterHash, getFilterHash, getHashRef}}>
            {props.children}
        </FilterContext.Provider>
    );
}

export function useFilterContext() {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilterContext must be used within a FilterContextProvider');
    }
    return context;
}