import {Grid} from "@mui/material";
import NumberField from "../../components/Formik/NumberField";

const Deposits = ({form}) => {
    const {values} = form;
    return (
        <>
            {values.deposits.map((el, i) => {
                return (
                    <Grid key={i} item xs={12} md={3}>
                        <NumberField
                            asString
                            name={`deposits[${i}].amount`}
                            label={`${i+1}${i === 0 ? 'er' : 'ème'} acompte`}
                            disabled
                        />
                    </Grid>
                )
            })}
        </>
    );
}

export default Deposits;

