import {forwardRef, useCallback} from "react";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar, SnackbarContent} from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import download from "../../Download";

const useStyles = makeStyles(() => ({
    root: {
        "@media (min-width:600px)": {
            minWidth: "344px !important"
        }
    },
    card: {
        width: "100%"
    },
    typography: {
        color: "#000"
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between"
    },
    icons: {
        marginLeft: "auto"
    },
    expand: {
        padding: "8px 8px",
        color: "#000",
    },
    svg: {
        width: '1.5em !important',
        height: '1.5em !important'
    },
    paper: {
        backgroundColor: "#fff",
        padding: 16
    },
    checkIcon: {
        fontSize: 20,
        paddingRight: 4
    },
    button: {
        padding: 0,
        textTransform: "none"
    }
}));

const DownloadComplete = forwardRef(
    ({id, ...props}, ref) => {
        if (props.data === undefined) {
            throw new Error('Props "data" is required for download file.');
        }
        const classes = useStyles();
        const {closeSnackbar} = useSnackbar();

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        const parser = new DOMParser();

        return (
            <SnackbarContent ref={ref} className={classes.root}>
                <Card className={classes.card} style={{backgroundColor: "#a6c565"}}>
                    <CardActions classes={{root: classes.actionRoot}}>
                        <Typography variant="body2" className={classes.typography}>
                            {props.message}
                        </Typography>
                        <div className={classes.icons}>
                            {props?.data?.options?.file &&
                                <IconButton
                                    size="small"
                                    className={classes.expand}
                                    onClick={() => {
                                        download(props.data.options.file, true);
                                        closeSnackbar(id);
                                    }}
                                >
                                    <DownloadForOfflineIcon fontSize="small" className={classes.svg}/>
                                </IconButton>}
                            <IconButton
                                size="small"
                                className={classes.expand}
                                onClick={handleDismiss}
                            >
                                <CloseIcon fontSize="small" className={classes.svg}/>
                            </IconButton>
                        </div>
                    </CardActions>
                </Card>
            </SnackbarContent>
        );
    }
);

DownloadComplete.displayName = "DownloadComplete";

export default DownloadComplete;