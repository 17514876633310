import React, {createContext, useContext, useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {authentificationService} from "../services/authentification.service";
import moment from "moment";
import {StrawTicketContext} from "./StrawTicketContext";

export const UserContext = createContext(undefined);

export const UserContextProvider = (props) => {

    const INITIAL_USER = {
        id: null,
        username: null,
        fullname: null,
        roles: [],
        notificationUnread: 0
    }
    const [timestamp,] = useState(moment().unix());
    const [currentUser, setCurrentUser] = useState(INITIAL_USER);
    const [notificationUnread, setNotificationUnread] = useState(0);

    // On each reload, just 1 time.
    useEffect(() => {
        const jwt = localStorage.getItem('token');
        if (jwt) {
            setCurrentUser(jwtDecode(jwt));
            setNotificationUnread(jwtDecode(jwt).notificationUnread);
        }
        return () => {
            setCurrentUser(INITIAL_USER);
            setNotificationUnread(null);
        }
    }, [timestamp]); // Patch in order to reload the cache.

    const signIn = (credentials) => authentificationService.login(credentials);

    const RequireAuth = (props) => {
        const jwt = localStorage.getItem('token');
        const location = useLocation();
        if (!jwt) {
            resetToken();
            return <Navigate to={'/login'} state={{prev: location.pathname}}/>
        }
        if (jwt && !isTokenValid(jwt)) {
            resetToken();
            return <Navigate to={'/login?disconnected'} state={{prev: location.pathname}}/>
        }
        if (props.roles && !props.roles.some(v => jwtDecode(jwt).roles.includes(v))) {
            resetToken();
            return <Navigate to={'/login?disconnected'} state={{prev: location.pathname}}/>
        }
        return props.children;
    }

    const isTokenValid = (token) => {
        if (token) {
            const exp = jwtDecode(token).exp * 1000
            if (Date.now() < exp) return true;
        }
        return false
    }

    const b64DecodeUnicode = (str) => {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    const jwtDecode = (token) => {
        return JSON.parse(b64DecodeUnicode(token.split('.')[1]));
    }

    const storeToken = (token) => {
        localStorage.setItem('token', token);
        setCurrentUser(jwtDecode(token));
        setNotificationUnread(jwtDecode(token).notificationUnread);
    }

    const resetToken = () => {
        localStorage.removeItem('token');
    }

    const isGranted = (...acceptedRoles) => {
        return (currentUser.roles || []).some(role => (acceptedRoles || []).includes(role));
    }

    return (
        <UserContext.Provider value={{
            signIn, storeToken, resetToken,
            RequireAuth, currentUser, notificationUnread, setNotificationUnread, isGranted
        }}>
            {props.children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }
    return context;
}
