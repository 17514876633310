import React, {createContext, useContext, useState} from "react";
import {hydraService} from "../utils/hydra";
import {strawService} from "../services/straw.service";
import {handleResponse, run} from "../utils/services";
import axios from "../utils/axios";
import {ENDPOINTS} from "../constants/endpoints";

export const StrawTicketContext = createContext(undefined);

export const StrawTicketContextProvider = (props) =>  {

    const [memberSelected, setMemberSelected] = useState(null);
    const [contractSelected, setContractSelected] = useState(null);
    const [hasContractChange, setHasContractChange] = useState(false);

    const calculateBallWeight = async (catalog, values, manufacturedBallValue) => {
        let {ballWeights = null} = catalog;

        if (!ballWeights) {
            ballWeights = await run(strawService.getAllStrawBallWeights, {pagination: false, year: values.harvestYear});
            ballWeights = hydraService.getMembers(ballWeights.responseData);
        }
        const deliveredWeight = Math.max(+values.grossWeight - +values.tareWeight, 0).toFixed(2);
        const workedWeight = Math.max(+deliveredWeight * 1000 - values.fabrication.pebbleWeight, 0).toFixed(2);
        const w = +manufacturedBallValue > 0 ? +workedWeight / +manufacturedBallValue : 0;
        const weight = ballWeights?.find(el => w <= +el.endScale) ?? [...catalog.ballWeights].pop();
        return hydraService.getIriFromItem(weight);
    }

    return (
        <StrawTicketContext.Provider value={{
            memberSelected, setMemberSelected,
            contractSelected, setContractSelected,
            hasContractChange, setHasContractChange,
            calculateBallWeight
        }}>
            {props.children}
        </StrawTicketContext.Provider>
    );
}

export function useStrawTicketContext() {
    const context = useContext(StrawTicketContext);
    if (!context) {
        throw new Error('useStrawTicketContext must be used within a StrawTicketContextProvider');
    }
    return context;
}
