import axios from "axios";
import _ from "lodash"
import {fileService} from "../../services/file.service";
import snackBar from "../SnackBar";

const Download = (url, remove = false) => {
    const basename = String(_.last(url.split('/')));
    axios.get(url, {
        responseType: "blob",
        headers: {
            'Content-type': 'application/pdf'
        }
    }).then(({data}) => {
        const fileURL = window.URL.createObjectURL(new Blob([data]));
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = basename;
        alink.click();
        alink.remove();
        URL.revokeObjectURL(url);
        // Delete file
        if (remove === true) {
            fileService.remove({url});
        }
    }).catch(error => {
        snackBar('Une erreur est survenue. Merci de contacter votre administrateur.', 'error');
    })
}

export default Download;