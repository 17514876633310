import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class StrawService {
  async getAllStraws(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.STRAW + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getStraw(id) {
    return axios
      .get(`${ENDPOINTS.STRAW}/${id}`)
      .then(handleResponse);
  }

  async addStraw(parameters) {
    return axios
      .post(ENDPOINTS.STRAW, parameters)
      .then(handleResponse);
  }

  async editStraw(parameters) {
    return axios
      .put(`${ENDPOINTS.STRAW}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeStraw(parameters) {
    return axios
      .delete(`${ENDPOINTS.STRAW}/${parameters.id}`)
      .then(handleResponse);
  }

  async getAllStrawUniformities(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_UNIFORMITIES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawUniformities(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_UNIFORMITIES}/${id}`)
        .then(handleResponse);
  }

  async addStrawUniformities(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_UNIFORMITIES, parameters)
        .then(handleResponse);
  }

  async editStrawUniformities(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_UNIFORMITIES}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawUniformities(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_UNIFORMITIES}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawBallWeights(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_BALL_WEIGHTS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawBallWeight(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_BALL_WEIGHTS}/${id}`)
        .then(handleResponse);
  }

  async addStrawBallWeight(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_BALL_WEIGHTS, parameters)
        .then(handleResponse);
  }

  async editStrawBallWeight(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_BALL_WEIGHTS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawBallWeight(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_BALL_WEIGHTS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawColorManufacturings(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_COLOR_MANUFACTURINGS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawColorManufacturing(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_COLOR_MANUFACTURINGS}/${id}`)
        .then(handleResponse);
  }

  async addStrawColorManufacturings(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_COLOR_MANUFACTURINGS, parameters)
        .then(handleResponse);
  }

  async editStrawColorManufacturings(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_COLOR_MANUFACTURINGS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawColorManufacturings(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_COLOR_MANUFACTURINGS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawPollutions(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_POLLUTIONS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawPollutions(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_POLLUTIONS}/${id}`)
        .then(handleResponse);
  }

  async addStrawPollutions(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_POLLUTIONS, parameters)
        .then(handleResponse);
  }

  async editStrawPollutions(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_POLLUTIONS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawPollutions(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_POLLUTIONS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawPresentations(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_PRESENTATIONS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawPresentations(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_PRESENTATIONS}/${id}`)
        .then(handleResponse);
  }

  async addStrawPresentations(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_PRESENTATIONS, parameters)
        .then(handleResponse);
  }

  async editStrawPresentations(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_PRESENTATIONS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawPresentations(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_PRESENTATIONS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawLoadingTimes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_LOADING_TIMES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawLoadingTimes(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_LOADING_TIMES}/${id}`)
        .then(handleResponse);
  }

  async addStrawLoadingTimes(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_LOADING_TIMES, parameters)
        .then(handleResponse);
  }

  async editStrawLoadingTimes(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_LOADING_TIMES}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawLoadingTimes(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_LOADING_TIMES}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawHumidities(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_HUMIDITIES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawHumidities(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_HUMIDITIES}/${id}`)
        .then(handleResponse);
  }

  async addStrawHumidities(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_HUMIDITIES, parameters)
        .then(handleResponse);
  }

  async editStrawHumidities(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_HUMIDITIES}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawHumidities(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_HUMIDITIES}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawPaymentConditions(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_PAYMENT_CONDITIONS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawPaymentConditions(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_PAYMENT_CONDITIONS}/${id}`)
        .then(handleResponse);
  }

  async addStrawPaymentConditions(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_PAYMENT_CONDITIONS, parameters)
        .then(handleResponse);
  }

  async editStrawPaymentConditions(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_PAYMENT_CONDITIONS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawPaymentConditions(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_PAYMENT_CONDITIONS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawColorDrivers(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_COLOR_DRIVERS + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawColorDrivers(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_COLOR_DRIVERS}/${id}`)
        .then(handleResponse);
  }

  async addStrawColorDrivers(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_COLOR_DRIVERS, parameters)
        .then(handleResponse);
  }

  async editStrawColorDrivers(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_COLOR_DRIVERS}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawColorDrivers(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_COLOR_DRIVERS}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawPebbles(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_PEBBLES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getStrawPebbles(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_PEBBLES}/${id}`)
        .then(handleResponse);
  }

  async addStrawPebbles(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_PEBBLES, parameters)
        .then(handleResponse);
  }

  async editStrawPebbles(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_PEBBLES}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawPebbles(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_PEBBLES}/${parameters.id}`)
        .then(handleResponse);
  }

  async getAllStrawSocialShares(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.STRAW_SOCIAL_SHARES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  };

  async getStrawSocialShares(id) {
    return axios
        .get(`${ENDPOINTS.STRAW_SOCIAL_SHARES}/${id}`)
        .then(handleResponse);
  }

  async addStrawSocialShares(parameters) {
    return axios
        .post(ENDPOINTS.STRAW_SOCIAL_SHARES, parameters)
        .then(handleResponse);
  }

  async editStrawSocialShares(parameters) {
    return axios
        .put(`${ENDPOINTS.STRAW_SOCIAL_SHARES}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeStrawSocialShares(parameters) {
    return axios
        .delete(`${ENDPOINTS.STRAW_SOCIAL_SHARES}/${parameters.id}`)
        .then(handleResponse);
  }

  async getDeclarationStrawHarvest(parameters) {
    return axios
        .get(`${ENDPOINTS.DECLARATION_STRAW_HARVEST}/${parameters.id}`)
        .then(handleResponse);
  }

  async addDeclarationStrawHarvest(parameters) {
    return axios
        .post(ENDPOINTS.DECLARATION_STRAW_HARVEST, parameters)
        .then(handleResponse);
  }

  async editDeclarationStrawHarvest(parameters) {
    return axios
        .put(`${ENDPOINTS.DECLARATION_STRAW_HARVEST}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeDeclarationStrawHarvest(parameters) {
    return axios
        .delete(`${ENDPOINTS.DECLARATION_STRAW_HARVEST}/${parameters.id}`)
        .then(handleResponse);
  }

  async addDeclarationStrawStock(parameters) {
    return axios
        .post(ENDPOINTS.DECLARATION_STRAW_STOCK, parameters)
        .then(handleResponse);
  }

  async editDeclarationStrawStock(parameters) {
    return axios
        .put(`${ENDPOINTS.DECLARATION_STRAW_STOCK}/${parameters.id}`, parameters)
        .then(handleResponse);
  }

  async removeDeclarationStrawStock(parameters) {
    return axios
        .delete(`${ENDPOINTS.DECLARATION_STRAW_STOCK}/${parameters.id}`)
        .then(handleResponse);
  }
}

export const strawService = new StrawService();
