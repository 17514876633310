import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ArticleYearService{
    async getAllArticlesYear(parameters = {}) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.ARTICLE_YEARS + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getArticleYear(id) {
        return axios
            .get(`${ENDPOINTS.ARTICLE_YEARS}/${id}`)
            .then(handleResponse);
    }

    async addArticleYear(parameters) {
        return axios
            .post(ENDPOINTS.ARTICLE_YEARS, parameters)
            .then(handleResponse);
    }

    async editArticleYear(parameters) {
        return axios
            .put(`${ENDPOINTS.ARTICLE_YEARS}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeArticleYear(parameters) {
        return axios
            .delete(`${ENDPOINTS.ARTICLE_YEARS}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const articleYearService = new ArticleYearService();
