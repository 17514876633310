import {chenevisInvoiceService} from "../../../services/chenevisInvoice.service";
import snackBar from "../../../components/SnackBar";
import {getErrors, getViolations} from "../../../utils/axios";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Button as MuiButton,
    Box
} from "@mui/material";
import {Form, Formik} from "formik";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import SubmitButton from "../../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {useDialog} from "../../../feature/DialogProvider";
import Switch from "../../../components/Formik/Switch";
import {useState} from "react";
import {INVOICE_TYPE} from "../../../constants/types";
import {run} from "../../../utils/services";

const GenerationDialog = ({catalog, setFilters}) => {
    const INITIAL_FORM_STATE = {
        invoice_type: null,
        article_id: null,
        from_member_id: null,
        to_member_id: null,
        year: null,
        invoice_date: null,
        expiry_date: null,
        generateMail: false,
        invoice: null
    }

    const FORM_VALIDATION = Yup.object().shape({});

    const [, closeDialog] = useDialog();

    const [data, setData] = useState(INITIAL_FORM_STATE);

    const INVOICE_TYPE_FILTER = [
        '1er acompte',
        '2ème acompte',
        'Solde',
        // 'Avoir',
    ];

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={data}
            enableReinitialize
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                if (values.invoice_type === INVOICE_TYPE.CREDIT) {
                    run(chenevisInvoiceService.generateInvoiceCreditPdf, values, {setFieldError, setSubmitting})
                        .then(() => {
                            snackBar('Les avoirs ont été générés.');
                            setFilters(prevState => ({...prevState}));
                            closeDialog();
                        });
                    return;
                }
                // chenevisInvoiceService.generateInvoiceCreditPdf(values)
                run(chenevisInvoiceService.generateInvoicePdf, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar('Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.');
                        setFilters(prevState => ({...prevState}));
                        closeDialog();
                    });
            }}
        >
            {({values}) => (
                <Form>
                    <DialogTitle className="headerModal">
                        <div><h3>Génération</h3></div>
                    </DialogTitle>
                    <DialogContent className={"contentModal"}>
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} className="Grid-grid">
                                    <Select name={'invoice_type'}
                                            label={'Type de facture'}
                                    >
                                        {catalog.invoiceTypes
                                            .filter(el => INVOICE_TYPE_FILTER.includes(el))
                                            .map((el, i) => {
                                                return <MenuItem key={i} value={el}>{el}</MenuItem>
                                            })}
                                    </Select>
                                </Grid>
                                {values.invoice_type !== INVOICE_TYPE.CREDIT &&
                                    <>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            <Select name={'article_id'} label={'Article'}>
                                                {catalog.articles.map((el, i) => {
                                                    return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AutocompleteAsync
                                                name={'from_member_id'}
                                                label={'De l\'adhérent...'}
                                                filterName={'company'}
                                                request={memberService.getAllMembers}
                                                getOptionLabel={(option) => `${option.company} ${option.code}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                filtersOnLoading={{'order[company]': 'ASC'}}
                                                hydra
                                                className="Autocomplete"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AutocompleteAsync
                                                name={'to_member_id'}
                                                label={'A l\'adhérent...'}
                                                filterName={'company'}
                                                request={memberService.getAllMembers}
                                                getOptionLabel={(option) => `${option.company} ${option.code}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                                hydra
                                                className="Autocomplete"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            <SelectYear
                                                name={'year'}
                                                label={'Année'}
                                                reverse
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'invoice_date'} label={'Date de facture'} nullIfEmpty/>
                                        </Grid>
                                    </>}
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'expiry_date'} label={'Date d\'échéance'} nullIfEmpty/>
                                </Grid>
                                {values.invoice_type === INVOICE_TYPE.CREDIT &&
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'invoice'}
                                            label={'Facture...'}
                                            filterName={'number'}
                                            request={chenevisInvoiceService.getAllChevenisInvoices}
                                            getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            hydra
                                            className="Autocomplete"
                                        />
                                    </Grid>}
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default GenerationDialog;