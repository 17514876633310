import {driverService} from "../../../../services/driver.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const Drivers = () => {
    const columns = [
        {field: 'code', headerName: 'Code', flex: 0.8},
        {field: 'name', headerName: 'Nom', flex: 1},
        {field: 'company', headerName: 'Société', flex: 1},
    ];
    return <ReferentialTable
        columns={columns}
        callback={driverService.getAllDrivers}
        addLabel={'Ajouter un chauffeur'}
        enableFilters={false}
        getRowClassName={(params) => params.row.active ? '' : 'inactive-row'}
    />
}

export default Drivers