import {ENDPOINTS} from "../constants/endpoints";
import axios from "../utils/axios";
import {handleResponse} from "../utils/services";

class ContactService {
    async getAllContacts(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.CONTACT + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getContact(id) {
        return axios
            .get(`${ENDPOINTS.CONTACT}/${id}`)
            .then(handleResponse);
    }

    async addContact(parameters) {
        return axios
            .post(ENDPOINTS.CONTACT, parameters)
            .then(handleResponse);
    }

    async editContact(parameters) {
        return axios
            .put(`${ENDPOINTS.CONTACT}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeContact(parameters) {
        return axios
            .delete(`${ENDPOINTS.CONTACT}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const contactService = new ContactService();