import {chenevisKilometricService} from "../../../../../services/chenevisKilometric.service";
import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {ENTITY} from "../../../../../constants/entity";

const Kilometric = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 0.8},
        {field: 'startScale', headerName: 'De', flex: 1},
        {field: 'endScale', headerName: 'À', flex: 1},
        {field: 'amount', headerName: 'Indemnité (€/T)', flex: 1},
    ];

    return <ReferentialTable
        columns={columns}
        callback={chenevisKilometricService.getAllChevenisKilometrics}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.CHENEVIS_KILOMETRIC.namespace)}
    />
}

export default Kilometric;