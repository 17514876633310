import {useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import NumberField from "../../../components/Formik/NumberField";
import {Grid} from "@mui/material";
import {Form, Formik} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";
import snackBar from "../../../components/SnackBar";
import {useNavigate} from "react-router-dom";
import {cloneReferentielService} from "../../../services/cloneReferentiel.service";
import {getViolations} from "../../../utils/axios";
import {run} from "../../../utils/services";

const ModalClone = ({cloneEntity, setFilters}) => {
    const navigate = useNavigate();

    const INITIAL_FORM_STATE = {
        from_year: new Date().getFullYear() - 1,
        to_year: new Date().getFullYear(),
        entity: cloneEntity
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "700px",
        maxWidth: "70vw",
        maxHeight: "85vh",
        minHeight: '20vh',
        backgroundColor: '#FFF',
        boxShadow: 24,
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleOpen} sx={{marginRight: "10px"}}>Dupliquer</Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="child-modal-title"
                   aria-describedby="child-modal-description"
            >
                <Box sx={style}>
                    <Box className="headerModal">
                        <Box className="titleModal">Duplication d'un barème</Box>
                    </Box>
                    <Formik
                        initialValues={INITIAL_FORM_STATE}
                        onSubmit={(values, {setFieldError, setSubmitting}) => {
                            run(cloneReferentielService.cloneReferentiel, values, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar(response.responseData.message);
                                    handleClose();
                                    if (setFilters) {
                                        setFilters(prevState => ({...prevState}));
                                    }
                                });
                        }}
                    >
                        <Form>
                            <Box className="contentModal">
                                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                      className="Grid-container">
                                    <Grid xs={12} md={12} item>
                                        <NumberField label={'Année origine'} name={'from_year'}/>
                                    </Grid>
                                    <Grid xs={12} md={12} item>
                                        <NumberField label={'Année destination'} name={'to_year'}/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="footerModal">
                                <SubmitButton className="buttonDelete">Dupliquer</SubmitButton>
                                <Button sx={{marginTop: 0, marginLeft: "10px"}} onClick={handleClose}>Annuler</Button>
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalClone