import { enqueueSnackbar } from 'notistack';

const snackBarWrapper = (message, variant = 'success', otherProps = {}) => {
    const configSnackBar = {
        variant,
        anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
        ...otherProps
    }
    enqueueSnackbar(message, configSnackBar);
}

export default snackBarWrapper;