import {useMemo} from 'react';
import {ROLES} from '../constants/roles';
import {useUserContext} from '../context/UserContext';

const ProtectedComponent = ({acceptedRoles = [], excludedRoles = [], children}) => {
    const {currentUser: {roles: currentRoles}} = useUserContext();
    const isAccesptedRoles = useMemo(() => {
        return (currentRoles || []).some(i => (acceptedRoles || []).includes(i));
    }, [acceptedRoles, currentRoles]);

    const isExcludedRoles = useMemo(() => {
        if (!excludedRoles.length) return true;
        if ((currentRoles || []).includes(ROLES.ADMIN)) return false;
        return (currentRoles || []).some(i => (excludedRoles || []).includes(i));
    }, [currentRoles, excludedRoles]);

    if ((!acceptedRoles.length && !excludedRoles.length) || !isExcludedRoles || isAccesptedRoles) return children;
}

export default ProtectedComponent;