import ReferentialTable from "../../../../components/Parameters/ReferentialTable";
import {invoicingCompaniesService} from "../../../../services/InvoicingCompanies.service";

const InvoicingCompanies = () => {
    const columns = [
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'name', headerName: 'Nom', flex: 1},
    ];
    return <ReferentialTable
        columns={columns}
        callback={invoicingCompaniesService.getAllInvoicingCompanies}
        addLabel={'Ajouter une société'}
        enableFilters={false}
    />
}

export default InvoicingCompanies