import {TYPE} from "../../../../constants/types";
import {Form, Formik, useFormikContext} from "formik";
import {Button as MuiButton, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Stack} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useState} from "react";
import Table from "../../../../components/Mui/DataGrid";
import {useParams} from "react-router-dom";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import * as Yup from "yup";
import Select from "../../../../components/Formik/Select";
import {hydraService} from "../../../../utils/hydra";
import {articleYearService} from "../../../../services/articleYear.service";
import NumberField from "../../../../components/Formik/NumberField";
import * as yup from "yup";
import {run} from "../../../../utils/services";
import snackBar from "../../../../components/SnackBar";
import Alert from "../../../../components/Alert";

const TableTypeArticle = ({catalog, rows, setRows}) => {
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [formState, setFormState] = useState(null);
    const [rowSelected, setRowSelected] = useState();

    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {
            field: 'vat', headerName: 'Code TVA', flex: 1,
            valueGetter: (params) => {
                const vatCodes = catalog.vatCodes
                const vatCode = vatCodes.find(v => v['@id'] === params.row.vat);
                return vatCode.label
            }
        },
        {field: 'sagePurchaseAccount', headerName: 'Compte achat SAGE', flex: 1},
        {field: 'section', headerName: 'Section', flex: 1},
        {field: 'accountingJournal', headerName: 'Journal comptable', flex: 1},
        {field: 'amount', headerName: 'Montant', flex: 1},
        {field: 'rootInvoice', headerName: 'Racine facture', flex: 1},
        {
            field: "actions",
            cellClassName: "actions",
            type: "actions",
            headerName: "Actions",
            getActions: ({row}) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={() => handleClickOpen(row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const FORM_VALIDATION_SEED = Yup.object().shape({
        year: yup.number().required('L\'année est obligatoire'),
        vat: yup.string().required("Le code TVA est obligatoire"),
        sagePurchaseAccount: yup.string().required('Le code Sage est obligatoire'),
        section: yup.string().required('La section est obligatoire'),
        accountingJournal: yup.string().required('Le journal comptable est obligatoire'),
        amount: yup.string().required('Le montant est obligatoire'),
    });

    const deleteRow = () => {
        run(articleYearService.removeArticleYear, {id: hydraService.getIdFromIri(rowSelected)}).then(() => {
            snackBar('La ligne a bien été supprimé')
            setRows(prev => prev.filter(i => i.id !== rowSelected["@id"]));
            setOpenAlert(false);
        });
    };

    const handleClickOpen = (row) => {
        setRowSelected(row);
        setOpenAlert(true);
    };

    const handleClose = () => {
        setOpenAlert(false);
    };

    const updateRow = (values, resetForm) => {
        run(articleYearService.editArticleYear, {
            ...values,
            id: hydraService.getIdFromIri(values['@id']),
            article: values.article['@id']
        }).then((response) => {
            const id = hydraService.getIdFromIri(response.responseData);
            setRows(prev => [...prev.filter(i => i.id !== values.id), {...values, id}]);
            resetForm();
            handleCloseDialog();
        });
    };

    const createRow = (values, resetForm) => {
        run(articleYearService.addArticleYear, values).then((response) => {
            const id = hydraService.getIriFromItem(response.responseData);
            setRows([...rows, {...response.responseData, id}]);
            resetForm();
            handleCloseDialog();
        });
    };

    const handleRowClick = (params) => {
        handleOpenDialog(params.row)
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setFormState(null);
    };

    const handleOpenDialog = (data) => {
        setOpen(true);
        setFormState(data);
    };

    return (
        <>
            <Stack sx={{width: "100%"}} flexDirection="row" justifyContent="flex-end">
                <MuiButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        const form = {
                            article: `/articles/${id}`,
                            year: "",
                            vat: "",
                            sagePurchaseAccount: "",
                            section: "",
                            accountingJournal: "",
                            amount: "",
                            rootInvoice: "",
                        };
                        handleOpenDialog(form);
                    }}>Ajouter</MuiButton>
            </Stack>
            <Table rows={rows} columns={columns} hideFooter={true} onRowClick={handleRowClick}/>
            <Dialog open={Boolean(open && formState)} onClose={handleCloseDialog}>
                <DialogTitle className={"headerModal"}>
                    <div><h3 className={"titleModal"}>Condition de paiement</h3></div>
                </DialogTitle>
                <DialogContent className={"contentModalArticle"}>
                    {formState && (
                        <Formik
                            initialValues={{...formState}}
                            validationSchema={FORM_VALIDATION_SEED}
                            onSubmit={(values, {resetForm}) => {
                                if (values['id'] || values['@id']) {
                                    updateRow(values, resetForm);
                                } else {
                                    createRow(values, resetForm);
                                }
                            }}
                        >
                            <Form>
                                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12} md={6}>
                                        <NumberField name={'year'} label={'Année'}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Select name={'vat'} label={'Code TVA'}>
                                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                                            {catalog.vatCodes.map((el, i) => <MenuItem key={i}
                                                                                       value={el['@id']}>{el.label}</MenuItem>)}
                                        </Select>
                                    </Grid>

                                    <Grid xs={12} md={6} item>
                                        <TextField label={"Compte d'achat Sage"} name={'sagePurchaseAccount'}/>
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                        <TextField label={"Section"} name={'section'}/>
                                    </Grid>

                                    <Grid xs={12} md={12} item>
                                        <TextField label={'Journal comptable'} name={'accountingJournal'}/>
                                    </Grid>

                                    <Grid xs={12} md={12} item>
                                        <TextField label={'Montant'} name={'amount'}/>
                                    </Grid>


                                    <Grid xs={12} md={12} item>
                                        <TextField label={'Racine facture'} name={'rootInvoice'}/>
                                    </Grid>
                                </Grid>
                                <Grid className={"footerModalArticle"} item xs={12} md={12} rowSpacing={2}
                                      display='flex' flexDirection='row' justifyContent='flex-end'>
                                    <Button>{formState["id"] ? 'Modifier' : 'Ajouter'}</Button>
                                    <MuiButton
                                        onClick={() => {
                                            handleCloseDialog();
                                        }}
                                    >
                                        Annuler
                                    </MuiButton>
                                </Grid>
                            </Form>
                        </Formik>
                    )}
                </DialogContent>
            </Dialog>

            <Alert handleClose={handleClose} open={openAlert} handleRemove={deleteRow}/>
        </>
    );
}

export default TableTypeArticle