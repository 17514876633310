import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";
import _ from "lodash";

const ColorDriver = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'article.label', headerName: 'Article', flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {field: 'label', headerName: 'Nom', flex: 1},
        {field: 'orderBy', headerName: "Ordre d'affichage", flex: 1},
    ];


    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawColorDrivers}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_COLOR_DRIVERS.namespace)}
    />
}

export default ColorDriver;