import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {siloService} from "../../../../services/silo.service";
import {invoicingCompaniesService} from "../../../../services/InvoicingCompanies.service";
import {PARAMETERS} from "../../../../constants/parameters";
import * as Yup from "yup";
import Loader from "../../../Loader";
import {Form, Formik} from "formik";
import snackBar from "../../../../components/SnackBar";
import {getViolations} from "../../../../utils/axios";
import {hydraService} from "../../../../utils/hydra";
import {Grid, MenuItem, Stack} from "@mui/material";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import TextField from "../../../../components/Formik/TextField";
import Select from "../../../../components/Formik/Select";
import ModalDelete from "../ModalDelete";
import Box from "@mui/material/Box";
import {run} from "../../../../utils/services";
import BackButton from "../../../../components/BackButton";

const InvoicingCompaniesPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [loading, setLoading] = useState(!!id)
    const [data, setData] = useState({
        code: "",
        name: "",
        address: "",
        additionalAddress: "",
        postcode: "",
        city: "",
        phone: "",
        fax: "",
        email: "",
        siretNumber: "",
        apeNumber: "",
        vatNumber: "",
    });

    useEffect(() => {
        if (!isCreation) {
            run(invoicingCompaniesService.getInvoicingCompanies, id)
                .then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                })
        }
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(invoicingCompaniesService.removeInvoicingCompanies, {id});
        }
    }

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(invoicingCompaniesService.addInvoicingCompanies, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(invoicingCompaniesService.editInvoicingCompanies, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={4} item>
                                <TextField label={'Code'} name={'code'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Nom de la société'} name={'name'}/>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <TextField label={'Adresse'} name={'address'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Adresse additionnelle'} name={'additionalAddress'}/>
                            </Grid>

                            <Grid xs={12} md={4} item>
                                <TextField label={'Code Postal'} name={'postcode'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Ville'} name={'city'}/>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <TextField label={'Téléphone'} name={'phone'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Fax'} name={'fax'}/>
                            </Grid>

                            <Grid xs={12} md={12} item>
                                <TextField label={'Email'} name={'email'}/>
                            </Grid>

                            <Grid xs={12} md={4} item>
                                <TextField label={'Numéro de Siret'} name={'siretNumber'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Numéro APE'} name={'apeNumber'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Numéro TVA'} name={'vatNumber'}/>
                            </Grid>
                        </Grid>

                        {!isCreation && <ModalDelete title="de la société" handleDelete={handleDelete}/>}
                    </Form>
                </Formik>
                <Box>

                </Box>
            </>
    )
}

export default InvoicingCompaniesPage