import {useDialog} from "../../../../feature/DialogProvider";
import {Form, Formik} from "formik";
import {importService} from "../../../../services/import.service";
import snackBar from "../../../../components/SnackBar";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import MuiTableAsync from "../../../../components/Mui/DataGridAsync";
import _ from "lodash";
import Switch from "../../../../components/Formik/Switch";
import SubmitButton from "../../../../components/Formik/SubmitButton";
import React, {useMemo, useState} from "react";
import {ticketService} from "../../../../services/ticket.service";
import {toLocaleDate} from "../../../../utils/i18n";
import Download from "../../../../components/Download";
import {fileService} from "../../../../services/file.service";
import {run} from "../../../../utils/services";

const DataGridTablet = ({setFilters}) => {

    const INITIAL_FORM_STATE = {
        generateMail: false
    }

    const [, closeDialog] = useDialog();
    const [currentFilters, setCurrentFilters] = useState({
        'exists[transfertDate]': false,
        'exists[strawTicket]': false,
        'status': true
    });

    const columns = useMemo(() => (
            [
                {
                    field: 'number',
                    headerName: 'N°',
                    flex: 1,
                    align: 'right',
                },
                {
                    field: 'loadingDate',
                    headerName: 'Date de chargement',
                    flex: 1,
                    align: 'center',
                    valueFormatter: (params) => toLocaleDate(params.value)
                },
                {
                    field: 'member.company',
                    headerName: 'Adhérent',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'driver.name',
                    headerName: 'Chauffeur',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'colorDriver.label',
                    headerName: 'Couleur chauffeur',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'ballNumber',
                    headerName: 'Nombre de balles',
                    flex: 1,
                    align: 'right',
                },
                {
                    field: 'signature',
                    headerName: 'Signature ?',
                    flex: 1,
                    align: 'center',
                    valueFormatter: (params) => params.value ? 'Oui' : 'Non'
                },
                {
                    field: 'contractFromMember',
                    headerName: 'Contrat ?',
                    flex: 1,
                    align: 'center',
                    valueFormatter: (params) => params.value ? 'Oui' : 'Non'
                }
            ])
        , []);

    return (
        <>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                onSubmit={(values, {setSubmitting}) => {
                    run(importService.importTablet, values, {setSubmitting})
                        .then((response) => {
                            snackBar('Import des tickets tablette effectué');
                            const {file} = response.responseData;
                            setCurrentFilters((prevState) => ({...prevState, file}));
                        });
                }}
            >
                <Form>
                    <DialogTitle className={'headerModal'}>
                        Import Tablette
                    </DialogTitle>
                    <DialogContent className={'contentModal'}>
                        <MuiTableAsync
                            columns={columns}
                            request={ticketService.getAllTabletTicket}
                            filters={currentFilters}
                            customPagination
                            customDeleteRequest={ticketService.editTabletTicket}
                            customDeleteCallback={() => setCurrentFilters(prevState => ({...prevState}))}
                            getRowId={(row) => {
                                return _.get(row, '@id');
                            }}
                        />
                    </DialogContent>
                    <DialogActions className={'footerModal'}>
                        {!currentFilters?.file ? <>
                            <SubmitButton>Importer</SubmitButton>
                            <Button onClick={closeDialog}>Annuler</Button>
                        </> : <>
                            <Button variant={'contained'} color={'error'} onClick={() => {
                                Download(currentFilters.file, true);
                                closeDialog();
                            }}>Télécharger le récapitulatif d'import</Button>
                            <Button onClick={() => {
                                fileService.remove({url: currentFilters.file});
                                closeDialog();
                            }}>Annuler</Button>
                        </>}
                    </DialogActions>
                </Form>
            </Formik>
        </>
    );
}

export default DataGridTablet;