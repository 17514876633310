import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useField} from "formik";

const RadioWrapper = ({name, label, options, disabled = false, ...otherProps}) => {

    const [field, meta] = useField(name);

    const configRadio = {
        ...field,
        ...otherProps,
    }

    const configFormControl = {
        disabled: disabled
    }
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
        configFormControl.helpertext = meta.error;
    }

    return (
        <FormControl {...configFormControl}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                {...configRadio}
            >
                {options
                    .map((el, i) => {
                    return <FormControlLabel key={i} value={el.value} control={<Radio {...(el.radioProps)}/>}
                                             label={el.label}/>
                })}
            </RadioGroup>
            {configFormControl.helpertext && <FormHelperText>{configFormControl.helpertext}</FormHelperText>}
        </FormControl>
    );
}

export default RadioWrapper;