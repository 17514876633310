import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class SupplyInvoiceService {
  async getAllSupplyInvoices(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.SUPPLY_INVOICE + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getSupplyInvoice(id) {
    return axios
      .get(`${ENDPOINTS.SUPPLY_INVOICE}/${id}`)
      .then(handleResponse);
  }

  async addSupplyInvoice(parameters) {
    return axios
      .post(ENDPOINTS.SUPPLY_INVOICE, parameters)
      .then(handleResponse);
  }

  async editSupplyInvoice(parameters) {
    return axios
      .put(`${ENDPOINTS.SUPPLY_INVOICE}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeSupplyInvoice(parameters) {
    return axios
      .delete(`${ENDPOINTS.SUPPLY_INVOICE}/${parameters.id}`)
      .then(handleResponse);
  }

  async removeInvoiceArticle(id) {
    return axios
        .delete(`${ENDPOINTS.SUPPLY_INVOICE_ARTICLES}/${id}`)
        .then(handleResponse);
  }

  async generateInvoiceCredit(parameters) {
    return axios
        .post(`${ENDPOINTS.SUPPLY_INVOICE}/${parameters.id}/credit`, parameters)
        .then(handleResponse);
  }

  async generateInvoicePdf(parameters) {
    return axios
        .post(`${ENDPOINTS.INVOICE}/supply/generate/payment`, parameters)
        .then(handleResponse);
  }

}

export const supplyInvoiceService = new SupplyInvoiceService();
