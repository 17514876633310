import Table from "../../../components/Mui/DataGrid";
import {useMemo, useState} from "react";
import _ from "lodash";
import {convertDate} from "../../../utils/moment";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {ROLES} from "../../../constants/roles";
import {Grid} from "@mui/material";
import EditDialog from "./EditRow";
import Confirmation from "../../../feature/Confirmation";
import {useUserContext} from "../../../context/UserContext";
import {run} from "../../../utils/services";
import {supplyInvoiceService} from "../../../services/supplyInvoice.service";

const ArticleTable = ({catalog, isManual = false, push, replace, insert, remove, form}) => {
    const {values} = form;
    const {isGranted} = useUserContext();

    const [editData, setEditData] = useState(undefined);

    const rows = values.articles;

    const handleEditData = (row) => {
        setEditData(row);
    }

    const handleRemove = (id) => {
        return run(supplyInvoiceService.removeInvoiceArticle, id);
    }

    const articleInfo = (iri) => catalog.articles.find(el => el['@id'] === iri);

    let columns = useMemo(() => {
        const start = [
            {
                field: 'ticket.ticketDate',
                headerName: 'Date BL',
                flex: 1,
                valueGetter: (params) => convertDate(_.get(params.row, params.field))
            },
            {
                field: 'ticket.blNumber',
                headerName: 'N° bon',
                flex: 1,
                valueGetter: (params) => _.get(params.row, params.field)
            }
        ];
        const end = [
            {
                field: 'article',
                headerName: 'Code article',
                flex: 1,
                valueGetter: (params) => articleInfo(params.value)?.code
            },
            {
                field: 'designation',
                headerName: 'Désignation',
                flex: 2,
            },
            {
                field: 'quantity',
                headerName: 'Quantité',
                flex: 1,
            },
            {
                field: 'unitPrice',
                headerName: 'Prix unitaire',
                flex: 1,
                align: 'right',
                valueFormatter: (params) => _.numberFormat(params.value)
            },
            {
                field: 'number',
                headerName: 'Nombre',
                flex: 1,
            },
            {
                field: 'vatRate',
                headerName: 'TVA',
                flex: 1
            }
        ];
        return (isManual ? [] : start).concat(end);
    }, [isManual, articleInfo]);

    if (!isGranted(ROLES.MEMBER) && !values.isBlocked) {
        columns = columns.concat([
            {
                field: 'action',
                type: 'actions',
                headerName: 'Action',
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                    const {id, row} = params;
                    return <Confirmation
                        buttonLabel={''}
                        modalTitle={'Suppression d\'un article'}
                        modalContent={'Confirmez-vous la suppression de cet article ?'}
                        handleConfirm={() => {
                            if (row['action'] || isGranted(ROLES.MEMBER)) {
                                return [];
                            }
                            const index = rows.findIndex(el => el.id === id);
                            if (isNaN(id)) {
                                remove(index);
                            } else {
                                handleRemove(id).then(() => {
                                    remove(index);
                                });
                            }
                        }}/>
                }
            },
        ])
    }

    return (
        <Grid container spacing={2} className="Grid-container">
            {!values.isBlocked && <ProtectedComponent acceptedRoles={ROLES.ADMIN}>
                <Grid item xs={12} container justifyContent="flex-end">
                    <EditDialog push={push}
                                insert={insert}
                                replace={replace}
                                catalog={catalog}
                                dataUpdate={editData}
                    />
                </Grid>
            </ProtectedComponent>}
            <Grid item xs={12}>
                <Table
                    rows={rows}
                    onRowClick={(params) => handleEditData(params.row)}
                    columns={columns}
                    hideFooter/>
            </Grid>
        </Grid>
    )
        ;
}

export default ArticleTable;