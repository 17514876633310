import {routes} from '../../utils/routes';
import {Link, Outlet, useLocation} from "react-router-dom";
import RedirectButton from "../../components/Mui/RedirectButton";
import {Box, Grid, Paper, styled} from "@mui/material";

const primaryColor = "#065826";

const ItemButton = styled(RedirectButton)(({theme}) => ({
    fontSize: 58,
    width: '300px',
}));

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '60px',
}));

const gridStyles = {
    backgroundColor: "transparent!important",
    padding: '0!important',
};

const boxStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: "20px",
};

const itemStyle = {
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px 0 hsl(0deg 0% 47% / 10%)",
    padding: "40px 20px 55px",
    color: primaryColor,
    fontSize: "13px"
};

const iconItem = {
    width: "125px",
    height: "125px",
    borderRadius: "50%",
    backgroundColor: primaryColor,
    border: "2px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 'auto',
    color: "#FFF",
    marginBottom: "15px"
}

const linkStyle = {
    textDecoration: "none",
}


const Parameters = () => {
    const location = useLocation();
    const routeParameters = routes[0].children.find(el => el.path === location.pathname);
    return (
        <>
            {routeParameters?.children &&
                <Grid container spacing={3} sx={gridStyles} className="Grid-root Grid-container-parameters">
                    {routeParameters.children
                        .filter(el => !!el.path)
                        .map((el, i) => {
                            return (
                                <Grid key={i} item xs={12} sm={5} md={5} className="Grid-item-parameters">
                                    <Link style={linkStyle} to={el.path}>
                                        <Item key={i} elevation={12} sx={itemStyle}>
                                            <Box sx={iconItem}>{el.icon}</Box>
                                            {el.handle.name}
                                        </Item>
                                    </Link>
                                </Grid>
                            );
                        })}
                </Grid>}
            <Outlet/>
        </>
    );
}

export default Parameters;