import {
    Button as MuiButton, IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {Form, Formik} from "formik";
import {run} from "../../utils/services";
import {memberService} from "../../services/member.service";
import NumberField from "../../components/Formik/NumberField";
import Button from "../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {hydraService} from "../../utils/hydra";
import {TYPE_SILO} from "../../constants/types";
import {useCallback, useContext, useMemo, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "../../utils/lodash/number/number_format";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import {useUserContext} from "../../context/UserContext";

const AreaTable = ({member = {}, siloDistances = [], type = 'ATTACHMENT'}) => {
    const FORM_VALIDATION = Yup.object().shape({});
    const {isGranted} = useUserContext();

    const filterSilos = (type = 'ATTACHMENT') => {
        switch (type) {
            case 'ATTACHMENT':
                return siloDistances.filter(el => [TYPE_SILO.ATTACHMENT].includes(el.memberSiloType) && el.silo && el.silo['@id'] === member.attachedSilo);
            case 'DRYER':
                return siloDistances.filter(el => [TYPE_SILO.DRYER].includes(el.memberSiloType) && el.silo && el.silo['@id'] === member.dryerSilo);
            default:
                return [];
        }
    }
    const [silos, setSilos] = useState(filterSilos(type));
    const [siloDblClickSelected, setSiloDblClickSelected] = useState(null);

    const siloMemberSelected = member?.silosInfos[type === 'ATTACHMENT' ? 'attachment' : 'dryer'];

    const INITIAL_FORM_STATE = {
        'member': hydraService.getIriFromItem(member),
        'silo': hydraService.getIriFromItem(siloMemberSelected.silo),
        'year': new Date().getFullYear(),
        'memberSiloType': type === 'ATTACHMENT' ? TYPE_SILO.ATTACHMENT : TYPE_SILO.DRYER,
        'distance': null
    };

    const reloadSilo = (silo) => {
        let arr = [...silos, silo];
        arr.sort((a, b) => String(b.year).localeCompare(a.year, undefined, {numeric: true}));
        setSilos(arr);
    }

    const distanceDisplayer = useCallback((siloDistance) => {
        if (siloDblClickSelected === null || siloDblClickSelected !== siloDistance['@id']) {
            const distance = _.numberFormat(siloDistance.distance, 0, 0);
            return isGranted(ROLES.ADMIN) ? <Tooltip title="Cliquez pour éditer">
                <a style={{cursor: 'pointer'}}
                   onClick={() => setSiloDblClickSelected(siloDistance['@id'])}>{distance}</a>
            </Tooltip> : <span>{distance}</span>;
        } else {
            const UPDATE_SILO_STATE = {
                'id': Number(hydraService.getIdFromIri(siloDistance['@id'])),
                'member': hydraService.getIriFromItem(member),
                'silo': hydraService.getIriFromItem(siloDistance.silo),
                'year': siloDistance.year,
                'memberSiloType': siloDistance.type,
                'distance': siloDistance.distance
            }
            return (
                <Formik
                    initialValues={UPDATE_SILO_STATE}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting, resetForm}) => {
                        run(memberService.editSiloDistance, values, {setFieldError, setSubmitting})
                            .then(response => {
                                setSiloDblClickSelected(null);
                                const index = silos.findIndex((el) => el.year === siloDistance.year);
                                silos[index] = response.responseData;
                                setSilos(silos);
                            });
                    }}>
                    <Form>
                        <Stack direction={'row'} spacing={.5}>
                            <NumberField label={'Distance'} inputProps={{maxLength: '10'}}
                                         name={'distance'}
                                         decimal={0}
                                         nullIfEmpty
                                         inputRef={input => input && input.select()}
                            />
                            <Button size={'small'}>Modifier</Button>
                            <MuiButton size={'small'} onClick={() => setSiloDblClickSelected(null)}>Annuler</MuiButton>
                        </Stack>
                    </Form>
                </Formik>
            );
        }
    }, [siloDblClickSelected, member, silos]);

    const handleDelete = useCallback((siloDistance) => {
        if (window.confirm('Confirmer la suppression ?')) {
            const id = hydraService.getIdFromIri(siloDistance);
            run(memberService.removeSiloDistance, {id}).then(r => {
                const filteredSilos = silos.filter(el => el['@id'] !== siloDistance['@id']);
                setSilos(filteredSilos);
            });
        }
    }, []);

    return (
        <>
            <p>Distance kilométrique silo {type === 'ATTACHMENT' ? 'de rattachement' : 'sécheur'}<strong>{siloMemberSelected.name}</strong></p>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Année</TableCell>
                            <TableCell align="right">Distance</TableCell>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <TableCell/>
                            </ProtectedComponent>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {silos.map((el, i) => (
                            <TableRow
                                key={i}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {el.year}
                                </TableCell>
                                <TableCell align="right">
                                    {distanceDisplayer(el)}
                                </TableCell>
                                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                    <TableCell align={'center'} style={{padding: 0}}>
                                        <IconButton size={'small'} onClick={() => handleDelete(el)}>
                                            <DeleteIcon color={'error'} style={{width: '1.2em'}}/>
                                        </IconButton>
                                    </TableCell>
                                </ProtectedComponent>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting, resetForm}) => {
                    run(memberService.addSiloDistance, values, {setFieldError, setSubmitting})
                        .then(response => {
                            reloadSilo(response.responseData);
                            resetForm();
                        });
                }}>
                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                    <Form>
                        <div style={{margin: '20px 0'}}>
                            <Stack direction="row" spacing={2}>
                                <NumberField label={'Année'} inputProps={{maxLength: '4'}}
                                             name={'year'} decimal={0} nullIfEmpty/>
                                <NumberField label={'Distance'} inputProps={{maxLength: '10'}}
                                             name={'distance'} decimal={0} nullIfEmpty/>
                                <Button>Ajouter</Button>
                            </Stack>
                        </div>
                    </Form>
                </ProtectedComponent>
            </Formik>
        </>
    );
}

export default AreaTable;