import Table from "../../components/Mui/DataGrid";
import {getIn, useFormikContext} from "formik";
import NumberField from "../../components/Formik/NumberField";

const ColorTable = ({name}) => {
    const {values} = useFormikContext();
    const rows = getIn(values, name).map(el => ({...el, id: el['@id']}));
    const columns = [
        {
            field: 'label',
            headerName: 'Couleur',
            flex: 1
        },
        {
            field: 'amount',
            headerName: 'Montant',
            flex: 1,
            renderCell: (params) => {
                const index = rows.findIndex(el => el.id === params.id);
                return index !== -1 ? <NumberField
                    asString
                    name={`${name}[${index}].amount`}
                /> : params.amount;
            }
        }
    ];

    return (
        <Table
            rows={rows}
            columns={columns}
            getRowId={(row) => row['@id']}
            hideFooter
        />
    );
}

export default ColorTable;