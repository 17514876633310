import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class CatalogService {
  async getAllCatalogs() {
    return axios
      .get(ENDPOINTS.CATALOG)
      .then(handleResponse);
  }

  async getCatalog(id) {
    return axios
      .get(`${ENDPOINTS.CATALOG}/${id}`)
      .then(handleResponse);
  }

  async addCatalog(parameters) {
    return axios
      .post(ENDPOINTS.CATALOG, parameters)
      .then(handleResponse);
  }

  async editCatalog(parameters) {
    return axios
      .put(`${ENDPOINTS.CATALOG}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeCatalog(parameters) {
    return axios
      .delete(`${ENDPOINTS.CATALOG}/${parameters.id}`)
      .then(handleResponse);
  }

  async getSearchFilter() {
    return axios
        .get(ENDPOINTS.SEARCH_FILTER)
        .then(handleResponse);
  }

  async searchFilters() {
    return axios
      .get(ENDPOINTS.SEARCH_FILTER)
      .then(handleResponse);
  }

  async getTitles() {
    return axios
        .get(`${ENDPOINTS.CATALOG}${ENDPOINTS.MEMBER}${ENDPOINTS.TITLE}`)
        .then(handleResponse);
  }

  async getAllCatalogDeliveriesPlaces(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_DELIVERIES_PLACES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllCatalogDeliveriesTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_DELIVERIES_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllCatalogTransportsTypes() {
    return axios
        .get(ENDPOINTS.CATALOG_TRANSPORTS_TYPES)
        .then(handleResponse);
  }

  async getAllCatalogAreasQualities() {
    return axios
        .get(ENDPOINTS.CATALOG_AREAS_QUALITIES)
        .then(handleResponse);
  }

  async getAllCatalogInvoicesTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_INVOICES_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllCatalogExportInvoicesTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_EXPORT_INVOICES_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllFarmingTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_FARMINGS_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllContractTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_CONTRACT_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllQualityType(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_CONTRACT_QUALITY_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async getAllProductionLine(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CATALOG_PRODUCTION_LINES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }
}

export const catalogService = new CatalogService();
