import {TextField} from "@mui/material";

const SelectWrapper = ({name, label, value, children, onChange, ...otherProps}) => {

    const configSelect = {
        select: true,
        fullWidth: true,
        name,
        label,
        value,
        ...(onChange && {onChange}),
        ...otherProps
    };

    return (
        <TextField {...configSelect}>
            {children}
        </TextField>
    );
}

export default SelectWrapper;