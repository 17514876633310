import {strawService} from "../../../../../services/straw.service";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {vatCodeService} from "../../../../../services/vatCode.service";
import {articleService} from "../../../../../services/article.service";
import {hydraService} from "../../../../../utils/hydra";
import {ARTICLE_TYPE_CODES, TYPE} from "../../../../../constants/types";
import * as Yup from "yup";
import Loader from "../../../../Loader";
import {Button as MuiButton, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Stack} from "@mui/material";
import {FieldArray, Form, Formik} from "formik";
import Select from "../../../../../components/Formik/Select";
import NumberField from "../../../../../components/Formik/NumberField";
import TextField from "../../../../../components/Formik/TextField";
import Button from "../../../../../components/Formik/SubmitButton";
import * as yup from "yup";
import ModalClone from "../../ModalClone";
import {ENTITY} from "../../../../../constants/entity";
import MuiSelect from "../../../../../components/Mui/Select";
import Range from "../../../../../utils/range";
import MuiTableAsync from "../../../../../components/Mui/DataGridAsync";
import DateNative from "../../../../../components/Formik/Date/Native";
import {run} from "../../../../../utils/services";
import _ from "lodash";
import Alert from "../../../../../components/Alert";
import snackBar from "../../../../../components/SnackBar";
import Deposit from "./Deposit";


const PaymentCondition = () => {
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [catalog, setCatalog] = useState({
        'vatCodes': [],
        'articles': [],
        'paymentConditions': [],
    });
    const [rows, setRows] = useState([]);
    const [formState, setFormState] = useState(null);
    const [filters, setFilters] = useState([]);
    const [row, setRow] = useState();
    const [openAlert, setOpenAlert] = useState(false);

    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {
            field: 'article.label', headerName: 'Article', flex: 1,
            valueGetter: (params) => {
                return _.get(params.row, params.field);
            }
        },
        {
            field: "actions",
            cellClassName: "actions",
            type: "actions",
            headerName: "Actions",
            getActions: ({row}) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={() => handleClickOpen(row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
        Promise.all([
            run(vatCodeService.getAllVatCodes, {pagination: false}),
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
            run(strawService.getAllStrawPaymentConditions, {pagination: false}),
        ]).then((values) => {
            let [vatCodes, articles, paymentConditions] = values.map(el => el?.responseData);
            vatCodes = hydraService.getMembers(vatCodes);
            articles = hydraService.getMembers(articles);
            paymentConditions = hydraService.getMembers(paymentConditions);
            paymentConditions.forEach(payment => {
                payment.vatAdvance = hydraService.getIriFromItem(payment.vatAdvance);
            })
            setCatalog({vatCodes, articles});
            setRows(paymentConditions.map(el => ({...el, id: el['@id']})));
            setLoading(false);
        })
        return () => {
            setRows([]);
        }
    }, []);

    const deleteRow = () => {
        run(strawService.removeStrawPaymentConditions, {
            id: hydraService.getIdFromIri(row["@id"])
        }).then(() => {
            snackBar('La ligne a bien été supprimé')
            setRows(prev => prev.filter(i => i.id !== row["@id"]));
            setOpenAlert(false);
        })
    };

    const updateRow = (values, resetForm, setFieldError) => {
        run(strawService.editStrawPaymentConditions, {
            ...values,
            id: hydraService.getIdFromIri(values['@id'])
        }, {setFieldError}).then((response) => {
            const id = hydraService.getIdFromIri(response.responseData);
            setRows(prev => [...prev.filter(i => i.id !== values.id), {...values, id}]);
            resetForm();
            handleCloseDialog();
        })
    };

    const createRow = (values, resetForm, setFieldError) => {
        run(strawService.addStrawPaymentConditions, values, {setFieldError}).then((response) => {
            const id = hydraService.getIdFromIri(response.responseData);
            setRows([...rows, {...values, id}]);
            resetForm();
            handleCloseDialog();
        })
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setFormState(null);
    };

    const handleOpenDialog = (data) => {
        setOpen(true);
        setFormState(data);
    };

    const handleRowClick = (params) => {
        const id = hydraService.getIdFromIri(params.id);
        run(strawService.getStrawPaymentConditions, id).then(response => {
            handleOpenDialog(response.responseData)
        })
    };

    const handleClose = () => {
        setOpenAlert(false);
    };

    const handleClickOpen = (row) => {
        setRow(row);
        setOpenAlert(true);
    };

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        loading ? <Loader/> :
            <>
                <Grid container spacing={3} className="Grid Grid-root">
                    <Grid item xs={3} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <MuiSelect name={'year'}
                                       label={'Année '}
                                       value={filters.year ?? ''}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           const newFilters = value ? {
                                               ...filters,
                                               [name]: value
                                           } : delete filters[name];
                                           setFilters(newFilters);
                                       }}
                            >
                                <MenuItem value={''}><em>Aucune</em></MenuItem>
                                {Range(new Date().getFullYear(), 2021, -1).map((el, i) => (
                                    <MenuItem key={i} value={el}>{el}</MenuItem>
                                ))}
                            </MuiSelect>
                        </Stack>
                    </Grid>

                    <Grid container item xs={9} justifyContent={'flex-end'} className="Grid">
                        <Stack sx={{width: "100%"}} flexDirection="row" justifyContent="flex-end">
                            <ModalClone cloneEntity={String(ENTITY.STRAW_PAYMENT_CONDITIONS.namespace)} setFilters={setFilters}/>
                            <MuiButton
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    const form = {
                                        article: null,
                                        year: null,
                                        vatAdvance: null,
                                        sagePurchaseAccount: "",
                                        section: "",
                                        accountingJournal: "",
                                        deposits: [],
                                        rootBalance: "",
                                        startOverstockDate: null,
                                        amountBonusOverstock: "",
                                    };
                                    handleOpenDialog(form);
                                }}>Ajouter</MuiButton>
                        </Stack>
                    </Grid>
                </Grid>

                <MuiTableAsync
                    columns={columns}
                    onRowClick={handleRowClick}
                    request={strawService.getAllStrawPaymentConditions}
                    filters={filters}
                    customPagination
                />

                <Dialog open={Boolean(open && formState)} onClose={handleCloseDialog}>
                    <DialogTitle className={"headerModal"}>
                        <div><h3 className={"titleModal"}>Condition de paiement</h3></div>
                    </DialogTitle>
                    <DialogContent className={"contentModalArticle"}>
                        {formState && (
                            <Formik
                                initialValues={{...formState}}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={(values, {resetForm, setFieldError}) => {
                                    if (values['id'] || values['@id']) {
                                        updateRow(values, resetForm, setFieldError);
                                    } else {
                                        createRow(values, resetForm, setFieldError);
                                        setFilters(prevState => ({...prevState}));
                                    }
                                }}
                            >
                                {({values}) => (
                                    <Form>
                                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                            <Grid xs={12} md={12} item>
                                                <Select name={'article'} label={'Article'}>
                                                    {catalog.articles.map((el, i) => <MenuItem key={i}
                                                                                                    value={el['@id']}>{el.label}</MenuItem>)}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField name={'year'} label={'Année'} nullIfEmpty/>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Select name={'vatAdvance'} label={'Code TVA'} nullIfEmpty>
                                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                                    {catalog.vatCodes.map((el, i) => <MenuItem key={i}
                                                                                               value={el['@id']}>{el.label}</MenuItem>)}
                                                </Select>
                                            </Grid>

                                            <Grid xs={12} md={6} item>
                                                <TextField label={"Compte d'achat Sage"} name={'sagePurchaseAccount'}/>
                                            </Grid>
                                            <Grid xs={12} md={6} item>
                                                <TextField label={"Section"} name={'section'}/>
                                            </Grid>

                                            <Grid xs={12} md={6} item>
                                                <TextField label={'Journal comptable'} name={'accountingJournal'}/>
                                            </Grid>

                                            <Grid xs={12} md={6} item>
                                                <TextField label={'Racine facture solde'} name={'rootBalance'}/>
                                            </Grid>
                                            <Grid xs={12} md={6} item>
                                                <DateNative label={'Date début surstockage'}
                                                            name={'startOverstockDate'} nullIfEmpty/>
                                            </Grid>
                                            <Grid xs={12} md={6} item>
                                                <NumberField label={'Prime surstockage'} name={'amountBonusOverstock'}
                                                             asString/>
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <FieldArray name={'deposits'} component={Deposit}/>
                                            </Grid>
                                        </Grid>
                                        <Grid className={"footerModalArticle"} item xs={12} md={12} rowSpacing={2}
                                              display='flex' flexDirection='row' justifyContent='flex-end'>
                                            <Button>{formState["@id"] ? 'Modifier' : 'Ajouter'}</Button>
                                            <MuiButton
                                                onClick={() => {
                                                    handleCloseDialog();
                                                }}
                                            >
                                                Annuler
                                            </MuiButton>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </DialogContent>
                </Dialog>

                <Alert handleClose={handleClose} open={openAlert} handleRemove={deleteRow} />
            </>
    )

}

export default PaymentCondition;