import React, {useCallback} from 'react';
import _ from "../../utils/lodash/number/number_format";
import {MenuItem} from "@mui/material";
import MuiSelect from "../Mui/Select";
import {useFilterContext} from "../../context/FilterContext";

const ArticleFilter = ({filters, setFilters, options = [], ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';
    const {setFilterHash} = useFilterContext();

    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
            setFilterHash(newFilters);
        },
        [filters, setFilters, setFilterHash]
    )

    return (
        <MuiSelect name={otherProps.name ?? 'article.code'}
                   label={otherProps.label ?? 'Article'}
                   value={filters['article.code'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {options.map((el, i) => <MenuItem key={i} value={el.code}>{el.label}</MenuItem>)}
        </MuiSelect>
    );
};

export default ArticleFilter;
