import React, {useCallback, useState} from 'react';
import _ from "../../utils/lodash/number/number_format";
import MuiAutocompleteAsync from "../Mui/AutocompleteAsync";
import {ticketService} from "../../services/ticket.service";
import {useFilterContext} from "../../context/FilterContext";
import {hydraService} from "../../utils/hydra";

const NumberInvoiceFilter = ({filters = {}, setFilters = () => null, request, ...otherProps}) => {
    const {setFilterHash} = useFilterContext();
    const [value, setValue] = useState(null);
    const handlerCallback = useCallback(
        (e, value) => {
            const newFilters = value ? {...filters, ...(value.number && {'number': value.number})} : _.omit(filters, 'number');
            setFilters(newFilters);
            setFilterHash(newFilters);
        },
        [setFilters, filters],
    );

    if ('number' in filters && (!value || value.number !== filters['number'])) {
        (async () => {
            const valueSelected = await request({'number': filters['number']});
            setValue(hydraService.getMembers(valueSelected.responseData)[0] ?? null);
        })();
    }

    if (value && !('number' in filters)) {
        setValue(null);
    }

    return (
        <MuiAutocompleteAsync
            name={otherProps.name ?? 'code'}
            label={otherProps.label ?? 'Numéro de ticket...'}
            filterName={otherProps.filterName ?? 'number'}
            request={request ?? ticketService.getAllStrawTicket}
            callback={handlerCallback}
            getOptionLabel={(option) => `${option.number}`}
            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
            noOptionsText="Pas d'options"
            value={value}
        />
    );
};

export default NumberInvoiceFilter;
