import {TextField} from "@mui/material";

const DisableTextField = ({value, label, ...otherProps}) => {
    const configDisabledTextField = {
        disabled: true,
        fullWidth: true,
        label,
        value,
        ...otherProps
    }
    return (
        <TextField {...configDisabledTextField}/>
    );
}
export default DisableTextField;