import MuiTableAsync from "../../../../components/Mui/DataGridAsync";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Button, DialogActions, DialogContent, DialogTitle, styled, Tooltip, tooltipClasses
} from "@mui/material";
import {useDialog} from "../../../../feature/DialogProvider";
import {importService} from "../../../../services/import.service";
import _ from "lodash";
import {Form, Formik} from "formik";
import SubmitButton from "../../../../components/Formik/SubmitButton";
import snackBar from "../../../../components/SnackBar";
import {toLocaleDate} from "../../../../utils/i18n";
import Download from "../../../../components/Download";
import {fileService} from "../../../../services/file.service";
import {run} from "../../../../utils/services";


const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    }, [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

// https://github.com/mui/mui-x/issues/1040#issuecomment-1361092416
const RenderExpandableCell = (props) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const {value} = props;

    const textElementRef = useRef(null);

    const checkOverflow = () => {
        // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
        const clientWidth = textElementRef.current?.getBoundingClientRect().width;

        textElementRef.current.style.overflow = "visible";
        const contentWidth = textElementRef.current?.getBoundingClientRect().width;
        textElementRef.current.style.overflow = "hidden";

        setIsOverflow(contentWidth > clientWidth || _.isArray(value));
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener("resize", checkOverflow);
        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, []);

    return (<BootstrapTooltip title={_.isArray(value) ? (<ul>
        {value.map((el, i) => <li key={i} style={{margin: 0, padding: 0}}>{el}</li>)}
    </ul>) : value} disableHoverListener={!isOverflowed}>
      <span
          ref={textElementRef}
          style={{
              whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
          }}
      >
        {_.isArray(value) && value.length > 0 ? `${value.length} ${value.length > 1 ? 'erreurs' : 'erreur'}` : value}
      </span>
    </BootstrapTooltip>);
};

const PlcdDialog = ({setFilters}) => {

    const INITIAL_FORM_STATE = {
        generateMail: false
    }

    const [, closeDialog] = useDialog();

    const [currentFilters, setCurrentFilters] = useState(null);

    const columns = useMemo(() => ([{
        field: 'number', headerName: 'N° Ticket', flex: 1
    }, {
        field: 'colorManufacturing', headerName: 'Couleur', flex: 1
    }, {
        field: 'uniformity', headerName: 'Homogénéité', flex: 1
    }, {
        field: 'pollution', headerName: 'Pollution', flex: 1
    }, {
        field: 'presentation', headerName: 'Présentation', flex: 1
    }, {
        field: 'fabricationDate',
        headerName: 'Date fabrication',
        flex: 1,
        valueFormatter: (params) => toLocaleDate(params.value)
    }, {
        field: 'pebbleWeight',
        headerName: 'Poids cailloux',
        flex: 1,
        valueFormatter: (params) => `${params.value} kg`,
        align: 'right'
    }, {
        field: 'humidityRate',
        headerName: 'Humidité',
        flex: 1,
        valueFormatter: (params) => _.numberFormat(params.value),
        align: 'right'
    }, {
        field: 'averageColorimetry',
        headerName: 'Colorimétrie',
        flex: 1,
        valueFormatter: (params) => _.numberFormat(params.value),
        align: 'right'
    }, {
        field: 'productionLine', headerName: 'Ligne de fabrication', flex: 1
    }, {
        field: 'errors',
        headerName: 'Traitement des erreurs',
        flex: 1,
        sortable: false,
        renderCell: RenderExpandableCell
        // renderCell: (params) => {
        //     const errors = _.get(params.row, params.field);
        //     return (
        //         <ul>
        //             {errors.map((el, i) => <li key={i}>{el}</li>)}
        //         </ul>
        //     )
        // }
    }]), []);
    return (
        <Formik
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setSubmitting}) => {
                run(importService.importPlcd, values, {setSubmitting})
                    .then((response) => {
                        const {errorsFile, file} = response.responseData;
                        let message = 'Import effectué.';
                        if (errorsFile) {
                            message += ' Mais il existe des erreurs.';
                        }
                        snackBar(message);
                        if (!errorsFile) {
                            setFilters(prevState => ({...prevState})); // Reload parent Datagrid.
                            closeDialog();
                        } else {
                            setCurrentFilters(prevState => ({errorsFile: errorsFile, file: file, page: 1}));
                        }
                    });
            }}
        >
            <Form>
                <DialogTitle className={'headerModal'}>
                    {!currentFilters ? 'Import PLCD' : 'Téléchargement du fichier d\'erreur'}
                </DialogTitle>
                <DialogContent className={'contentModal'}>
                    <MuiTableAsync
                        columns={columns}
                        request={importService.readPlcd}
                        filters={currentFilters}
                        customPagination
                        getRowId={() => {
                            return _.uniqueId();
                        }}
                    />
                </DialogContent>
                <DialogActions className={'footerModal'}>
                    {!currentFilters ? <>
                        <SubmitButton>Importer</SubmitButton>
                        <Button onClick={closeDialog}>Annuler</Button>
                    </> : <>
                        <Button variant={'contained'} color={'error'} onClick={() => {
                            Download(currentFilters.file, true);
                            closeDialog();
                        }}>Télécharger le récapitulatif d'import</Button>
                        <Button onClick={() => {
                            closeDialog();
                            fileService.remove({url: currentFilters.file});
                        }}>Annuler</Button>
                    </>}
                </DialogActions>
            </Form>
        </Formik>
    );
}

export default PlcdDialog;