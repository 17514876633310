import {vatCodeService} from "../../../../services/vatCode.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const VatCode = () => {
    const columns = [
        {field: 'label', headerName: 'Nom', flex: 1},
        {field: 'code', headerName: 'Code', flex: 1},
    ];
    return <ReferentialTable
        columns={columns}
        callback={vatCodeService.getAllVatCodes}
        addLabel={'Ajouter un code TVA'}
        enableFilters={false}
    />
}

export default VatCode