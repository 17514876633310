import {useParams} from "react-router-dom";
import Confirmation from "../../feature/Confirmation";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {run} from "../../utils/services";

const ConfirmationAction = ({request, callback, ...otherProps}) => {
    const {id} = useParams();
    const handleSubmit = () => {
        run(request, {id})
            .then(() => {
                callback();
            });
    }

    const configConfirmation = {
        ...otherProps,
        buttonLabel: otherProps.buttonLabel || 'Supprimer cet élément',
        modalTitle: otherProps.modalTitle || 'Supprimer cet élément',
        modalContent: otherProps.modalContent || 'Voulez vous supprimer cet élément ?',
        startIcon: otherProps.startIcon || <CardGiftcardIcon/>,
        color: otherProps.color || 'success'
    }

    return <Confirmation {...configConfirmation} handleConfirm={handleSubmit}/>
}

export default ConfirmationAction;