import {TextField} from "@mui/material";
import {useField} from 'formik';

const TextFieldWrapper = ({name, ...otherProps}) => {
    const [field, meta] = useField(name)
    const configTextField = {
        ...field,
        value: field.value ? field.value : '',
        fullWidth: true,
        variant: 'outlined',
        ...(otherProps.type === 'date' && {
            InputLabelProps: {
                shrink: true
            }
        }),
        ...otherProps
    }
    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }
    return <TextField {...configTextField} />
}

export default TextFieldWrapper;