import {Button as MuiButton, Button, Grid, Stack} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {chenevisService} from "../../../services/chenevis.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {ROLES} from "../../../constants/roles";
import Vivescia from "./Vivescia";
import FormExport from "./FormExport";
import {siloService} from "../../../services/silo.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {run} from "../../../utils/services";
import {ARTICLE_TYPE_CODES, SENDER_MAIL} from "../../../constants/types";
import {useDialog} from "../../../feature/DialogProvider";
import {useUserContext} from "../../../context/UserContext";
import _ from "../../../utils/lodash/number/number_format";
import {toLocaleDate} from "../../../utils/i18n";
import SenderMail from "../../../feature/SenderMail";
import HarvestYearFilter from "../../../components/Filters/HarvestYearFilter";
import ArticleFilter from "../../../components/Filters/ArticleFilter";
import MemberFilter from "../../../components/Filters/MemberFilter";
import {useFilterContext} from "../../../context/FilterContext";


const ChenevisTickets = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {getFilterHash} = useFilterContext();
    const [filters, setFilters] = useState(getFilterHash);
    const {isGranted, currentUser} = useUserContext();
    const [openDialog] = useDialog();

    const [catalog, setCatalog] = useState({
        silos: [],
        articles: [],
        tickets: [],
        loading: true
    });

    const columns = [
        {field: 'number', headerName: 'N° Bon', flex: 1},
        {field: 'harvestYear', headerName: 'Année', flex: 1},
        {
            field: 'ticketDate',
            headerName: 'Date bon',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'silo.name', headerName: 'Silos', flex: 1, valueGetter: (params) => params.row.silo?.name},
        {field: 'member.company', headerName: 'Adhérent', flex: 1, valueGetter: (params) => params.row.member?.company},
        {
            field: 'quantityDelivered', headerName: 'Quantité livré', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value, 3, 3)
        },
    ];

    useEffect(() => {
        Promise.all([
            run(siloService.getAllSilos, {pagination: false}),
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.GRAINES}),
            // chenevisService.getAllChenevis({pagination: false}),
        ]).then((values) => {
            let [silos, articles] = values.map((el) => el.responseData);
            silos = hydraService.getMembers(silos);
            articles = hydraService.getMembers(articles);
            setCatalog({...catalog, silos, articles, loading: false});
        });
        return () => null;
    }, []);

    return (
        <>
            {!catalog.loading && (
                <Grid container spacing={3} className="Grid Grid-root">
                    <Grid item xs={6} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <HarvestYearFilter filters={filters}
                                               setFilters={setFilters}
                                               name={'harvestYear'}
                                               label={'Année'}
                                               optionNoneText={'Aucune'}
                            />
                            <ArticleFilter filters={filters}
                                           setFilters={setFilters}
                                           options={catalog.articles}
                                           optionNoneText={'Aucun'}
                            />
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <MemberFilter filters={filters}
                                              setFilters={setFilters}
                                />
                            </ProtectedComponent>
                        </Stack>
                    </Grid>
                    <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <SenderMail typeSender={SENDER_MAIL.CHENEVIS_TICKETS}/>
                                <Vivescia/>
                            </ProtectedComponent>
                            <MuiButton onClick={() => openDialog({
                                fullWidth: true,
                                maxWidth: 'md',
                                children: <FormExport catalog={catalog}
                                                      isGranted={isGranted}
                                                      currentUser={currentUser}
                                                      title={'Exporter un/des ticket(s) chenevis'}/>
                            })}>
                                Edition
                            </MuiButton>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <Button component={Link} variant="contained" to={`${location.pathname}/add`}
                                        sx={{mb: 0}}>
                                    Ajouter un ticket chenevis
                                </Button>
                            </ProtectedComponent>
                        </Stack>
                    </Grid>
                </Grid>
            )}
            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={chenevisService.getAllChenevis}
                filters={filters}
                customPagination
            />
        </>
    )
}

export default ChenevisTickets