import {useEffect, useState} from "react";
import {
    Divider,
    Grid
} from "@mui/material";
import {useParams} from "react-router-dom";
import {hydraService} from "../../utils/hydra";
import {memberService} from "../../services/member.service";
import MemberDetailHead from "../../feature/MemberDetailHead";
import {run} from "../../utils/services";
import Loader from "../Loader";
import moment from "moment";
import AreaTable from "./AreaTable";
import {ROLES} from "../../constants/roles";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {useUserContext} from "../../context/UserContext";

const AreaPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        member: {},
        siloDistances: []
    });

    useEffect(() => {
        Promise.all([
            run(memberService.getMember, id),
            run(memberService.getMemberSiloDistances, {id, searchParameters: {timestamp: moment().unix(), pagination: true}})
        ]).then((values) => {
            let [member, siloDistances] = values.map((el    ) => el.responseData);
            siloDistances = hydraService.getMembers(siloDistances);
            setData({member, siloDistances});
            setIsLoading(false);
        });
        return () => null;
    }, []);

    return (
        isLoading ? <Loader/> :
            <FormDisabler disabled={isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <MemberDetailHead member={data.member}/>
                    <Divider/>
                    <Grid container className="Grid-container" sx={{paddingLeft: "20px"}}>
                        <Grid item xs={12} md={5}>
                            <AreaTable {...data} type={'ATTACHMENT'}/>
                        </Grid>
                        <Grid item xs={12} md={1}/>
                        <Grid item xs={12} md={5}>
                            <AreaTable {...data} type={'DRYER'}/>
                        </Grid>
                    </Grid>
                </Grid>
            </FormDisabler>
    )
}

export default AreaPage;