import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class UserService {
  async getAllUsers(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.USER + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getUser(id) {
    return axios
      .get(`${ENDPOINTS.USER}/${id}`)
      .then(handleResponse);
  }

  async addUser(parameters) {
    return axios
      .post(ENDPOINTS.USER, parameters)
      .then(handleResponse);
  }

  async editUser(parameters) {
    return axios
      .put(`${ENDPOINTS.USER}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeUser(parameters) {
    return axios
      .delete(`${ENDPOINTS.USER}/${parameters.id}`)
      .then(handleResponse);
  }

  async getUserNotifications(id) {
    return axios
        .get(`${ENDPOINTS.USER}/${id}/notifications`)
        .then(handleResponse);
  }
}

export const userService = new UserService();
