import {Navigate, useLocation} from "react-router-dom";
import {useUserContext} from "../../../context/UserContext";
import {ROLES} from "../../../constants/roles";

const Logout = () => {
    const {resetToken, isGranted} = useUserContext();
    const location = useLocation();
    resetToken();
    if (process.env.REACT_APP_EXTRANET_URL && !isGranted(ROLES.ADMIN) && isGranted(ROLES.MEMBER)) {
        window.location.href = process.env.REACT_APP_EXTRANET_URL;
        return null;
    }
    return <Navigate to={'/login'} state={{ prev: location.pathname }} />

}

export default Logout;