import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Stack} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useCallback} from "react";
import _ from "../../../utils/lodash/array/toggle";

const MultipleCheckboxWrapper = ({name, legend, options, sxLabel = {}, excludes = [], ...otherProps}) => {

    options = options || [];
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = useCallback((e) => {
        const {value} = e.target;
        let boxs = field.value;
        _.toggle(boxs, value);
        setFieldValue(name, boxs);
    }, [field.value]);

    const configCheckbox = {
        ...field,
        ...otherProps,
        onChange: handleChange,
    }

    const configFormControl = {}
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
        configFormControl.helpertext = meta.error;
    }

    return (
        <FormControl {...configFormControl}>
            {legend && <FormLabel component={'legend'}>{legend}</FormLabel>}
            <FormGroup row>
            {options
                    .filter(el => !excludes.includes(el))
                    .map((el, i) => {
                        const config = {
                            ...configCheckbox,
                            value: el,
                            checked: field.value.includes(el)
                        };
                    return (
                        <FormControlLabel
                            key={i}
                            label={el}
                            name={name}
                            control={<Checkbox {...config}/>}
                            sx={sxLabel}
                        />
                    )
                })}
            </FormGroup>
            {configFormControl.helpertext && <FormHelperText>{configFormControl.helpertext}</FormHelperText>}
        </FormControl>
    );
}

export default MultipleCheckboxWrapper;