import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {
    Box,
    Button as MuiButton,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    MenuItem,
    Stack
} from "@mui/material";
import {ROLES} from "../../constants/roles";
import {engagementsService} from "../../services/engagements.service";
import React from "react";
import TableAsync from "../../components/Mui/DataGridAsync";
import ProtectedComponent from "../../feature/ProtectedComponent";
import MuiSelect from "../../components/Mui/Select";
import Range from "../../utils/range";
import MuiAutocompleteAsync from "../../components/Mui/AutocompleteAsync";
import {memberService} from "../../services/member.service";
import _ from "lodash";
import {useDialog} from "../../feature/DialogProvider";
import {Form, Formik} from "formik";
import AutocompleteAsync from "../../components/Formik/AutocompleteAsync";
import SelectYear from "../../components/Formik/SelectYear";
import SubmitButton from "../../components/Formik/SubmitButton";
import {run} from "../../utils/services";
import snackBar from "../../components/SnackBar";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Download from "../../components/Download";

const Engagements = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState({});
    const [openDialog] = useDialog();

    const columns = [
        {
            field: 'member.company',
            headerName: 'Adhérent',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {field: 'currentYear', headerName: 'Année en cours', flex: 1},
        {field: 'capitalContract', headerName: 'Capital', flex: 1},
        {
            field: 'file',
            headerName: 'Fichier',
            flex: 1,
            renderCell: (params) => {
                return params.value?.hostFilename ? (
                    <IconButton color={'error'} onClick={() => Download(params.value.hostFilename)}>
                        <PictureAsPdfIcon/>
                    </IconButton>
                ) : ' - ';
            },
            sortable: false
        },
    ];

    const ExportDialog = ({title, setFilters}) => {
        const [, closeDialog] = useDialog();
        const INITIAL_FORM_STATE = {
            from_member_id: null,
            to_member_id: null,
            year: null,
            generate_mail: false,
        }
        return (
            <Formik
                validateOnChange={false}
                initialValues={INITIAL_FORM_STATE}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    run(engagementsService.generateEngagement, values, {setFieldError, setSubmitting})
                        .then((response) => {
                            if (response.statusText === 'OK') {
                                snackBar('Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.');
                                setFilters(prevState => ({...prevState}));
                                closeDialog();
                            } else {
                                snackBar("Il n'y a pas de membre pour cette année");
                            }
                        });
                }}
            >
                <Form>
                    <DialogTitle className={title ? "headerModal" : null}>
                        <div><h3>{title}</h3></div>
                    </DialogTitle>
                    <DialogContent className="contentModal">
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'from_member_id'}
                                        label={'De l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'ASC'}}
                                        hydra
                                        className="Autocomplete"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'to_member_id'}
                                        label={'A l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                        hydra
                                        className="Autocomplete"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectYear
                                        name={'year'}
                                        label={'Année'}
                                        reverse
                                        nullIfEmpty
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            </Formik>
        )
    }

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                    <Grid item xs={8} className="Grid">
                        <Stack direction={'row'} spacing={1}>
                            <MuiSelect name={'currentYear'}
                                       label={'Année'}
                                       value={filters.currentYear ?? ''}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           const newFilters = value ? {
                                               ...filters,
                                               [name]: value
                                           } : delete filters[name];
                                           setFilters(newFilters);
                                       }}
                            >
                                <MenuItem value={''}><em>Aucune</em></MenuItem>
                                {Range(new Date().getFullYear(), 2021, -1).map((el, i) => (
                                    <MenuItem key={i} value={el}>{el}</MenuItem>
                                ))}
                            </MuiSelect>
                            <MuiAutocompleteAsync
                                name={'code'}
                                label={'Adhérent...'}
                                filterName={'company'}
                                request={memberService.getAllMembers}
                                callback={(e, value) => {
                                    let newFilters;
                                    if (value) {
                                        const {code} = value;

                                        newFilters = {...filters, ...(code && {'member.code': code})};
                                    } else {
                                        newFilters = delete filters['member.code'];
                                    }
                                    setFilters(newFilters);
                                }}
                                getOptionLabel={(option) => `${option.company} ${option.code}`}
                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            />
                        </Stack>
                    </Grid>
                    <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <MuiButton variant={'contained'} onClick={() => openDialog({
                                    fullWidth: true,
                                    maxWidth: 'md',
                                    children: <ExportDialog title={'Générer une situation des engagements'} setFilters={setFilters}/>
                                })}>
                                    Générer des engagements
                                </MuiButton>
                            </ProtectedComponent>
                        </Stack>
                    </Grid>
                </ProtectedComponent>
            </Grid>
            <TableAsync
                columns={columns}
                onRowClick={false}
                request={engagementsService.getAllEngagements}
                filters={filters}
                customPagination
            />
        </>
    )
}
export default Engagements
