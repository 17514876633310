import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
    Button as MuiButton,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem
} from "@mui/material";
import {useDialog} from "../DialogProvider";
import {Form, Formik} from "formik";
import SubmitButton from "../../components/Formik/SubmitButton";
import DateNative from "../../components/Formik/Date/Native";
import {SENDER_MAIL} from "../../constants/types";
import {run} from "../../utils/services";
import {senderMailerService} from "../../services/senderMailer.service";
import Select from "../../components/Formik/Select";
import snackBar from "../../components/SnackBar";

const SenderMail = ({typeSender}) => {
    const [openDialog] = useDialog();
    return (
        <IconButton onClick={() => openDialog({
            fullWidth: true,
            maxWidth: 'md',
            children: <Window typeSender={typeSender}/>
        })}>
            <ForwardToInboxIcon/>
        </IconButton>
    );
}

const Window = ({typeSender = SENDER_MAIL.CHENEVIS_TICKETS}) => {
    const [, closeDialog] = useDialog();
    const INITIAL_FORM_STATE = {
        date_start: null,
        date_end: null,
        straw_type: null,
        sender_type: typeSender
    }

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(senderMailerService.send, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar('Demande effectuée.');
                        closeDialog();
                    });
            }}
        >
            <Form>
                <DialogTitle className={"headerModal"}>
                    <div><h3>{`Envoyer e-mail récapitulatif`}</h3></div>
                </DialogTitle>
                <DialogContent className="contentModal">
                    <Grid
                        container
                        spacing={3}
                        columnSpacing={{xs: 1, sm: 2, md: 3}}
                        className="Grid-container"
                    >
                        {typeSender === SENDER_MAIL.STRAW_TICKETS &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <Select name={'straw_type'} label={'Type de paille'} nullIfEmpty>
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {[{
                                            id: 'weighting_date',
                                            label: 'Date de pesée'
                                        // }, {
                                        //     id: 'fabrication_date',
                                        //     label: 'Date de fabrication'
                                        }].map((el, i) => {
                                            return <MenuItem key={i} value={el.id}>{el.label}</MenuItem>;
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}/>
                            </>
                        }
                        <Grid item xs={12} md={6}>
                            <DateNative name={"date_start"} label={"Date de début"} nullIfEmpty/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateNative name={"date_end"} label={"Date de fin"} nullIfEmpty/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="footerModal">
                    <SubmitButton>
                        Envoyer
                    </SubmitButton>
                    <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                </DialogActions>
            </Form>
        </Formik>
    );
}

export default SenderMail;