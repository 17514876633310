import {departmentService} from "../../../../services/department.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const Department = () => {
    const columns = [
        {field: 'code', headerName: 'Code', flex: 0.7},
        {field: 'label', headerName: 'Nom', flex: 1.3},
    ];
    return <ReferentialTable
        columns={columns}
        callback={departmentService.getAllDepartments}
        addLabel={'Ajouter un département'}
        enableFilters={false}
    />
}

export default Department