import {useFormikContext} from "formik";
import {Grid} from "@mui/material";
import NumberField from "../../../components/Formik/NumberField";
import {useEffect, useRef, useState} from "react";
import {chenevisPaymentConditionService} from "../../../services/chenevisPaymentConditions.service";
import {hydraService} from "../../../utils/hydra";
import {run} from "../../../utils/services";

const Amount = () => {
    const {values, setFieldValue} = useFormikContext();

    const [cache, setCache] = useState({});
    const [ready, setReady] = useState(false);

    const calculate = (amountExcludingVat, paymentConditions) => {
        if (amountExcludingVat) {
            const amountIncludingVat = +amountExcludingVat * (1 + ((paymentConditions?.vatAdvance?.rate || 0) / 100));
            return {
                'amountIncludingVat': amountIncludingVat.toFixed(2),
                'vatAmount': (amountIncludingVat - amountExcludingVat).toFixed(2)
            };
        }
    }

    const putValues = (pc = {}) => {
        if (ready && values.amountExcludingVat) {
            const result = calculate(values.amountExcludingVat, pc);
            setFieldValue('vatAmount', result.vatAmount);
            setFieldValue('amountIncludingVat', result.amountIncludingVat);
        }
    }

    useEffect(()=>{
        setReady(true);
    }, []);

    useEffect(() => {
        if (!ready) {
            return;
        }
        const timeId = setTimeout(() => {
            (async () => {
                const key = `${values.harvestYear}-${values.article}`;
                if (values.article && values.harvestYear) {
                    if (!(key in cache)) {
                        const data = await run(chenevisPaymentConditionService.getAllChevenisPaymentConditions,{
                            article: values.article,
                            year: values.harvestYear
                        });
                        const conditions = hydraService.getMembers(data.responseData)[0] ?? {};
                        setCache({...cache, [key]: conditions})
                        setFieldValue('vat', hydraService.getIriFromItem(conditions.vatAdvance));
                        putValues(conditions);
                    } else {
                        putValues(cache[key]);
                    }
                } else {
                    putValues();
                }
            })();
        }, 600);
        return () => {
            clearTimeout(timeId);
        }
    }, [values.article, values.harvestYear, values.amountExcludingVat]);


    return (
        <>
            <Grid item xs={12} md={4}>
                <NumberField asString
                             name={'amountExcludingVat'}
                             label={'Montant HT'}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <NumberField asString
                             name={'vatAmount'}
                             label={'Montant TVA'}
                             disabled
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <NumberField asString
                             name={'amountIncludingVat'}
                             label={'Montant TTC'}
                             disabled
                />
            </Grid>
        </>
    );
}

export default Amount;