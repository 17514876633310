import {siloService} from "../../../../services/silo.service";
import ReferentialTable from "../../../../components/Parameters/ReferentialTable";

const Silos = () => {
    const columns = [
        {field: 'name', headerName: 'Nom', flex: 1},
        {field: 'type', headerName: 'Type', flex: 1},
    ];
    return <ReferentialTable
        columns={columns}
        callback={siloService.getAllSilos}
        addLabel={'Ajouter un silo'}
        enableFilters={false}
    />
}

export default Silos;