import {
    Box, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    MenuItem
} from "@mui/material";
import Select from "../../../components/Formik/Select";
import {Form, Formik, insert, useFormikContext} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {NumberField, TextField} from "../../../components/Formik";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as Yup from "yup";


const EditDialog = ({catalog, title, push, insert, replace, dataUpdate}) => {
    const {values: initValues} = useFormikContext();
    const {articles, vatCodes} = catalog;

    const [formState, setFormState] = useState(null);

    const handleClose = () => {
        setFormState(null);
    }

    const handleOpen = (data) => {
        setFormState(data);
    }

    useEffect(() => {
        if (!dataUpdate) {
            return;
        }
        handleOpen(dataUpdate);
    }, [dataUpdate]);

    const FORM_VALIDATION = Yup.object().shape({
        article: Yup.string().nullable().required('L\'article est obligatoire'),
        designation: Yup.string().nullable().required('La désignation est obligatoire'),
        quantity: Yup.number().nullable().required('La quantité est obligatoire'),
        unitPrice: Yup.number().nullable().required('Le prix unitaire est obligatoire'),
        number: Yup.number().nullable().required('Le nombre est obligatoire'),
    });

    const populateDesignation = useCallback(
        (iri, setFieldValue) => {
            const article = articles.find(el => el['@id'] === iri);
            const articleYear = article.articleYears.find(el => el.year === initValues.harvestYear);
            const vat = vatCodes.find(el => el['@id'] === articleYear?.vat)
            setFieldValue('designation', article?.label);
            setFieldValue('unitPrice', articleYear?.amount ?? null);
            setFieldValue('vatRate', vat?.rate ?? null);
        }, [articles, vatCodes, initValues]);


    return (
        <>
            <MuiButton variant={'contained'}
                       disabled={!(initValues.harvestYear && initValues.articleType)}
                       onClick={() => {
                           const form = {
                               article: null,
                               designation: null,
                               quantity: null,
                               unitPrice: null,
                               number: null,
                               vatRate: null,
                           }
                           handleOpen(form);
                       }}
            >Ajouter un article</MuiButton>
            <Dialog open={Boolean(formState)} onClose={handleClose}>
                {formState &&
                    <Formik initialValues={{...formState}}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values) => {
                                if (!('id' in values)) {
                                    const id = '_' + Math.random().toString(16).slice(2);
                                    push({...values, id});
                                } else {
                                    console.log(values)
                                    const index = initValues.articles.findIndex(el => el.id === values.id);
                                    replace(index, values);
                                }
                                handleClose();
                            }}>
                        {({setFieldValue}) => (
                            <Form>
                                <DialogTitle className={"headerModal"}>
                                    <div><h3>{`${formState.id ? 'Modifier' : 'Ajouter'} un article`}</h3></div>
                                </DialogTitle>
                                <DialogContent className="contentModal">
                                    <Box sx={{my: 2}}>
                                        <Grid container spacing={3} className="Grid-container">
                                            <Grid item xs={12} md={6} className="Grid-grid">
                                                <Select name={'article'}
                                                        label={'Article'}
                                                        callback={(iri) => populateDesignation(iri, setFieldValue)}
                                                >
                                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                                    {articles
                                                        .filter(el => el.type === initValues.articleType)
                                                        .sort()
                                                        .map((el, i) => {
                                                            return <MenuItem key={i}
                                                                             value={el['@id']}>{el.label}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="Grid-grid">
                                                <TextField
                                                    name={'designation'}
                                                    label={'Désignation d\'article'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    name={'quantity'}
                                                    label={'Quantité'}
                                                    decimal={2}
                                                    asString
                                                    nullIfEmpty
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} className="Grid-grid">
                                                <NumberField
                                                    name={'number'}
                                                    label={'Nombre'}
                                                    asString
                                                    nullIfEmpty
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    name={'unitPrice'}
                                                    label={'Prix unitaire'}
                                                    asString
                                                    nullIfEmpty
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogContent>
                                <DialogActions className="footerModal">
                                    <SubmitButton>
                                        {formState.id ? 'Modifier' : 'Ajouter'}
                                    </SubmitButton>
                                    <MuiButton onClick={handleClose}>Annuler</MuiButton>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                }
            </Dialog>
        </>

    );
}

export default EditDialog;