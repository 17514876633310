import {Divider, Grid, Stack} from "@mui/material";
import Checkbox from "../../components/Formik/Checkbox";
import DateNative from "../../components/Formik/Date/Native";
import TextField from "../../components/Formik/TextField";
import Radio from "../../components/Formik/Radio";
import {useEffect, useRef, useState} from "react";
import {memberService} from "../../services/member.service";
import {hydraService} from "../../utils/hydra";
import Loader from "../Loader";
import AutocompleteAsync from "../../components/Formik/AutocompleteAsync";
import {FieldArray, getIn, useFormikContext} from "formik";
import NumberField from "../../components/Formik/NumberField";
import MemberDetailHead from "../../feature/MemberDetailHead";
import {run} from "../../utils/services";
import {useContractContext} from "../../context/ContractContext";
import {STRAW_CODES} from "../../constants/types";
import {useTheme} from '@mui/material/styles';
import {strawService} from "../../services/straw.service";
import {useParams} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import {ROLES} from "../../constants/roles";

const DetailPage = () => {
    const {values, setFieldValue} = useFormikContext();
    const {catalog} = useContractContext();
    const {isGranted} = useUserContext();
    const [memberSelected, setMemberSelected] = useState(null);
    const theme = useTheme();
    const cacheSocialShare = useRef({});
    const {id = null} = useParams();

    useEffect(() => {
        if (values.member) {
            run(memberService.getMember, hydraService.getIdFromIri(values.member))
                .then((response) => {
                    const member = response.responseData;
                    setMemberSelected(member);
                });
        }
    }, []);

    useEffect(() => {
        if (isGranted(ROLES.ADMIN) && values.year && values.year.toString().length === 4 && values.year >= 2023) {

            if (values.basicEngagement) {
                setFieldValue('declaration.capitalShares', (Number(values.basicEngagement) * 100 / 75).toFixed(2))
            }

            if (values.year in cacheSocialShare.current) {
                const socialShare = cacheSocialShare.current[values.year];
                const capital = Number(values.declaration.capitalShares) * Number(socialShare?.priceHemp ?? null)
                setFieldValue('declaration.capital', capital.toFixed(2));
                return;
            }
            run(strawService.getAllStrawSocialShares, {year: values.year})
                .then((response) => {
                    const socialShare = hydraService.getMembers(response.responseData)[0] ?? null;
                    if (socialShare) {
                        cacheSocialShare.current[values.year] = socialShare;
                    }
                    const capital = Number(values.declaration.capitalShares) * Number(socialShare?.priceHemp ?? null);
                    setFieldValue('declaration.capital', capital.toFixed(2));
                });
            if (id === null && memberSelected) {
                run(memberService.getMemberEngagements, hydraService.getIdFromIri(values.member))
                    .then((response) => {
                        const engagements = hydraService.getMembers(response.responseData);
                        const engagement = engagements.find(el => +el.currentYear === +values.year) ?? null;
                        const engagementYear = engagement?.engagementYears.find(el => +el.contractYear === +values.year) ?? null;
                        setFieldValue('basicEngagement', engagementYear?.basicEngagement ?? null);
                        setFieldValue('contractToProduce', engagementYear?.contractToProduce ?? null);
                    });
            }
        }
    }, [values.basicEngagement, values.year, memberSelected]);

    function createTonnages(articles = []) {
        return articles.filter(el => STRAW_CODES.find(sc => sc.code === el.code))
            .map(el => ({
                article: el['@id'],
                maxTonnage: null,
            }));
    }

    return (
        catalog.isLoading ? <Loader/> :
            <>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    {memberSelected && <MemberDetailHead member={memberSelected}/>}
                    <Grid xs={12} lg={12} item>
                        <AutocompleteAsync
                            name={'member'}
                            label={'Adhérent...'}
                            filterName={'company'}
                            request={memberService.getAllMembers}
                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            value={memberSelected}
                            callback={(member) => {
                                setMemberSelected(member);
                                setFieldValue('declaration.endDateEngagement', member.capital.endDateContract)
                            }}
                            hydra
                        />
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid xs={2} item>
                        <Checkbox name={'closedContract'} label={'Contrat soldé'}/>
                    </Grid>
                    <Grid xs={10} item>
                        <Checkbox name={'harvestYearClosed'} label={'Année de récolte soldé'}/>
                    </Grid>
                    <Grid xs={4} item>
                        <DateNative name={'contractDate'} label={'Date du contrat'}/>
                    </Grid>
                    <Grid xs={4} item>
                        <NumberField
                            name={'year'}
                            label={'Année'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            disabled={theme.components.MuiFormControlLabel.defaultProps.disabled || values.strawTickets.length > 0}
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <NumberField
                            asString
                            name={'hectaresNumber'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            label={'Nombre d\'hectares'}
                            disabled={theme.components.MuiFormControlLabel.defaultProps.disabled || getIn(values, 'hasSubscription')}
                        />
                    </Grid>
                </Grid>
                <Divider/>
                <h4 style={{paddingLeft: '24px'}}>Type d'agriculture</h4>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid xs={12} item>
                        <Radio
                            name={'farmingType'}
                            label={''}
                            options={catalog.farmingTypes.map(el => ({
                                label: el,
                                value: el
                            }))}
                            row
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <TextField
                            name={'biologicalCertificateNumber'}
                            label={'Numéro de certificat biologique'}
                        />
                    </Grid>
                    <Grid xs={4} item/>
                    <Grid xs={4} item/>
                    <Grid xs={4} item>
                        <Checkbox name={'outEngagement'} label={'Tonnage additionnel accepté'}/>
                    </Grid>
                    <Grid xs={8} item>
                        {values.outEngagement &&
                            <FieldArray name={'tonnages'}>
                                <Stack spacing={2}>
                                    {(values.tonnages || createTonnages(catalog.articles)).map((el, i) => (
                                        <NumberField
                                            asString
                                            key={i}
                                            name={`tonnages[${i}].maxTonnage`}
                                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                            label={catalog.articles?.find(article => article['@id'] === el.article)?.label}
                                        />
                                    ))}
                                </Stack>
                            </FieldArray>}
                    </Grid>
                    <Grid xs={4} item>
                        <NumberField
                            asString
                            name={'contractToProduce'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            label={'Contrat à produire'}
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <NumberField
                            asString
                            name={'basicEngagement'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            label={'Engagement de base'}
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <NumberField
                            name={'badWeatherCancellation'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                            label={'Annulation intempéries'}
                            asString
                        />
                    </Grid>
                </Grid>
            </>
    );
}

export default DetailPage;