import {useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem} from "@mui/material";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {importService} from "../../../services/import.service";
import Switch from "../../../components/Formik/Switch";
import snackBar from "../../../components/SnackBar";
import {Form, Formik} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {run} from "../../../utils/services";
import {toLocaleDate} from "../../../utils/i18n";

const Vivescia = () => {

    const INITIAL_FORM_STATE = {
        generateMail: false
    }

    const [open, setOpen] = useState(false);

    const columns = useMemo(() => {
        return [
            {field: 'number', headerName: 'N° Bon', flex: 1},
            {field: 'year', headerName: 'Année', flex: 1},
            {field: 'silo', headerName: 'N° Silo', flex: 1},
            {
                field: 'ticketDate',
                headerName: 'Date bon',
                flex: 1,
                valueFormatter: (params) => toLocaleDate(params.value)
            },
            {field: 'code', headerName: 'Code', flex: 1},
            {field: 'quantityDelivered', headerName: 'Quantité livrée', flex: 1},
            {field: 'quantityStandard', headerName: 'Quantité norme', flex: 1},
            {field: 'humidity', headerName: 'Humidité', flex: 1},
            {field: 'impurityOther', headerName: 'Impurté autre', flex: 1},
            {field: 'impurityGreenGrain', headerName: 'Impurté grains verts', flex: 1},
            {field: 'impuritySproutedGrain', headerName: 'Impurté semées', flex: 1},
            {field: 'vivesciaCode', headerName: 'Code Vivescia', flex: 1},
        ]
    }, []);

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                onSubmit={(values, {setSubmitting}) => {
                    run(importService.importVivescia, values, {setSubmitting})
                        .then((response) => {
                            snackBar('Import Vivescia effectué');
                            handleClose();
                            window.location.reload(false);
                        });
                }}
            >
                <Form>
                    <Button
                        onClick={handleClick}
                    >
                        Récuperation des bons Vivescia
                    </Button>
                    <Dialog open={open} fullWidth maxWidth={'xl'} onClose={handleClose}>
                        <DialogTitle className={'headerModal'}>
                            Récuperation des bons Vivescia
                        </DialogTitle>
                        <DialogContent className={'contentModal'}>
                            <Box>
                                <MuiTableAsync
                                    columns={columns}
                                    // onRowClick={(props) => navigate(props.id)}
                                    request={importService.readVivescia}
                                    filters={{}}
                                    customPagination
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions className={'footerModal'}>
                            <SubmitButton>Importer</SubmitButton>
                            <Button onClick={handleClose}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            </Formik>
        </>
    );
};

export default Vivescia;