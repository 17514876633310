import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class FileService {
  async remove(parameters) {
    return axios
      .delete(`${ENDPOINTS.FILE_REMOVE}`, {
        data: {
          file: parameters.url
        }
      })
      .then(handleResponse);
  }
}

export const fileService = new FileService();
