import {useNavigate} from "react-router-dom";
import {userService} from "../../../services/user.service";
import {Grid} from "@mui/material";
import {PARAMETERS} from "../../../constants/parameters";
import TableAsync from "../../../components/Mui/DataGridAsync";
import RedirectButton from "../../../components/Mui/RedirectButton";
import _ from "lodash";

const Users = () => {
    const navigate = useNavigate();
    const columns = [
        {field: 'lastname', headerName: 'Nom', flex: 1},
        {field: 'firstname', headerName: 'Prénom', flex: 1},
        {field: 'email', headerName: 'Email', flex: 2},
        {
            field: 'roles', headerName: 'Rôle', flex: 1,
            valueGetter: (params) => {
                switch (true) {
                    case _.indexOf(params.row.roles, 'ROLE_ADMIN') > -1:
                        return 'Administrateur';
                    case _.indexOf(params.row.roles, 'ROLE_ACCOUNTANT') > -1:
                        return 'Comptable';
                    default:
                        return 'Adhérent';
                }
            }
        },
    ];
    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={6} className="Grid">
                </Grid>
                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                    <RedirectButton to={PARAMETERS.ROUTE_ADD}>Ajouter un utilisateur</RedirectButton>
                </Grid>
            </Grid>
            <TableAsync
                columns={columns}
                onRowClick={(props) => navigate(`/parameters${props.id}`)}
                request={userService.getAllUsers}
                customPagination
            />
        </>
    );
}

export default Users;