import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {PARAMETERS} from "../../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import {useEffect, useState} from "react";
import {hydraService} from "../../../../../utils/hydra";
import {chenevisHumidityService} from "../../../../../services/chenevisHumidity.service";
import Loader from "../../../../Loader";
import snackBar from "../../../../../components/SnackBar";
import ModalDelete from "../../ModalDelete";
import Box from "@mui/material/Box";
import NumberField from "../../../../../components/Formik/NumberField";
import {run} from "../../../../../utils/services";
import BackButton from "../../../../../components/BackButton";

const HumidityPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const initialState = {
        year: currentYear,
        startScale: '',
        endScale: '',
        amount: '',
        coefficient: '',
    }
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(initialState);

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        if (!isCreation) {
            run(chenevisHumidityService.getChevenisHumidity, id)
                .then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        run(chenevisHumidityService.removeChevenisHumidity, {id})
            .then(() => {
                navigate(PARAMETERS.BACK_ROUTE);
            })
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(chenevisHumidityService.addChevenisHumidity, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(chenevisHumidityService.editChevenisHumidity, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={12} item>
                                <NumberField label={'Année'} name={'year'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField
                                    label={'De'}
                                    name={'startScale'}
                                    asString
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField
                                    label={'À'}
                                    name={'endScale'}
                                    asString
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField
                                    label={'Réfaction'}
                                    name={'amount'}
                                    asString
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField
                                    label={'Coefficient de retour aux normes'}
                                    name={'coefficient'}
                                    asString
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du barème humidité" handleDelete={handleDelete}/>}
                </Box>
            </>
    )

}

export default HumidityPage