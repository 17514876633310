export const ENTITY = {
    CHENEVIS_HUMIDITY: {
        namespace: "Chenevis\\Humidity",
    },
    CHENEVIS_KILOMETRIC: {
        namespace: "Chenevis\\KilometricAllowance",
    },
    CHENEVIS_KILOMETRIC_DRYER: {
        namespace: "Chenevis\\KilometricAllowanceDryerSilo",
    },
    CHENEVIS_PAYMENT_CONDITIONS: {
        namespace: "Chenevis\\PaymentCondition",
    },

    STRAW_UNIFORMITIES: {
        namespace: "Straw\\Uniformity",
    },
    STRAW_BALL_WEIGHTS: {
        namespace: "Straw\\BallWeight"
    },
    STRAW_COLOR_MANUFACTURINGS: {
        namespace: "Straw\\ColorManufacturing"
    },
    STRAW_POLLUTIONS: {
        namespace: "Straw\\Pollution"
    },
    STRAW_PRESENTATIONS: {
        namespace: "Straw\\Presentation"
    },
    STRAW_LOADING_TIMES: {
        namespace: "Straw\\LoadingTime"
    },
    STRAW_HUMIDITIES: {
        namespace: "Straw\\Humidity"
    },
    STRAW_PAYMENT_CONDITIONS: {
        namespace: "Straw\\PaymentCondition"
    },
    STRAW_COLOR_DRIVERS: {
        namespace: "Straw\\ColorDriver"
    },
    STRAW_PEBBLES: {
        namespace: "Straw\\Pebble"
    },
    STRAW_SOCIAL_SHARES: {
        namespace: "Straw\\SocialShares"
    }
}