import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";
import _ from "../../../../../utils/lodash/number/number_format";

const Presentation = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'label', headerName: 'Libellé', flex: 1},
        {field: 'plcdCode', headerName: 'Code PLCD', flex: 1},
        {
            field: 'amount', headerName: 'Montant', flex: 1, valueFormatter: (params) => {
                return _.numberFormat(params.value);
            }
        },
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawPresentations}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_PRESENTATIONS.namespace)}
    />
}

export default Presentation;