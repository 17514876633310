import {useMemo, useState} from "react";
import {Button, Grid, Stack} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {contractService} from "../../services/contract.service";
import MuiTableAsync from "../../components/Mui/DataGridAsync";
import {ROLES} from "../../constants/roles";
import {useUserContext} from "../../context/UserContext";
import ProtectedComponent from "../../feature/ProtectedComponent";
import ImportMenu from "../../feature/ImportMenu";
import {toLocaleDate} from "../../utils/i18n";
import HarvestYearFilter from "../../components/Filters/HarvestYearFilter";
import {useFilterContext} from "../../context/FilterContext";
import MemberFilter from "../../components/Filters/MemberFilter";

const Contracts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isGranted} = useUserContext();
    const {getFilterHash} = useFilterContext();
    const [filters, setFilters] = useState(getFilterHash);

    const importMenu = useMemo(() => {
        return [
            {title: 'Import contrat', type: "contract"},
            {title: 'Import avenant bio', type: "avenant_bio"},
            {title: 'Import avenant rouie', type: "avenant_rouie"},
            {title: 'Import avenant semi-rouie', type: "avenant_semi_rouie"},
            {title: 'Import déclaration de stock', type: "color_driver_stock"},
            {title: 'Import déclaration de récolte', type: "color_driver_harvest"},
        ]
    }, [])

    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {
            field: 'contractDate',
            headerName: 'Date contrat',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'member.code', headerName: 'Code', flex: 1, valueGetter: (params) => params.row.member?.code},
        {field: 'member.title', headerName: 'Titre', flex: 1, valueGetter: (params) => params.row.member?.title},
        {field: 'member.company', headerName: 'Nom', flex: 1, valueGetter: (params) => params.row.member?.company},
        {field: 'member.city', headerName: 'Ville', flex: 1, valueGetter: (params) => params.row.member?.city},
    ];

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={6} className="Grid">
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                        <Stack spacing={1} direction={'row'}>
                            <HarvestYearFilter name={'year'} filters={filters} setFilters={setFilters}/>
                            <MemberFilter filters={filters} setFilters={setFilters}/>
                        </Stack>
                    </ProtectedComponent>
                </Grid>
                {isGranted(ROLES.ADMIN) &&
                    <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <ImportMenu data={importMenu} callback={() => {
                                setFilters({...filters});
                            }}/>
                            <Button component={Link} variant="contained" to={`${location.pathname}/add`} sx={{mb: 0}}>
                                Ajouter un contrat
                            </Button>
                        </Stack>
                    </Grid>
                }
            </Grid>
            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={contractService.getAllContracts}
                filters={filters}
                customPagination
            />
        </>
    )
}

export default Contracts