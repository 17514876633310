import {Box, Button as MuiButton, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@mui/material";
import Select from "../../components/Formik/Select";
import AutocompleteAsync from "../../components/Formik/AutocompleteAsync";
import {memberService} from "../../services/member.service";
import MultipleCheckbox from "../../components/Formik/MultipleCheckbox";
import SelectYear from "../../components/Formik/SelectYear";
import {run} from "../../utils/services";
import {exportService} from "../../services/export.service";
import snackBar from "../../components/SnackBar";
import download from "../../components/Download";
import {useDialog} from "../../feature/DialogProvider";
import SubmitButton from "../../components/Formik/SubmitButton";
import {Form, Formik} from "formik";

const FormExport = ({title}) => {

    const [, closeDialog] = useDialog();

    const INITIAL_FORM_STATE = {
        format: null,
        year: null,
        from_member_id: null,
        to_member_id: null,
        options: []
    }

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(exportService.exportMembers, values, {setFieldError, setSubmitting})
                    .then((response) => {
                        snackBar(`Génération effectuée`, 'success');
                        download(response.responseData.pdf, true);
                        return;
                    });
            }}
        >
            <Form>
                <DialogTitle className={title ? "headerModal" : null}>
                    <div><h3>{title}</h3></div>
                </DialogTitle>
                <DialogContent className="contentModal">
                    <Box sx={{my: 2}}>
                        <Grid container spacing={3} className="Grid-container">
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <Select name={'article_id'} label={'Format de l\'export'}>
                                    <MenuItem value={''}><em>Aucune</em></MenuItem>
                                    {['fiche', 'liste'].map((el, i) => {
                                        return <MenuItem key={i} value={el}>{el}</MenuItem>
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <SelectYear
                                    name={'year'}
                                    label={'Année'}
                                    reverse
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompleteAsync
                                    name={'from_member_id'}
                                    label={'De l\'adhérent...'}
                                    filterName={'company'}
                                    request={memberService.getAllMembers}
                                    getOptionLabel={(option) => `${option.company} ${option.code}`}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    hydra
                                    className="Autocomplete"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompleteAsync
                                    name={'to_member_id'}
                                    label={'A l\'adhérent...'}
                                    filterName={'company'}
                                    request={memberService.getAllMembers}
                                    getOptionLabel={(option) => `${option.company} ${option.code}`}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    hydra
                                    className="Autocomplete"
                                />
                            </Grid>
                            <Grid item xs={12} className="Grid-checkbox-container">
                                <MultipleCheckbox
                                    name={'options'}
                                    legend={'Type d\'export'}
                                    options={['contact', 'fiche_adherent', 'surface', 'capital']}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions className="footerModal">
                    <SubmitButton>
                        Générer
                    </SubmitButton>
                    <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                </DialogActions>
            </Form>
        </Formik>
    );
}

export default FormExport;