import {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Grid, MenuItem, InputAdornment} from "@mui/material";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import ChenevisTable from "../../ticket/chenevis/ChenevisTable";
import {useNavigate, useParams} from "react-router-dom";
import {memberService} from "../../../services/member.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import Loader from "../../Loader";
import {chenevisInvoiceService} from "../../../services/chenevisInvoice.service";
import snackBar from "../../../components/SnackBar";
import {catalogService} from "../../../services/catalog.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import DisableTextField from "../../../components/Mui/DisableTextField";
import Amount from "./Amount";
import {run} from "../../../utils/services";
import {ARTICLE_TYPE_CODES} from "../../../constants/types";
import {ROLES} from "../../../constants/roles";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {useUserContext} from "../../../context/UserContext";
import FormDisabler from "../../../components/Formik/ThemeFormikDisablerProvider";
import ActionInvoice from "../ActionInvoice";
import NavigationButtons from "../NavigationButtons";

const ChenevisInvoice = () => {
    const {isGranted} = useUserContext();
    const navigate = useNavigate();
    const {id} = useParams();
    const INITIAL_FORM_STATE = {
        member: null,
        unitPrice: "",
        tickets: [],
        number: "",
        invoiceDate: null,
        expiryDate: null,
        harvestYear: null,
        article: null,
        reference: null,
        amountExcludingVat: null,
        amountIncludingVat: null,
        vatAmount: null,
        vat: null,
        credit: false,
        isBlocked: false,
        canGenerateCredit: false
    }
    const FORM_VALIDATION = Yup.object().shape({});

    const [loading, setLoading] = useState(!!id);
    const [catalog, setCatalog] = useState({
        articles: [],
        invoicesTypes: [],
        exportInvoicesTypes: [],
        paymentConditions: []
    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [memberSelected, setMemberSelected] = useState(null);

    const INVOICE_TYPE_FILTER = [
        '1er acompte',
        '2ème acompte',
        'Solde',
    ].concat([data.reference]);

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.GRAINES}),
            run(catalogService.getAllCatalogExportInvoicesTypes, {pagination: false}),
            run(catalogService.getAllCatalogInvoicesTypes, {pagination: false})
        ]).then((values) => {
            let [articles, exportInvoicesTypes, invoicesTypes] = values.map(el => el.responseData);
            articles = hydraService.getMembers(articles);
            setCatalog({articles, exportInvoicesTypes, invoicesTypes});

            if (!!id) {
                chenevisInvoiceService.getChevenisInvoice(id)
                    .then((response) => {
                        setData(response.responseData);
                        memberService.getMember(hydraService.getIdFromIri(response.responseData.member))
                            .then((response) => {
                                setMemberSelected(response.responseData);
                                setLoading(false);
                            });
                    })
            }
        });
        return () => null;
    }, [id]);

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(chenevisInvoiceService.addChevenisInvoice, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué.');
                                })
                        } else {
                            run(chenevisInvoiceService.editChevenisInvoice, {id, ...values}, {
                                setFieldError,
                                setSubmitting
                            })
                                .then(() => {
                                    snackBar('Enregistrement effectué.');
                                })
                        }
                    }}
                >
                    <Form>
                        <NavigationButtons data={data}/>
                        <FormDisabler disabled={data.isBlocked || isGranted(ROLES.MEMBER, ROLES.ACCOUNTANT)}>
                            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                  className="Grid-container">
                                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                    <Grid item xs={12} md={12}>
                                        <AutocompleteAsync
                                            name={'member'}
                                            label={'Adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            value={memberSelected}
                                            hydra
                                        />
                                    </Grid>
                                </ProtectedComponent>
                                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                    <Grid item xs={12} md={6}>
                                        <DisableTextField value={data.number}
                                                          InputProps={{
                                                              startAdornment: <InputAdornment
                                                                  position="start">{data.credit ? 'CREDIT' : 'FACTURE'} N°</InputAdornment>,
                                                          }}
                                        />
                                    </Grid>
                                </ProtectedComponent>

                                <Grid item xs={12} md={isGranted(ROLES.ADMIN) ? 6 : 12}>
                                    <DateNative
                                        name={'invoiceDate'}
                                        label={'Date facturation'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'article'} label={'Article'} nullIfEmpty disabled={data.isBlocked}>
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {catalog.articles.map((el, i) => {
                                            return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'reference'} label={'Référence'} nullIfEmpty
                                            disabled={data.isBlocked}>
                                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                                        {catalog.invoicesTypes.filter(el => INVOICE_TYPE_FILTER.includes(el)).map((el, i) => {
                                            return <MenuItem key={i} value={el}>{el}</MenuItem>
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectYear
                                        name={'harvestYear'}
                                        label={'Année'}
                                        reverse
                                        disabled={data.isBlocked}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateNative name={'expiryDate'} label={'Date échéance'}/>
                                </Grid>
                                <Amount/>
                                {data.tickets.length > 0 &&
                                    <Grid item xs={12} md={12}>
                                        <h4>Tickets</h4>
                                        <ChenevisTable/>
                                    </Grid>}
                            </Grid>
                        </FormDisabler>
                    </Form>
                </Formik>
                {id && <ActionInvoice data={data}/>}
            </>
    );
}

export default ChenevisInvoice;