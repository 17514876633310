import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class DepositService {
  async getAllDeposits(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.DEPOSITS + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }
}

export const depositService = new DepositService();
