import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";

function NotFound() {
    // return (
    //     <div className="container-content d-flex align-center justify-center pos-relative">
    //         <div className="content-404">
    //             <p>Nous ne trouvons pas la page <br />que vous cherchez</p>
    //             <h2>404</h2>
    //             <Link className="btn btn-secondary" to="/">Retour à l'accueil</Link>
    //         </div>
    //     </div>
    // )
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: 'primary',
            }}
        >
            <Typography variant="h1">
                404
            </Typography>
            <Typography variant="h6">
                The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" href={'/'}>Back Home</Button>
        </Box>
    );
}

export default NotFound