import {Grid} from "@mui/material";
import NumberField from "../../../components/Formik/NumberField";
import {useFormikContext} from "formik";
import {useEffect, useRef, useState} from "react";

const Quantity = ({catalog}) => {
    const {values, setFieldValue} = useFormikContext();
    const [ready, setReady] = useState(false);

    /**
     *
     * @param arrayReduce
     * @returns {string}
     */
    function calculateImpurityTotal(arrayReduce = []) {
        const impurityTotal = arrayReduce
            .reduce((acc, value) => acc + (+value), 0);
        return impurityTotal.toFixed(2);
    }

    /**
     *
     * @param scaleHumidities
     * @param impurityTotal
     * @returns {string}
     */
    function calculQuantityNormes(values, scaleHumidities = [], impurityTotal = 0) {
        const quantityDelivered = +values.quantityDelivered;
        const impurityGreenGrain = +values.impurityGreenGrain;
        const impuritySproutedGrain = +values.impuritySproutedGrain;
        const impurityOther = +values.impurityOther;
        const humidity = +values.humidity;
        const year = values.harvestYear;
        let quantityNormes = 0;
        if (quantityDelivered !== 0) {
            quantityNormes = quantityDelivered - (quantityDelivered * impurityTotal / 100);
            if (scaleHumidities?.length) {
                quantityNormes = scaleHumidities.reduce((acc, item) => {
                    const endHumidity = +`${item["endScale"]}`;
                    const startHumidity = +`${item["startScale"]}`;
                    const coefficient = +`${item["coefficient"]}`;

                    if (item['year'] && item["year"] === year && humidity >= startHumidity && humidity <= endHumidity) {
                        return acc * coefficient / 100;
                    }
                    return acc;
                }, quantityDelivered);
            }
            quantityNormes = +(quantityNormes.toFixed(3));
            quantityNormes -= quantityNormes * (impurityGreenGrain / 100)
                            + quantityNormes * (impuritySproutedGrain / 100)
                            + quantityNormes * (impurityOther / 100);
        }
        return quantityNormes.toFixed(3);
    }

    useEffect(() => {
        setReady(true);
    }, [])

    useEffect(() => {
            if (!ready) {
                return;
            }
            const timeoutId = setTimeout(() => {
                const impurityTotal = calculateImpurityTotal([values.impurityGreenGrain, values.impuritySproutedGrain, values.impurityOther]);
                const quantityStandard = calculQuantityNormes(values, catalog.humidities, +impurityTotal);
                setFieldValue('impurityTotal', impurityTotal);
                setFieldValue('quantityStandard', quantityStandard);
            }, 600);
            return () => {
                clearTimeout(timeoutId);
            }
        }, [
            values.harvestYear, values.humidity,
            values.quantityDelivered, values.impurityGreenGrain,
            values.impuritySproutedGrain, values.impurityOther
        ]
    );

    return (
        <>
            <Grid item xs={12} md={6}>
                <NumberField
                    name={"impurityTotal"}
                    label={"Total impuretés"}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberField
                    name={"quantityStandard"}
                    label={"Quantité normes"}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled
                />
            </Grid>
        </>
    );
}

export default Quantity;