import {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import NumberField from "../../../../components/Formik/NumberField";
import {InputAdornment} from "@mui/material";
import SnackBar from "../../../../components/SnackBar";
import {useStrawTicketContext} from "../../../../context/StrawTicketContext";
import {hydraService} from "../../../../utils/hydra";

const PebbleWeightField = ({catalog = {}, name = 'fabrication', loading = false}) => {
    const {
        setHasContractChange
    } = useStrawTicketContext();
    const {values, setFieldValue, setValues} = useFormikContext();
    const [ready, setReady] = useState(false);
    const {ballWeights} = catalog;
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!ready) { // Hack reloading
            setReady(true);
            return;
        }

        const timeoutId = setTimeout(() => {
            if (!catalog.pebbles.length) {
                SnackBar(`Le barème "Cailloux ou terre" est indéfini en ${values.harvestYear}`, 'error');
                return;
            }

            // Calcul du barème "Cailloux ou terre"
            const deliveredWeight = Math.max(+values.grossWeight - +values.tareWeight, 0).toFixed(2);
            const rate = +values[name].pebbleWeight / 1000 / +deliveredWeight * 100;
            const pebble = catalog.pebbles.find(el => +rate <= +el.endScale) ?? [...catalog.pebbles].pop();

            // Calcul du barème "Poids des balles"
            const workedWeight = Math.max(+deliveredWeight * 1000 - values[name].pebbleWeight, 0).toFixed(2);
            const calculateBallWeight = values.ballNumber > 0 ? +workedWeight / values.ballNumber : null;
            const newBallWeightId = ballWeights.find(el => +el.startScale <= calculateBallWeight && +el.endScale >= calculateBallWeight) ?? null;

            setValues(prevValues => ({
                ...prevValues,
                fabrication: {
                    ...prevValues.fabrication,
                    pebble: hydraService.getIriFromItem(pebble),
                    ballWeight: hydraService.getIriFromItem(newBallWeightId)
                }
            }));

        }, 600);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [
        values[name].pebbleWeight
    ]);

    return (
        <NumberField
            asString
            name={`${name}.pebbleWeight`}
            label={'Poids cailloux en Kg'}
            InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            nullIfEmpty
        />
    );
}

export default PebbleWeightField;