import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {strawService} from "../../../../../services/straw.service";
import {ENTITY} from "../../../../../constants/entity";

const Pollution = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'plcdCode', headerName: 'Code PLCD', flex: 1},
    ];

    return <ReferentialTable
        columns={columns}
        callback={strawService.getAllStrawPollutions}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.STRAW_POLLUTIONS.namespace)}
    />
}

export default Pollution;