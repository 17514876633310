import {TextField} from "@mui/material";
import {useCallback, useEffect} from "react";
import {useFilterContext} from "../../context/FilterContext";
import _ from "lodash";

// https://dmitripavlutin.com/react-throttle-debounce/
const CodeMemberFilter = ({filters = {}, setFilters = () => null}) => {
    const {setFilterHash} = useFilterContext();
    const handlerChange = (e) => {
        const {name, value} = e.target;
        const newFilters = value ? {...filters, [e.target.name]: e.target.value} : _.omit(filters, [name]);
        setFilters(newFilters);
        setFilterHash(newFilters);
    }
    const debouncedHandler = useCallback(_.debounce(handlerChange, 600), []);

    useEffect(() => {
        return () => {
            debouncedHandler.cancel();
        }
    }, []);

    return (
        <TextField fullWidth
                   name={'code'}
                   label={'Rechercher un code adhérent...'}
                   onChange={debouncedHandler}
                   defaultValue={filters.code ?? ''}
        />
    );
}

export default CodeMemberFilter;