import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {chenevisHumidityService} from "../../../../../services/chenevisHumidity.service";
import {ENTITY} from "../../../../../constants/entity";

const Humidity = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 0.8},
        {field: 'startScale', headerName: 'De', flex: 1},
        {field: 'endScale', headerName: 'À', flex: 1},
        {field: 'amount', headerName: 'Réfaction', flex: 1},
        {field: 'coefficient', headerName: 'Coefficient', flex: 1},
    ];

    return <>
        <ReferentialTable columns={columns}
                          callback={chenevisHumidityService.getAllChevenisHumidities}
                          addLabel={'Ajouter un barème'}
                          cloneEntity={String(ENTITY.CHENEVIS_HUMIDITY.namespace)}
        />
    </>
}

export default Humidity