import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class SiloService {
  async getAllSilos(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.SILO + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getSilo(id) {
    return axios
      .get(`${ENDPOINTS.SILO}/${id}`)
      .then(handleResponse);
  }

  async addSilo(parameters) {
    return axios
      .post(ENDPOINTS.SILO, parameters)
      .then(handleResponse);
  }

  async editSilo(parameters) {
    return axios
      .put(`${ENDPOINTS.SILO}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeSilo(parameters) {
    return axios
      .delete(`${ENDPOINTS.SILO}/${parameters.id}`)
      .then(handleResponse);
  }

  async getAllSiloTypes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.SILO_TYPES + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async cloneSilos(parameters) {
    return axios
        .post(ENDPOINTS.DUPLICATE_SILO, parameters)
        .then(handleResponse)
  }
}

export const siloService = new SiloService();
