import {ENDPOINTS} from "../constants/endpoints";
import axios from "../utils/axios";
import {handleResponse} from "../utils/services";
import {INVOICE_TYPE} from "../constants/types";

class StrawInvoiceService {
    async getAllStrawInvoices(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.STRAW_INVOICE + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getStrawInvoice(id) {
        return axios
            .get(`${ENDPOINTS.STRAW_INVOICE}/${id}`)
            .then(handleResponse);
    }

    async addStrawInvoice(parameters) {
        return axios
            .post(ENDPOINTS.STRAW_INVOICE, parameters)
            .then(handleResponse);
    }

    async editStrawInvoice(parameters) {
        return axios
            .put(`${ENDPOINTS.STRAW_INVOICE}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeStrawInvoice(parameters) {
        return axios
            .delete(`${ENDPOINTS.STRAW_INVOICE}/${parameters.id}`)
            .then(handleResponse);
    }

    async generateBalancePdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/balance`, parameters)
            .then(handleResponse);
    }

    async generatePaymentPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/payment`, parameters)
            .then(handleResponse);
    }

    async generateSubscriptionPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/subscription`, parameters)
            .then(handleResponse);
    }

    async generateTransportPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/transport`, parameters)
            .then(handleResponse);
    }

    async generateCreditPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/payment/credit`, parameters)
            .then(handleResponse);
    }

    async generateSubscriptionCreditPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/subscription/credit`, parameters)
            .then(handleResponse);
    }

    async generateTransportCreditPdf(parameters) {
        return axios
            .post(`${ENDPOINTS.INVOICE}/straw/generate/transport/credit`, parameters)
            .then(handleResponse);
    }

    async generateInvoiceCredit(parameters) {
        return axios
            .post(`${ENDPOINTS.STRAW_INVOICE}/${parameters.id}/credit`, parameters)
            .then(handleResponse);
    }
}

export const strawInvoiceService = new StrawInvoiceService();
