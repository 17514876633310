import {Form, Formik} from "formik";
import {authentificationService} from "../../../services/authentification.service";
import {getErrors} from "../../../utils/axios";
import snackBar from "../../../components/SnackBar";
import {Container, IconButton, InputAdornment, Typography} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Logo from "../../../assets/images/logo_chanvrier.png";
import TextField from "../../../components/Formik/TextField";
import SubmitButton from "../../../components/Formik/SubmitButton";
import RedirectButton from "../../../components/Mui/RedirectButton";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {useState} from "react";


const UpdatePassword = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    const location = useLocation();

    const INITIAL_FORM_STATE = {
        tokenKey: token,
        plainPassword: '',
        confirmPassword: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        plainPassword: Yup.string().required('Champ obligatoire'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('plainPassword'), null], 'Les mots de passe doivent être identiques')
    });

    const [showPlainPassword, setShowPlainPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = (setPassword) => setPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const styleTitle = {
        margin: "20px auto",
        color: "#464a53",
        textAlign: "center",
        fontSize: "16px"
    }

    return (
        <Formik
            validateOnBlur={false}
            initialValues={{...INITIAL_FORM_STATE}}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                if(values.plainPassword === values.confirmPassword) {
                    authentificationService.resetPassword(values)
                        .then((res)=>{
                            snackBar("Le mot de passe a bien été modifié")
                            navigate("/login")
                        }).catch((axiosError) => {
                            const error = getErrors(axiosError)
                            snackBar(`Erreur : ${error}`, 'error');
                        });
                } else {
                    snackBar("Les 2 mots de passe ne sont pas identiques", "error");
                }
            }}
        >
            <Form className={'login'}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{
                            maxWidth: "270px",
                            margin: "auto",
                        }}>
                            <img style={{width: '100%'}} src={Logo} alt={"La chanvrière"} />
                        </Box>
                        <Typography component="h1" variant="h2" sx={styleTitle}>
                            Veuillez saisir votre nouveau mot de passe
                        </Typography>
                        <Box noValidate>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Nouveau mot de passe"
                                name="plainPassword"
                                autoComplete="email"
                                autoFocus
                                type={showPlainPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword(setShowPlainPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPlainPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Confirmer le mot de passe"
                                name="confirmPassword"
                                autoComplete="email"
                                autoFocus
                                type={showConfirmPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword(setShowConfirmPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <SubmitButton fullWidth>
                                Modifier le mot de passe
                            </SubmitButton>
                            <RedirectButton fullWidth to={'/login'} variant={'text'}>Retour</RedirectButton>
                        </Box>
                    </Box>
                </Container>
            </Form>
        </Formik>
    );

}

export default UpdatePassword