import Table from "../../../components/Mui/DataGrid";
import {useFormikContext} from "formik";
import {useMemo} from "react";
import _ from "lodash";

const ChenevisTable = () => {
    const {values} = useFormikContext();
    const {unitPrice, tickets} = values;

    const rows = tickets;

    const columns = useMemo(() => [
        {
            field: 'ticket.number',
            headerName: 'N° Bon',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'ticket.quantityStandard',
            headerName: 'Qté normes',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'unitPrice',
            headerName: 'Prix de bases',
            flex: 1,
            valueGetter: () => unitPrice
        },
        {
            field: 'amountHumidity',
            headerName: 'Humidité',
            flex: 1,
        },
        {
            field: 'amountApproach',
            headerName: 'Approche',
            flex: 1,
        },
        {
            field: 'netPrice',
            headerName: 'Prix net',
            flex: 1
        },
        {
            field: 'amountExcludingVat',
            headerName: 'Total HT',
            flex: 1
        },
    ], [unitPrice]);

    return (
        <Table
            rows={rows}
            columns={columns}
            hideFooter
            getRowId={(params) => params['@id']}
        />
    );
}

export default ChenevisTable;