import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import {run} from "../../../utils/services";
import Table from "../../../components/Mui/DataGrid";
import EditDialog from "../../../pages/ticket/supply/EditRow";
import {useState} from "react";
import {Grid} from "@mui/material";
import Confirmation from "../../../feature/Confirmation";
import {ticketService} from "../../../services/ticket.service";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import _ from "lodash";

const ArticleTable = ({articles, push, insert, replace, remove, form}) => {
    const {values} = form;
    const {isGranted} = useUserContext();

    const [editData, setEditData] = useState(undefined);

    const handleRemove = (id) => {
        return run(ticketService.removeTicketArticle, id);
    }

    const handleEditData = (row) => {
        setEditData(row);
    }

    let columns = [
        {
            field: 'article',
            headerName: 'Code article',
            flex: 1,
            valueGetter: (params) => articleInfo(params.value)?.code
        },
        {field: 'designation', headerName: 'Désignation', flex: 1},
        {field: 'quantity', headerName: 'Quantité', flex: 1},
        {field: 'number', headerName: 'Nombre', flex: 1},
        {
            field: 'unitPrice',
            headerName: 'Prix unitaire',
            flex: 1,
            align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
    ];

    if (!isGranted(ROLES.MEMBER)) {
        columns = columns.concat([
            {
                field: 'action',
                type: 'actions',
                headerName: 'Action',
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                    const {id, row} = params;
                    return <Confirmation
                        buttonLabel={''}
                        modalTitle={'Suppression d\'un article'}
                        modalContent={'Confirmez-vous la suppression de cet article ?'}
                        handleConfirm={() => {
                            if (row['action'] || isGranted(ROLES.MEMBER)) {
                                return [];
                            }
                            const index = rows.findIndex(el => el.id === id);
                            if (isNaN(id)) {
                                remove(index);
                            } else {
                                handleRemove(id).then(() => {
                                    remove(index);
                                });
                            }
                        }}/>
                }
            },
        ])
    }

    const articleInfo = (iri) => {
        return articles.find(el => el['@id'] === iri);
    }

    const rows = values.articles
        ?.map((article, i) => {
            return ({
                id: article.id,
                article: article.article,
                designation: article.designation,
                quantity: article.quantity,
                unitPrice: article.unitPrice,
                number: article.number,
            })
        });

    return (
        <Grid container spacing={2} className="Grid-container">
            <ProtectedComponent acceptedRoles={ROLES.ADMIN}>
                <Grid item xs={12} container justifyContent="flex-end">
                    <EditDialog push={push}
                                insert={insert}
                                replace={replace}
                                articles={articles}
                                dataUpdate={editData}
                    />
                </Grid>
            </ProtectedComponent>
            <Grid item xs={12}>
                <Table
                    rows={rows}
                    onRowClick={(params) => handleEditData(params.row)}
                    columns={columns}
                    hideFooter/>
            </Grid>
        </Grid>
    );
}

export default ArticleTable;