export const TYPE = {
    STRAW: '/article_types/1',
    SEED: '/article_types/2',
    OTHER: '/article_types/3',
    SUPPLY: '/article_types/4',
    CONDITIONING: '/article_types/5',
}

export const TYPE_SILO = {
    ATTACHMENT: 'Rattachement',
    DRYER: 'Sécheur',
    ATTACHMENT_DRYER: 'Rattachement + sécheur'
}

export const STRAW_CODES = [
    {code: '5', label: 'Rouie'},
    {code: '6', label: 'Non Rouie'},
    {code: '7', label: 'Semi Rouie'},
    {code: '8', label: 'Bio Rouie'},
    {code: '9', label: 'Bio Non Rouie'},
    {code: '10', label: 'Bio Semi Rouie'}
]

export const ARTICLE_TYPE_CODES = {
    PAILLE: 1,
    GRAINES: 2,
    AUTRES: 3,
    APPROVISIONNEMENT: 4,
    CONDITIONNEMENT: 5
}

export const INVOICE_TYPE = {
    SUBSCRIPTION: 'Cotisation',
    TRANSPORT: 'Transport',
    BALANCE: 'Solde',
    CREDIT: 'Avoir',
    X_DEPOSIT: 'Xème acompte',
    APPRO: 'Approvisionnement',
}

export const TRANSPORT_TYPE = {
    MEMBER: 'Adhérent',
    TRUCK: 'Camion',
}

export const SENDER_MAIL = {
    CHENEVIS_TICKETS: 'chenevis_tickets',
    STRAW_TICKETS: 'straw_tickets',
    CHENEVIS_INVOICES: 'chenevis_invoices',
    STRAW_INVOICES: 'straw_invoices',
}

export const SHARE_MOVEMENT_TYPES = {
    TRANSFERT: 'Transfert',
    IMPORT: 'Import',
    ADD: 'Ajout',
    REMOVE: 'Diminution'
}