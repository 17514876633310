import {useField, useFormikContext} from "formik";
import {TextField} from "@mui/material";
import {convertDate} from "../../../../utils/moment";
import {useCallback} from "react";

const DateWrapper = ({name, nullIfEmpty = false, callback = ()=>{}, ...otherProps}) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = useCallback((e) => {
        let {value} = e.target;
        if (nullIfEmpty && !value) {
            value = null;
        }
        setFieldValue(name, value);
        callback(value);
    }, [field.value]);

    const configDateField = {
        ...field,
        ...otherProps,
        value: field.value ? convertDate(field.value) : '',
        type: 'date',
        fullWidth: true,
        variant: 'outlined',
        onChange: handleChange,
        InputLabelProps: {
            shrink: true
        }
    }

    if (meta && meta.touched && meta.error) {
        configDateField.error = true;
        configDateField.helperText = meta.error;
    }

    return <TextField {...configDateField}/>
}

export default DateWrapper;