import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Box, Grid, Stack} from "@mui/material";
import TextField from "../../../../../components/Formik/TextField";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import {useEffect, useState} from "react";
import Loader from "../../../../Loader";
import snackBar from "../../../../../components/SnackBar";
import ModalDelete from "../../ModalDelete";
import {hydraService} from "../../../../../utils/hydra";
import {strawService} from "../../../../../services/straw.service";
import {run} from "../../../../../utils/services";
import NumberField from "../../../../../components/Formik/NumberField";
import Checkbox from "../../../../../components/Formik/Checkbox";
import BackButton from "../../../../../components/BackButton";

const BallWeightPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        year: currentYear,
        startScale: null,
        endScale: null,
        label: '',
        amount: '',
        alertTriggered: false,
    });

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        (async () => {
            if (!isCreation) {
                run(strawService.getStrawBallWeight, id).then((response) => {
                    setData(response.responseData);
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        run(strawService.removeStrawBallWeight, id)
            .then(() => {
                navigate(PARAMETERS.BACK_ROUTE);
            });
    }

    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (!id) {
                        run(strawService.addStrawBallWeight, values, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                                navigate(PARAMETERS.BACK_ROUTE)
                            });
                    } else {
                        const id = hydraService.getIdFromIri(values);
                        run(strawService.editStrawBallWeight, {id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                            });
                    }
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={4} item>
                            <NumberField label={'Année'} name={'year'} nullIfEmpty/>
                        </Grid>
                        <Grid xs={12} md={8} item>
                            <TextField label={"Libellé"} name={'label'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <NumberField label={'De'} name={'startScale'} asString nullIfEmpty/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <NumberField label={'À'} name={'endScale'} asString nullIfEmpty/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <NumberField label={'Montant €/T'} name={'amount'} asString/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Checkbox name={'alertTriggered'} label={'Alerte notification'}/>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
            <Box>
                {!isCreation && <ModalDelete title="de l'article" handleDelete={handleDelete}/>}
            </Box>
        </>
    )

}

export default BallWeightPage