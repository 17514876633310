import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class VatCodeService {
  async getAllVatCodes(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.VAT_CODE + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getVatCode(id) {
    return axios
      .get(`${ENDPOINTS.VAT_CODE}/${id}`)
      .then(handleResponse);
  }

  async addVatCode(parameters) {
    return axios
      .post(ENDPOINTS.VAT_CODE, parameters)
      .then(handleResponse);
  }

  async editVatCode(parameters) {
    return axios
      .put(`${ENDPOINTS.VAT_CODE}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeVatCode(parameters) {
    return axios
      .delete(`${ENDPOINTS.VAT_CODE}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const vatCodeService = new VatCodeService();
