import {
    Divider,
    Grid,
    Button as MuiButton,
    Dialog,
    DialogContent,
    Typography,
    Stack,
    DialogTitle, MenuItem, Alert
} from "@mui/material";
import {useParams} from "react-router-dom";
import {Formik, Form} from "formik";
import DateNative from "../../components/Formik/Date/Native";
import Button from "../../components/Formik/SubmitButton";
import NumberField from "../../components/Formik/NumberField";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useEffect, useState, useMemo, useCallback} from "react";
import {memberService} from "../../services/member.service";
import {hydraService} from "../../utils/hydra";
import * as Yup from "yup";
import {capitalService} from "../../services/capital.service";
import snackBar from "../../components/SnackBar";
import Loader from "../Loader";
import Table from "../../components/Mui/DataGrid";
import {ROLES} from "../../constants/roles";
import ProtectedComponent from "../../feature/ProtectedComponent";
import MemberDetailHead from "../../feature/MemberDetailHead";
import {capitalHistoriesService} from "../../services/capitalHistories.service";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {run} from "../../utils/services";
import {toLocaleDate} from "../../utils/i18n";
import {useUserContext} from "../../context/UserContext";
import {SHARE_MOVEMENT_TYPES} from "../../constants/types";
import Select from "../../components/Formik/Select";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {strawService} from "../../services/straw.service";
import moment from "../../utils/moment";

const CapitalPage = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [formState, setFormState] = useState(null);
    const {isGranted} = useUserContext();

    const INITIAL_FORM_STATE = useMemo(() => (
        {
            member: `/members/${id}`,
            socialSharesNumber: '',
            capital: '',
            endDateContract: null,
            engagement: '',
            engagementBase: '',
            hasCapital: false,
            hasScaleSocialShares: false,
        }
    ), [id]);
    const [data, setData] = useState(INITIAL_FORM_STATE);

    const FORM_VALIDATION = Yup.object().shape({
        socialSharesNumber: Yup.number().typeError('Le nombre de parts sociales doit être un nombre'),
        capital: Yup.number().typeError('Le capital doit être un nombre'),
        engagement: Yup.number().typeError('L\'engagement N+1 doit être un nombre'),
    });

    useEffect(() => {
        Promise.all([
            run(memberService.getMemberCapital, id, {setFieldError: false}), // Disable error
            run(memberService.getMemberCapitalHistories, id),
            run(strawService.getAllStrawSocialShares, { year: moment().year() }),
        ]).then((values) => {
            const capital = values[0].responseData ?? INITIAL_FORM_STATE;
            const capitalHistories = hydraService.getMembers(values[1].responseData);
            setRows(capitalHistories.map(el => ({...el, id: el['@id']})));
            setData({...capital, hasScaleSocialShares: hydraService.getTotalItems(values[2].responseData) > 0});
        })
            .catch(e => e)
            .finally(() => {
                setIsLoading(false);
            });
        return () => {
            setRows([]);
        }
    }, [INITIAL_FORM_STATE, id]);

    const handleCloseDialog = () => {
        setOpen(false);
        setFormState(null);
    };
    const handleOpenDialog = (data) => {
        setOpen(true);
        setFormState(data);
    };

    const deleteRow = (values) => () => {
        if (isGranted(ROLES.MEMBER)) {
            return;
        }
        let confirmMessage = 'Confirmer la suppression ?';
        if (values.movementType === SHARE_MOVEMENT_TYPES.TRANSFERT && values.shareMovement !== null) {
            const fromTo = values.hempSharesNumber > 0 ? 'en provenance de' : 'vers';
            const fromToMember = values.hempSharesNumber > 0 ? values.shareMovement.memberFrom.company : values.shareMovement.memberTo.company;
            confirmMessage = `Voulez-vous supprimer ce transfert de parts ${fromTo} l'adhérent ${fromToMember} ?\nN’oubliez pas de faire également la régularisation sur l'adhérent ${fromToMember}.`
        }
        if (window.confirm(confirmMessage)) {
            capitalHistoriesService.removeCapitalHistory({
                ...values,
                id: hydraService.getIdFromIri(values.id)
            }).then((response) => {
                setRows(prev => prev.filter(i => i.id !== values.id));
                run(memberService.getMemberCapital, id, {setFieldError: false})
                    .then(response => {
                        const capital = response.responseData;
                        setData(prevState => ({...prevState, ...capital}));
                    })
            });
        }
    };

    const compareRow = useCallback(
        (a, b) => {
            const effectiveDateCompare = a.effectiveDate.localeCompare(b.effectiveDate);
            if (effectiveDateCompare !== 0) {
                return effectiveDateCompare;
            }
            return a.createdAt.localeCompare(a.createdAt);
        }, []
    );

    const createRow = (values, props) => {
        run(capitalHistoriesService.addCapitalHistory, values, {
            setFieldError: props.setFieldError,
            setSubmitting: props.setSubmitting
        }).then((response) => {
            const iri = hydraService.getIriFromItem(response.responseData);
            setRows([...rows, {...response.responseData, id: iri}].sort(compareRow));
            setData({...data, ...response.responseData?.capitalUser});
            props.resetForm();
            handleCloseDialog();
        });
    };
    const updateRow = (values, props) => {
        run(capitalHistoriesService.editCapitalHistory, {
            ...values,
            id: hydraService.getIdFromIri(values.id)
        }, {setFieldError: props.setFieldError, setSubmitting: props.setSubmitting}).then((response) => {
            const iri = hydraService.getIriFromItem(response.responseData);
            const index = rows.findIndex(i => i.id === iri);
            setRows(prev => prev.with(index, {...response.responseData, id: iri}).sort(compareRow));
            setData({...data, ...response.responseData?.capitalUser});
            props.resetForm();
            handleCloseDialog();
        });
    };

    const handleRowClick = (params) => {
        const lastElementOfRows = [...rows].pop();
        if (lastElementOfRows['@id'] !== params.row['@id']
            || params.row.movementType === SHARE_MOVEMENT_TYPES.TRANSFERT
            || isGranted(ROLES.MEMBER)) {
            return;
        }
        handleOpenDialog(params.row)
    };

    let columns = [
        {
            field: 'acceptedDate', headerName: 'Date acceptation par le conseil', flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {
            field: 'effectiveDate', headerName: 'Date effective', flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'movementType', headerName: 'Nature du mouvement', flex: 1},
        {
            field: 'hempSharesNumber',
            headerName: 'Nombre de parts chanvre',
            flex: 1,
            valueFormatter: (params) => params.value ?? '-'
        },
        {
            field: 'flaxSharesNumber',
            headerName: 'Nombre de parts lin',
            flex: 1,
            valueFormatter: (params) => params.value ?? '-'
        },
        {field: 'hempCapital', headerName: 'Capital chanvre', flex: 1, valueFormatter: (params) => params.value ?? '-'},
        {field: 'flaxCapital', headerName: 'Capital lin', flex: 1, valueFormatter: (params) => params.value ?? '-'},
    ];

    if (!(isGranted(ROLES.MEMBER)) && data.hasScaleSocialShares) {
        columns = columns.concat([
            {
                field: "actions",
                cellClassName: "actions",
                type: "actions",
                headerName: "Actions",
                getActions: ({row}) => {
                    const lastElementOfRows = [...rows].pop();
                    return lastElementOfRows && lastElementOfRows['@id'] === row['@id'] ? [
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={deleteRow(row)}
                            color="inherit"
                        />,
                    ] : [];
                },
            },
        ])
    }

    const FORM_VALIDATION_HISTORY = Yup.object().shape({
        acceptedDate: Yup.string().required("Date acceptation par le conseil est obligatoire").nullable(false),
        effectiveDate: Yup.string().required("Date effective est obligatoire").nullable(false),
        movementType: Yup.string().required("Nature du mouvement est obligatoire").nullable(false),
        hempSharesNumber: Yup
            .number()
            .notOneOf([0], 'Les parts chanvre doit être différente de 0')
            .when('flaxSharesNumber', {
                is: (flaxSharesNumber) => !flaxSharesNumber,
                then: Yup.number().typeError("Parts chanvre ou lin obligatoire").required(),
                otherwise: Yup.number().typeError("Nombre de parts chanvre doit être un nombre").nullable(true)
            })
        ,
        flaxSharesNumber: Yup
            .number()
            .notOneOf([0], 'Les parts lin doit être différente de 0')
            .when('hempSharesNumber', {
                is: (hempSharesNumber) => !hempSharesNumber,
                then: Yup.number().typeError("Parts chanvre ou lin obligatoire").required(),
                otherwise: Yup.number().typeError("Nombre de parts lin doit être un nombre").nullable(true)
            }),
        hempCapital: Yup
            .number()
            .notOneOf([0], 'Les capital chanvre doit être différente de 0')
            .when('flaxCapital', {
                is: (flaxCapital) => !flaxCapital,
                then: Yup.number().typeError("Capital chanvre ou lin obligatoire").required(),
                otherwise: Yup.number().typeError("Capital chanvre doit être un nombre").nullable(true)
            }),
        flaxCapital: Yup
            .number()
            .notOneOf([0], 'Les capital lin doit être différente de 0')
            .when('hempCapital', {
                is: (hempCapital) => !hempCapital,
                then: Yup.number().typeError("Capital chanvre ou lin obligatoire").required(),
                otherwise: Yup.number().typeError("Capital lin doit être un nombre").nullable(true)
            }),
    }, [['hempSharesNumber', 'flaxSharesNumber'], ['hempCapital', 'flaxCapital']]);

    return (
        isLoading ? <Loader/> :
            <FormDisabler disabled={isGranted(ROLES.MEMBER)}>
                {!data.hasScaleSocialShares && <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}><Alert severity="error">
                    Aucun barème de part social pour l'année {moment().year()}
                </Alert></Stack>}
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <MemberDetailHead memberId={INITIAL_FORM_STATE.member}/>
                    <Divider/>
                    <Stack sx={{width: "100%"}} flexDirection="row" justifyContent="space-between">
                        <Typography variant="h2" fontWeight="bold" color="primary"
                                    style={{paddingLeft: "20px"}}>Historique</Typography>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN, ROLES.ACCOUNTANT]}>
                            <MuiButton
                                color="primary"
                                variant="contained"
                                disabled={!data.hasScaleSocialShares}
                                onClick={() => {
                                    const form = {
                                        member: `/members/${id}`,
                                        movementType: '',
                                        acceptedDate: '',
                                        effectiveDate: '',
                                        hempSharesNumber: null,
                                        flaxSharesNumber: null,
                                        hempCapital: null,
                                        flaxCapital: null
                                    };
                                    handleOpenDialog(form);
                                }}
                            >
                                Ajouter / Diminuer
                            </MuiButton>
                        </ProtectedComponent>
                    </Stack>
                    <Table rows={rows}
                           columns={columns}
                           hideFooter={true}
                           onRowClick={handleRowClick}
                    />
                    <Dialog open={Boolean(open && formState)} onClose={handleCloseDialog}>
                        <DialogTitle className={"headerModal"}>
                            <div><h3>Historique</h3></div>
                        </DialogTitle>
                        <DialogContent className={"contentModalArticle"}>
                            {formState && (
                                <Formik
                                    initialValues={{...formState}}
                                    validationSchema={FORM_VALIDATION_HISTORY}
                                    onSubmit={(values, {resetForm, setFieldError, setSubmitting}) => {
                                        if (values['id'] || values['@id']) {
                                            updateRow(values, {resetForm, setFieldError, setSubmitting});
                                        } else {
                                            createRow(values, {resetForm, setFieldError, setSubmitting});
                                        }
                                    }}
                                >
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <DateNative
                                                    name={'acceptedDate'}
                                                    label={`Date acceptation par le conseil`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <DateNative
                                                    name={'effectiveDate'}
                                                    label={`Date effective`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Select
                                                    name={"movementType"}
                                                    label={"Nature du mouvement"}
                                                    disabled={Boolean(formState['id'] || formState['@id'])}
                                                >
                                                    <MenuItem value={null}><em>Aucun</em></MenuItem>
                                                    {(formState.movementType === SHARE_MOVEMENT_TYPES.IMPORT ?
                                                        [SHARE_MOVEMENT_TYPES.IMPORT] :
                                                        [
                                                            SHARE_MOVEMENT_TYPES.ADD,
                                                            SHARE_MOVEMENT_TYPES.REMOVE,
                                                        ])
                                                        .map((item, i) => {
                                                            return <MenuItem key={i} value={item}>{item}</MenuItem>
                                                        })}
                                                </Select>
                                            </Grid>
                                            <Grid item md={6}/>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    asString
                                                    nullIfEmpty
                                                    name={"hempSharesNumber"}
                                                    label={"Nombre de parts chanvre"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    asString
                                                    nullIfEmpty
                                                    name={"hempCapital"}
                                                    label={"Capital chanvre"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    asString
                                                    nullIfEmpty
                                                    name={"flaxSharesNumber"}
                                                    label={"Nombre de parts lin"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <NumberField
                                                    asString
                                                    nullIfEmpty
                                                    name={"flaxCapital"}
                                                    label={"Capital lin"}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} rowSpacing={2} display='flex'
                                              flexDirection='row' justifyContent='flex-end'
                                              className={"footerModalArticle"}>
                                            <Button>{formState["id"] ? 'Modifier' : 'Ajouter'}</Button>
                                            <MuiButton
                                                onClick={() => {
                                                    handleCloseDialog();
                                                }}
                                            >
                                                Annuler
                                            </MuiButton>
                                        </Grid>
                                    </Form>
                                </Formik>
                            )}
                        </DialogContent>
                    </Dialog>
                    <Formik
                        initialValues={data}
                        validationSchema={FORM_VALIDATION}
                        enableReinitialize
                        onSubmit={(values, {setFieldError, setSubmitting}) => {
                            if (!('@id' in data)) {
                                run(capitalService.addCapital, values, {setFieldError, setSubmitting})
                                    .then((response) => {
                                        setData(response.responseData);
                                        snackBar('Capital ajouté');
                                    });
                            } else {
                                const id = hydraService.getIdFromIri(values);
                                run(capitalService.editCapital, {...values, id}, {setFieldError, setSubmitting})
                                    .then((response) => {
                                        setData(response.responseData);
                                        snackBar('Capital modifié');
                                    });
                            }
                        }}
                    >
                        <Form>
                            <h4 style={{paddingLeft: "20px"}}>En cours d'engagement</h4>
                            <Grid container spacing={2} className="Grid-container">
                                <Grid item xs={12} md={3}>
                                    <NumberField asString name={'socialSharesNumber'}
                                                 label={'Nombre de parts sociales'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberField asString name={'capital'} label={'Capital'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberField asString name={'engagementBase'} label={'engagement (T)'} disabled/>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberField name={'endDateContract'}
                                                 label={'Date de fin de contrat'}
                                                 nullIfEmpty
                                    />
                                </Grid>
                            </Grid>
                            {/*<h4 style={{paddingLeft: "20px"}}>A venir</h4>*/}
                            <Grid container spacing={2} className="Grid-container">
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <NumberField*/}
                                {/*        asString*/}
                                {/*        name={'engagement'}*/}
                                {/*        label={'Engagement N+1'}*/}
                                {/*        InputProps={{*/}
                                {/*            endAdornment: <InputAdornment position="start">Tonnes</InputAdornment>,*/}
                                {/*        }}*/}
                                {/*        disabled*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} md={12}>
                                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN, ROLES.ACCOUNTANT]}>
                                        <Button>Mettre à jour</Button>
                                    </ProtectedComponent>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </Grid>
            </FormDisabler>
    );
}

export default CapitalPage;