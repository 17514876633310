import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, Stack} from "@mui/material";
import TextField from "../../../../../components/Formik/TextField";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import {useEffect, useState} from "react";
import {hydraService} from "../../../../../utils/hydra";
import Loader from "../../../../Loader";
import snackBar from "../../../../../components/SnackBar";
import ModalDelete from "../../ModalDelete";
import {strawService} from "../../../../../services/straw.service";
import Checkbox from "../../../../../components/Formik/Checkbox";
import Box from "@mui/material/Box";
import {run} from "../../../../../utils/services";
import NumberField from "../../../../../components/Formik/NumberField";
import BackButton from "../../../../../components/BackButton";

const PebblesPage = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const [data, setData] = useState({
        year: currentYear,
        code: '',
        startScale: '',
        endScale: '',
        label: '',
        amount: '',
        defaultValue: true,
        alertTriggered: false,

    });
    const [loading, setLoading] = useState(true);
    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        (async () => {
            if (!isCreation) {
                run(strawService.getStrawPebbles, id).then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        })();
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(strawService.removeStrawPebbles, {id});
        }
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(strawService.addStrawPebbles, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(strawService.editStrawPebbles, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={6} item>
                                <NumberField label={'Année'} name={'year'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'code'} name={'Code'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Libellé'} name={'label'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField label={'De'} name={'startScale'} asString/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField label={'À'} name={'endScale'} asString/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField label={'Montant'} name={'amount'} asString/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Checkbox name={'alertTriggered'} label={'Alerte notification'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Checkbox name={'defaultValue'} label={'Valeur défaut'}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du barème" handleDelete={handleDelete}/>}
                </Box>
            </>
    )

}

export default PebblesPage;