import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class DepartmentService {
  async getAllDepartments(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.DEPARTMENT + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getDepartment(id) {
    return axios
      .get(`${ENDPOINTS.DEPARTMENT}/${id}`)
      .then(handleResponse);
  }

  async addDepartment(parameters) {
    return axios
      .post(ENDPOINTS.DEPARTMENT, parameters)
      .then(handleResponse);
  }

  async editDepartment(parameters) {
    return axios
      .put(`${ENDPOINTS.DEPARTMENT}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeDepartment(parameters) {
    return axios
      .delete(`${ENDPOINTS.DEPARTMENT}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const departmentService = new DepartmentService();
