import {memberService} from "../../services/member.service";
import AutocompleteAsync from "../Mui/AutocompleteAsync";
import {useCallback, useState} from "react";
import {useFilterContext} from "../../context/FilterContext";
import _ from "lodash";
import {hydraService} from "../../utils/hydra";

const CompanyFilter = ({filters = {}, setFilters = newFilters => null, ...otherProps}) => {
    const {setFilterHash} = useFilterContext();
    const [value, setValue] = useState(null);
    const handlerCallback = useCallback(
        (e, value) => {
            const newFilters = value ? {...filters, ...(value.code && {'code': value.code})} : _.omit(filters, 'code');
            setFilters(newFilters);
            setFilterHash(newFilters);
        },
        [setFilters, filters]
    );

    if ('code' in filters && (!value || value.code !== filters.code)) {
        (async() => {
            const valueSelected = await memberService.getAllMembers({'code': filters.code});
            setValue(hydraService.getMembers(valueSelected.responseData)[0] ?? null);
        })();
    }

    if (value && !('code' in filters)) {
        setValue(null);
    }


    return (
        <AutocompleteAsync
            name={'code'}
            label={'Adhérent...'}
            filterName={'company'}
            request={memberService.getAllMembers}
            callback={handlerCallback}
            getOptionLabel={(option) => `${option.company} ${option.code}`}
            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
            value={value}
            callbackOnClear={() => setValue(null)}
        />
    );
}

export default CompanyFilter;