import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Grid, MenuItem, Stack, Typography} from "@mui/material";
import TextField from "../../../../../components/Formik/TextField";
import Button from "../../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../../constants/form";
import {useEffect, useState} from "react";
import {hydraService} from "../../../../../utils/hydra";
import Loader from "../../../../Loader";
import snackBar from "../../../../../components/SnackBar";
import {getViolations} from "../../../../../utils/axios";
import ModalDelete from "../../ModalDelete";
import {strawService} from "../../../../../services/straw.service";
import Select from "../../../../../components/Formik/Select";
import {articleService} from "../../../../../services/article.service";
import Box from "@mui/material/Box";
import {run} from "../../../../../utils/services";
import {ARTICLE_TYPE_CODES} from "../../../../../constants/types";
import NumberField from "../../../../../components/Formik/NumberField";
import BackButton from "../../../../../components/BackButton";

const ColorManufacturingPage = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const isCreation = !id;
    const currentYear = new Date().getFullYear();
    const [data, setData] = useState({
        year: currentYear,
        article: null,
        code: '',
        label: '',
        plcdCode: '',
        amount: '',
        orderBy: 1,

    });
    const [catalog, setCatalog] = useState([]);
    const [loading, setLoading] = useState(!!id);
    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
        ]).then((values) => {
            let [articles] = values.map(el => el.responseData).map(hydraService.getMembers);
            setCatalog({articles});
            if (!isCreation) {
                run(strawService.getStrawColorManufacturing, id).then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                })
            }
        });
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(strawService.removeStrawColorManufacturings, {id});
        }
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(strawService.addStrawColorManufacturings, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(strawService.editStrawColorManufacturings, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={6} item>
                                <NumberField label={'Année'} name={'year'} nullIfEmpty/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={'Code'} name={'code'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Libellé'} name={'label'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <Select name={'article'} label={"Article"} nullIfEmpty>
                                    {catalog.articles?.map((el, i) => <MenuItem key={i}
                                                                                value={el['@id']}>{el.label}</MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <TextField label={"Code PLCD"} name={'plcdCode'}/>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <NumberField label={"Montant"} name={'amount'} asString/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <NumberField label={"Ordre d'affichage"} name={'orderBy'} decimal={0}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <Box>
                    {!isCreation && <ModalDelete title="du barème" handleDelete={handleDelete} disabled={data.ticketCounter > 0}/>}
                    {data.ticketCounter > 0 && <Typography><em>(Suppression impossible : {data.ticketCounter} tickets associés)</em></Typography>}
                </Box>
            </>
    )

}

export default ColorManufacturingPage;