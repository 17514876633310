import {Autocomplete, TextField} from "@mui/material";
import {useCallback, useEffect, useRef, useState} from "react";
import {hydraService} from "../../../utils/hydra";
import _ from "lodash";
import {useField, useFormikContext} from "formik";

/**
 * This component is based on Autocomplete Component of MUI
 * You can add all props defined for this component.
 *
 * Request props is required.
 * If hydra props is added, then only the @id property will be saved in formik
 *
 * @param string name
 * @param string|null label
 * @param string|null filterName
 * @param url request
 * @param func getOptionLabel
 * @param func isOptionEqualToValue
 * @param mixed value
 * @param bool hydra
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
const AutocompleteAsyncWrapper = ({
                                      name,
                                      label,
                                      filterName,
                                      request,
                                      getOptionLabel,
                                      isOptionEqualToValue,
                                      value,
                                      hydra,
                                      withValue,
                                      callback,
                                      filtersOnLoading = null,
                                      nullIfEmpty = false,
                                      ...otherProps
                                  }) => {
    if (!request) {
        throw new Error(`"request" must be a string et not be empty.`)
    }

    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const [filter, setFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(value);

    const [open, setOpen] = useState(false);
    const loading = open && filtersOnLoading && options.length === 0;

    const handleChange = useCallback((e, value) => {
        setFilter('');
        setSelected(value);
        setFieldValue(name, isHydra(value));
        if (callback) {
            callback(value);
        }
    }, [field.value]);

    const isHydra = (value) => {
        if (hydra) {
            return value && '@id' in value ? value['@id'] : null;
        }
        return !nullIfEmpty && value ? value : null;
    }

    const configAutocompleteAsync = {
        fullWidth: true,
        autoComplete: true,
        includeInputInList: true,
        filterSelectedOptions: true,
        filterOptions: (x) => x,
        onChange: handleChange,
        ...(getOptionLabel && {getOptionLabel: getOptionLabel}),
        ...(isOptionEqualToValue && {isOptionEqualToValue: isOptionEqualToValue}),
        ...otherProps
    }

    const firstLoad = useRef(true);
    useEffect(() => {
        let active = true;
        let timeOutId = null;
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
        if (!filter) {
            return;
        }

        timeOutId = setTimeout(() => {
            request({[filterName ?? name]: filter})
                .then((response) => {
                    if (active) {
                        let newOptions = [];
                        if (selected) {
                            newOptions = [selected]; // Important for previous value
                        }
                        const members = hydraService.getMembers(response.responseData);
                        setOptions(newOptions.concat(members));
                    }
                });
        }, 600);

        return () => {
            clearTimeout(timeOutId);
            active = false;
        };
    }, [filter]);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            request(filtersOnLoading)
                .then((response) => {
                    if (active) {
                        let members = hydraService.getMembers(response.responseData);
                        if ('reverse' in filtersOnLoading && !!filtersOnLoading.reverse) {
                            members = members.reverse();
                        }
                        setOptions(members);
                    }
                });
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    const configTextField = {
        ...field,
        ...otherProps,
        variant: 'outlined',
        placeholder: "Rechercher..."
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    const handleClose = useCallback((e, reason) => {
        setOpen(false);
        const {value} = e.target;
        if (reason === 'blur' && (options.length === 0 || !value)) {
            setFieldValue(name, nullIfEmpty ? null : value);
        }
    }, [setFieldValue, setOpen, options],);

    return (
        <Autocomplete
            name={name}
            value={selected || value}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={handleClose}
            options={options}
            onInputChange={(e, value, reason) => {
                setFilter(_.trim(value));
                if (reason === 'clear') {
                    setOptions([]);
                }
            }}
            renderInput={(params) => <TextField {...params} {...configTextField} label={label}/>}
            loading={true}
            loadingText={'Recherche en cours...'}
            noOptionsText="Pas de résultats"
            {...configAutocompleteAsync}
        />
    );
}

export default AutocompleteAsyncWrapper;