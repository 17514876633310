import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class CapitalService {
  async getAllCapitals(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.CAPITAL + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getCapital(id) {
    return axios
      .get(`${ENDPOINTS.CAPITAL}/${id}`)
      .then(handleResponse);
  }

  async addCapital(parameters) {
    return axios
      .post(ENDPOINTS.CAPITAL, parameters)
      .then(handleResponse);
  }

async editCapital(parameters) {
    return axios
      .put(`${ENDPOINTS.CAPITAL}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeCapital(parameters) {
    return axios
      .delete(`${ENDPOINTS.CAPITAL}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const capitalService = new CapitalService();
