import {TextField} from "@mui/material";
import {convertDate} from "../../utils/moment";
import {useCallback} from "react";
import _ from "lodash";

const DateFilter = ({setFilters, ...otherProps}) => {

    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setFilters(prevState => (value ? {...prevState, [name]: convertDate(value)} : _.omit(prevState, [name])));
    }, [setFilters]);

    const configDateField = {
        type: 'date',
        fullWidth: true,
        variant: 'outlined',
        onChange: handleChange,
        InputLabelProps: {
            shrink: true
        },
        ...otherProps
    }

    return <TextField {...configDateField}/>
}

export default DateFilter;