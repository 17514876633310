import {useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";

const ModalDelete = ({handleDelete = () => null, title, ...otherProps}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "700px",
        maxHeight: "85vh",
        minHeight: '20vh',
        backgroundColor: '#FFF',
        boxShadow: 24,
    };

    const configButton = {
        variant: 'contained',
        color: 'error',
        onClick: handleOpen,
        sx: otherProps.sx ?? {marginTop: "20px"},
        startIcon: otherProps.startIcon ?? <DeleteIcon/>,
        ...otherProps
    }

    return (
        <div>
            <Button {...configButton}>Supprimer</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style}}>
                    <Box className="headerModal">
                        <Box className="titleModal">Suppression {title}</Box>
                    </Box>
                    <Box className="contentModal">
                        <p>Confirmez-vous la suppression {title} ?</p>
                    </Box>
                    <Box className="footerModal">
                        <Button className="buttonDelete" onClick={handleDelete}>Oui</Button>
                        <Button sx={{marginTop: 0, marginLeft: "10px"}} onClick={handleClose}>Non</Button>
                    </Box>
                </Box>
            </Modal>
        </div>

    )
}

export default ModalDelete