import {useMatches, Link as RouterLink} from "react-router-dom";
import {Box, Breadcrumbs, Link} from "@mui/material";

const BreadCrumbsWrapper = () => {
    let matches = useMatches();
    let crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.name))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match, index) => <Link component={RouterLink} key={index} to={match.pathname}>{match.handle?.name}</Link>);
    //crumbs.shift();
    return (
        <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link component={RouterLink} to={'/'}>Accueil</Link>
                {crumbs}
            </Breadcrumbs>
        </Box>
    );
}

export default BreadCrumbsWrapper;

