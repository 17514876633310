import _ from "lodash";

function toggle(collection, item) {
    const idx = _.indexOf(collection, item);
    if (idx !== -1) {
        collection.splice(idx, 1);
    } else {
        collection.push(item);
    }
    return collection;
}

_.mixin({'toggle': toggle});

export default _;