import {useFormikContext} from "formik";

const RefusedMessage = ({catalog, name = 'fabrication'}) => {
    const {values} = useFormikContext();

    const humidity = catalog.humidities.find(el => el['@id'] === values[name].humidity);

    return humidity?.refused && <p style={{
        color: '#FFF',
        backgroundColor: '#d91e1e',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '5px'

    }}>REFUS PAILLE</p>
}

export default RefusedMessage;