import {useEffect, useState} from "react";
import {FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {Grid, MenuItem, Stack} from "@mui/material";
import DateNative from "../../../components/Formik/Date/Native";
import TextField from "../../../components/Formik/TextField";
import {useNavigate, useParams} from "react-router-dom";
import {memberService} from "../../../services/member.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import {ticketService} from "../../../services/ticket.service";
import snackBar from "../../../components/SnackBar";
import Loader from "../../Loader";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {ROLES} from "../../../constants/roles";
import SelectYear from "../../../components/Formik/SelectYear";
import FormDisabler from "../../../components/Formik/ThemeFormikDisablerProvider";
import {run} from "../../../utils/services";
import {articleTypeService} from "../../../services/articleType.service";
import {Checkbox} from "../../../components/Formik";
import Select from "../../../components/Formik/Select";
import ArticleTable from "./ArticleTable";
import _ from "lodash";
import {exportService} from "../../../services/export.service";
import NavigationButtons from "../NavigationButtons";
import {useUserContext} from "../../../context/UserContext";

const SupplyPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const navigate = useNavigate();
    const INITIAL_FORM_STATE = {
        ticketDate: new Date(),
        pickupDate: null,
        deposit: null,
        member: null,
        harvestYear: new Date().getFullYear(),
        articleType: null,
        isVisible: false,
        blocked: false,
        articles: [],
    };

    const FORM_VALIDATION = Yup.object().shape({});

    const [loading, setLoading] = useState(true);
    const [catalog, setCatalog] = useState({
        'members': [],
        'articles': [],
        'articleTypeList': []
    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [memberSelected, setMemberSelected] = useState(null);

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false}),
            run(articleTypeService.getAllArticleTypes, {pagination: false, 'order[label]': 'ASC'})
        ]).then((values) => {
            const [articles, articleTypeList] = values.map((value) => hydraService.getMembers(value.responseData));
            setCatalog(prev => ({...prev, articles, articleTypeList}));

            if (!!id) {
                run(ticketService.getSupplyTicket, id)
                    .then((response) => {
                        setData(response.responseData);
                        run(memberService.getMember, hydraService.getIdFromIri(response.responseData.member))
                            .then((response) => {
                                setMemberSelected(response.responseData);
                                setLoading(false);
                            });
                    })
            } else {
                setLoading(false);
            }
        });
        return () => null;
    }, [id]);

    const purgeArticleId = (articles) => {
        return articles.map((el, i) => {
            if (isNaN(el.id)) {
                return _.omit(el, 'id');
            }
        })
    }

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setFieldValue, setSubmitting, setValues}) => {
                        const payload = {...values, articles: purgeArticleId(values.articles)};
                        if (!id) {
                            run(ticketService.addSupplyTicket, payload, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                    setValues(response.responseData);
                                    navigate(response.responseData['@id']);
                                });
                        } else {
                            run(ticketService.editSupplyTicket, {id, ...values}, {setFieldError, setSubmitting})
                                .then((response) => {
                                    setValues(response.responseData);
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    {({values}) => (
                        <Form>
                            <Grid container spacing={3} className="Grid Grid-root">
                                <Grid item xs={6} className="Grid">
                                </Grid>
                                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                                    <NavigationButtons exportRequest={exportService.exportSupplyTicket}
                                                       removeRequest={ticketService.removeSupplyTicket}/>
                                </Grid>
                            </Grid>
                            <FormDisabler disabled={data.blocked || isGranted(ROLES.MEMBER)}>
                                <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 3}}
                                      className="Grid-container">
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name={'number'}
                                            label={'N° BL'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateNative name={'ticketDate'} label={'Date'} nullIfEmpty/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SelectYear
                                            name={'harvestYear'}
                                            label={'Année'}
                                            end={new Date().getFullYear()}
                                            reverse
                                            nullIfEmpty
                                            disabled={!isGranted(ROLES.ADMIN)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'member'}
                                            label={'Adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            value={memberSelected}
                                            filtersOnLoading={{'order[company]': 'ASC'}}
                                            hydra
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name={'deposit'}
                                            label={'Dépôt'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Select name={'articleType'}
                                                label={'Type d\'article'}
                                                disabled={values.articles.length > 0}
                                        >

                                            <MenuItem
                                                value={null}
                                                >
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {catalog.articleTypeList.map((el, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={el['@id']}
                                                    >
                                                    {el.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                        <Grid item xs={12} md={2}>
                                            <Checkbox
                                                name={'isVisible'}
                                                label={'Rendre visible'}
                                            />
                                        </Grid>
                                    </ProtectedComponent>
                                    {values.isVisible &&
                                        <Grid item xs={12} md={4}>
                                            <DateNative name={'pickupDate'} label={'Date d\'enlèvement'} required nullIfEmpty/>
                                        </Grid>
                                    }
                                </Grid>
                                <FieldArray name={'articles'}
                                            render={props => <ArticleTable {...props} articles={catalog.articles}/>}/>
                            </FormDisabler>
                        </Form>)}
                </Formik>
            </>
    );
}

export default SupplyPage;