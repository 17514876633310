import {Link} from "react-router-dom";
import {Button as MuiButton} from "@mui/material";

const RedirectButtonWrapper = ({to, children, ...otherProps}) => {
    const configRedirectButton = {
        component: Link,
        to,
        ...otherProps,
        variant: otherProps.variant ?? 'contained',
        color: otherProps.color ?? 'primary',
    }
    return (
        <MuiButton {...configRedirectButton}>
            {children ?? 'Fermer'}
        </MuiButton>
    );
}

export default RedirectButtonWrapper;