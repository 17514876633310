import {useState} from "react";
import {
    Box,
    Container,
    Grid,
    Toolbar
} from "@mui/material";
import Header from "./Header";
import DrawerLayout from "./Drawer";
import {matchRoutes, Outlet, useLocation} from "react-router-dom";
import Breadcrumbs from "../components/Mui/Breadcrumbs";
import {useUserContext} from "../context/UserContext";
import {routes} from "../utils/routes";
import {FilterContextProvider} from "../context/FilterContext";

const MainLayout = () => {
    const {RequireAuth} = useUserContext();
    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <RequireAuth roles={matchRoutes(routes, useLocation()).pop().route.handle.roles}>
            <Box sx={{display: 'flex'}}>
                <Header open={open} toggleDrawer={toggleDrawer}/>
                <DrawerLayout open={open} toggleDrawer={toggleDrawer}/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Breadcrumbs/>
                    <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                        <Grid>
                            <FilterContextProvider>
                                <Outlet/>
                            </FilterContextProvider>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </RequireAuth>
    )
}

export default MainLayout