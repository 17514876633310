import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ChenevisService {
  async getAllChenevis(parameters = {}) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.CHENEVIS + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getChenevis(id) {
    return axios
      .get(`${ENDPOINTS.CHENEVIS}/${id}`)
      .then(handleResponse);
  }

  async addChenevis(parameters) {
    return axios
      .post(ENDPOINTS.CHENEVIS, parameters)
      .then(handleResponse);
  }

  async editChenevis(parameters) {
    return axios
      .put(`${ENDPOINTS.CHENEVIS}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeChenevis(parameters) {
    return axios
      .delete(`${ENDPOINTS.CHENEVIS}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const chenevisService = new ChenevisService();
