import {Button as MuiButton, Grid, Stack, Typography} from "@mui/material";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import PrintIcon from "@mui/icons-material/Print";
import Download from "../../components/Download";
import Button from "../../components/Formik/SubmitButton";
import BackButton from "../../components/BackButton";

const NavigationButtons = ({data}) => {
    return (
        <Grid container spacing={3} className="Grid Grid-root">
            <Grid item xs={6} className="Grid">
            </Grid>
            <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    {data.file &&
                        <MuiButton variant={'outlined'}
                                   startIcon={<PrintIcon/>}
                                   onClick={() => Download(data.file.hostFilename)}
                        >Imprimer</MuiButton>}
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                        <Button variant={'outlined'} disabled={data.isBlocked}/>
                    </ProtectedComponent>
                    <BackButton/>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default NavigationButtons;