import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class CapitalHistoriesService {
  async getAllCapitalHistories(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
      .get(ENDPOINTS.CAPITAL_HISTORIES + (queryString ? `?${queryString}` : ''))
      .then(handleResponse);
  }

  async getCapitalHistory(id) {
    return axios
      .get(`${ENDPOINTS.CAPITAL_HISTORIES}/${id}`)
      .then(handleResponse);
  }

  async addCapitalHistory(parameters) {
    return axios
      .post(ENDPOINTS.CAPITAL_HISTORIES, parameters)
      .then(handleResponse);
  }

async editCapitalHistory(parameters) {
    return axios
      .put(`${ENDPOINTS.CAPITAL_HISTORIES}/${parameters.id}`, parameters)
      .then(handleResponse);
  }

  async removeCapitalHistory(parameters) {
    return axios
      .delete(`${ENDPOINTS.CAPITAL_HISTORIES}/${parameters.id}`)
      .then(handleResponse);
  }
}

export const capitalHistoriesService = new CapitalHistoriesService();
