import {useDialog} from "../../../../feature/DialogProvider";
import {Form, Formik} from "formik";
import {importService} from "../../../../services/import.service";
import snackBar from "../../../../components/SnackBar";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import MuiTableAsync from "../../../../components/Mui/DataGridAsync";
import _ from "lodash";
import SubmitButton from "../../../../components/Formik/SubmitButton";
import {useMemo} from "react";
import {toLocaleDate} from "../../../../utils/i18n";
import {run} from "../../../../utils/services";

const DataGridAlpix = ({setFilters}) => {

    const INITIAL_FORM_STATE = {
        generateMail: false
    }

    const [, closeDialog] = useDialog();

    const columns = useMemo(() => (
            [
                {
                    field: 'number',
                    headerName: 'N°',
                    flex: 1
                },
                {
                    field: 'loadingDate',
                    headerName: 'Date de chargement',
                    flex: 1,
                    valueFormatter: (params) => toLocaleDate(params.value)
                },
                {
                    field: 'company',
                    headerName: 'Adhérent',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'driver',
                    headerName: 'Chauffeur',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'exitWeight',
                    headerName: 'Tare',
                    flex: 1,
                    valueFormatter: (params) => _.numberFormat(params.value)
                },
                {
                    field: 'net',
                    headerName: 'Net/livré',
                    flex: 1,
                    valueFormatter: (params) => _.numberFormat(params.value)
                },
                {
                    field: 'entryWeight',
                    headerName: 'Brut',
                    flex: 1,
                    valueFormatter: (params) => _.numberFormat(params.value)
                },
            ])
        , []);

    return (
        <>
            <Formik
                initialValues={INITIAL_FORM_STATE}
                onSubmit={(values, {setSubmitting}) => {
                    run(importService.importAlpix, values, {setSubmitting})
                        .then((response)=>{
                            snackBar('Import Alpix effectué');
                            setFilters(prevState => ({...prevState}));
                            closeDialog();
                        });
                }}
            >
                <Form>
                    <DialogTitle className={'headerModal'}>
                        Import Alpix (bascule)
                    </DialogTitle>
                    <DialogContent className={'contentModal'}>
                        <MuiTableAsync
                            columns={columns}
                            request={importService.readAlpix}
                            customPagination
                            getRowId={() => {
                                return _.uniqueId();
                            }}
                        />
                    </DialogContent>
                    <DialogActions className={'footerModal'}>
                        <SubmitButton>Importer</SubmitButton>
                        <Button onClick={closeDialog}>Annuler</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </>
    );
}

export default DataGridAlpix;

/**
 * exists[transportFees]=true
 */