import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class CloneReferentielService {
    async cloneReferentiel(parameters) {
        return axios
            .post("/duplicate/scale", parameters)
            .then(handleResponse)
    }
}

export const cloneReferentielService = new CloneReferentielService();
