import CssBaseline from "@mui/material/CssBaseline";
import {Form, Formik} from "formik";
import {Container, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import Logo from "../../../assets/images/logo_chanvrier.png";
import TextField from "../../../components/Formik/TextField";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {authentificationService} from "../../../services/authentification.service";
import {getErrors} from "../../../utils/axios";
import snackBar from "../../../components/SnackBar";
import RedirectButton from "../../../components/Mui/RedirectButton";
import {useNavigate} from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();

    const INITIAL_FORM_STATE = {
        email: ''
    }
    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email('Email invalide.').required('Champ obligatoire'),
    });

    const styleTitle = {
        margin: "20px auto",
        color: "#464a53",
        textAlign: "center",
        fontSize: "16px"
    }

    return (
        <Formik
            validateOnBlur={false}
            initialValues={{...INITIAL_FORM_STATE}}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                authentificationService.forgottenPassword(values)
                    .then(()=>{
                        snackBar('E-mail envoyé.');
                        navigate("/login");
                    })
                    .catch((axiosError) => {
                        const error = getErrors(axiosError)
                        snackBar(`Erreur : ${error}`, 'error');
                    });
            }}
        >
            <Form className={'login'}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{
                            maxWidth: "270px",
                            margin: "auto",
                        }}>
                            <img style={{width: '100%'}} src={Logo} alt={"La chanvrière"} />
                        </Box>
                        <Typography component="h1" variant="h2" sx={styleTitle}>
                            Saisir votre adresse e-mail
                        </Typography>
                        <Box noValidate sx={{width: "100%"}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <SubmitButton fullWidth>
                                Envoyer
                            </SubmitButton>
                            <RedirectButton fullWidth to={'/login'} variant={'text'}>Retour</RedirectButton>
                        </Box>
                    </Box>
                </Container>
            </Form>
        </Formik>
    );
}

export default ResetPassword;