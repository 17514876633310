import {useState} from "react";
import {useParams} from "react-router-dom";
import DetailPage from "./DetailPage";
import {Box, Button as MuiButton, Grid, Stack, Tab} from "@mui/material";
import {TabContext, TabPanel, TabList} from "@mui/lab";
import ContactPage from "./ContactPage";
import AreaPage from "./AreaPage";
import CapitalPage from "./CapitalPage";
import FormExport from "./FormExport";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import {useDialog} from "../../feature/DialogProvider";
import BackButton from "../../components/BackButton";

const MemberPage = () => {
    const {id} = useParams();
    const isCreation = !id;
    const [openDialog] = useDialog();

    const [value, setValue] = useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={6} className="Grid">
                </Grid>
                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                    <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                        <Stack spacing={1} direction={'row'}>
                            <MuiButton onClick={() => openDialog({
                                children: <FormExport title={'Exporter un/des adhérent(s)'}/>
                            })}>
                                Edition
                            </MuiButton>
                            <BackButton/>
                        </Stack>
                    </ProtectedComponent>
                </Grid>
            </Grid>
            {isCreation && <DetailPage/>}
            {!isCreation &&
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabContext value={value}>
                        <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Détails" value={'0'}/>
                            <Tab label="Contact" value={'1'}/>
                            <Tab label="Distances" value={'2'}/>
                            <Tab label="Capital" value={'3'}/>
                        </TabList>
                        <TabPanel value={'0'}>
                            <DetailPage/>
                        </TabPanel>
                        <TabPanel value={'1'}>
                            <ContactPage/>
                        </TabPanel>
                        <TabPanel value={'2'}>
                            <AreaPage/>
                        </TabPanel>
                        <TabPanel value={'3'}>
                            <CapitalPage/>
                        </TabPanel>
                    </TabContext>
                </Box>}
        </>
    );
}

export default MemberPage;