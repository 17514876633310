import { Container, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { memberService } from "../../services/member.service";
import { hydraService } from "../../utils/hydra";

const MemberDetailHead = ({ memberId, member }) => {
    const [memberDetail, setMemberDetail] = useState(undefined);

    const memberMemo = useMemo(()=>{
        return member || memberDetail;
    }, [member, memberDetail]);

    useEffect(() => {
        if (memberId && !member) {
            memberService.getMember(hydraService.getIdFromIri(memberId))
                .then((response) => {
                    const member = response.responseData;
                    setMemberDetail(member);
                });
        }
    }, [memberId, member]);

    return (
        <>
            {memberMemo && (
                <Container sx={{
                    paddingTop: '15px',
                    margin: 0
                }} className="container_header_member_infos">
                    <p>
                        {`${memberMemo['title']} ${memberMemo['particle'] ? '/ '+memberMemo['particle'] : ''} `}
                        <span className="typography-bold">{`${memberMemo['company'] ? '/ '+memberMemo['company'] : ''}`}</span></p>
                    <Typography marginTop={1}>{`${memberMemo['address']} - ${memberMemo['postcode']} ${memberMemo['city']}`}</Typography>
                </Container>
            )}
        </>
    );
}

export default MemberDetailHead;