import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ChevenisHumidityService {
    async getAllChevenisHumidities(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.CHENEVIS_HUMIDITY + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getChevenisHumidity(id) {
        return axios
            .get(`${ENDPOINTS.CHENEVIS_HUMIDITY}/${id}`)
            .then(handleResponse);
    }

    async addChevenisHumidity(parameters) {
        return axios
            .post(ENDPOINTS.CHENEVIS_HUMIDITY, parameters)
            .then(handleResponse);
    }

    async editChevenisHumidity(parameters) {
        return axios
            .put(`${ENDPOINTS.CHENEVIS_HUMIDITY}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeChevenisHumidity(parameters) {
        return axios
            .delete(`${ENDPOINTS.CHENEVIS_HUMIDITY}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const chenevisHumidityService = new ChevenisHumidityService();
