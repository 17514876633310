import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import {PARAMETERS} from "../../../../constants/parameters";
import {Form, Formik} from "formik";
import {Box, Grid, MenuItem, Stack} from "@mui/material";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import {useEffect, useState} from "react";
import {vatCodeService} from "../../../../services/vatCode.service";
import Loader from "../../../Loader";
import snackBar from "../../../../components/SnackBar";
import ModalDelete from "../ModalDelete";
import {hydraService} from "../../../../utils/hydra";
import {articleTypeService} from "../../../../services/articleType.service";
import Checkbox from "../../../../components/Formik/Checkbox";
import Select from "../../../../components/Formik/Select";
import {articleService} from "../../../../services/article.service";
import TableTypeArticle from "./TableTypeArticle";
import {articleYearService} from "../../../../services/articleYear.service";
import {ARTICLE_TYPE_CODES, TYPE} from "../../../../constants/types";
import {run} from "../../../../utils/services";
import BackButton from "../../../../components/BackButton";

const ArticlePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [isLoading, setIsLoading] = useState(true);
    const [catalog, setCatalog] = useState([]);
    const [data, setData] = useState({
        code: '',
        type: null,
        label: '',
        vat: '',
        sagePurchaseAccount: '',
        section: '',
        accountingJournal: '',
        status: true,
        articlePrices: [],

    });

    const [rows, setRows] = useState([]);

    const FORM_VALIDATION = Yup.object().shape({});

    useEffect(() => {
        Promise.all([
            run(articleTypeService.getAllArticleTypes, {pagination: false}),
            run(vatCodeService.getAllVatCodes, {pagination: false}),
            run(articleYearService.getAllArticlesYear, {pagination: false})
        ]).then((values) => {
            let [articleTypes, vatCodes, articleYears,] = values.map(el => el.responseData).map(hydraService.getMembers);
            setCatalog({articleTypes, vatCodes});
            if (!isCreation) {
                run(articleService.getArticle, id).then((response) => {
                    setData(response.responseData);
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
            }
            const allConditionsPayements = articleYears.filter((v) => v.article['@id'] === `/articles/${id}`);
            if (articleTypes.type !== TYPE.SEED && articleTypes.type !== TYPE.STRAW) {
                setRows(allConditionsPayements.map(el => ({...el, id: el['@id']})));
            }
        });
        return () => {
            setRows([]);
        }
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (rows.length === 0) {
            run(articleService.removeArticle, id)
                .then(() => {
                    navigate(PARAMETERS.BACK_ROUTE);
                });
        } else {
            snackBar("Vous devez supprimer les conditions de paiement avant de pouvoir supprimer l'article", 'error');
        }

    }
    return (
        isLoading ? <Loader/> : <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (!id) {
                        run(articleService.addArticle, values, {setFieldError, setSubmitting})
                            .then((response) => {
                                snackBar('Enregistrement effectué');
                                if (response.responseData.type.split('/').slice(-1) === ARTICLE_TYPE_CODES.APPROVISIONNEMENT) {
                                    navigate('/parameters/toolkits' + response.responseData['@id']);
                                    return;
                                }
                                navigate(PARAMETERS.BACK_ROUTE);
                            });
                    } else {
                        const id = hydraService.getIdFromIri(values);
                        run(articleService.editArticle, {id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar('Enregistrement effectué');
                            });
                    }
                }}
            >
                <Form>
                    <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                            <BackButton/>
                        </Stack>
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                        <Grid xs={12} md={4} item>
                            <TextField label={'Code'} name={'code'} placeholder={"ART001"}/>
                        </Grid>
                        <Grid xs={12} md={8} item>
                            <TextField label={"Nom de l'article"} name={'label'}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Select name={'type'} label={"Type d'article"} nullIfEmpty>
                                <MenuItem value={''}><em>Aucun</em></MenuItem>
                                {catalog.articleTypes
                                    .sort((a, b) => a.label.localeCompare(b.label))
                                    .map((el, i) => <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Checkbox name={'status'} label={'Actif ?'}/>
                        </Grid>

                        <Grid xs={12} md={12} item>
                            {id && false === [TYPE.STRAW, TYPE.SEED].includes(data.type) && <TableTypeArticle catalog={catalog} rows={rows} setRows={setRows}/>}
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
            <Box>
                {!isCreation && <ModalDelete title="de l'article" handleDelete={handleDelete}/>}
            </Box>
        </>
    )

}

export default ArticlePage