import {ENDPOINTS} from "../constants/endpoints";
import axios, {getErrors} from "../utils/axios";
import {handleResponse} from "../utils/services";
import snackBar from "../components/SnackBar";

class ImportService {
    async readPlcd(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.IMPORT_PLCD_READ + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async importPlcd(parameters) {
        return axios
            .post(ENDPOINTS.IMPORT_PLCD, parameters)
            .then(handleResponse)
    }

    async readVivescia(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.IMPORT_VIVESCIA_READ + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async importVivescia(parameters) {
        return axios
            .post(ENDPOINTS.IMPORT_VIVESCIA, parameters)
            .then(handleResponse)
    }

    async readAlpix(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.IMPORT_ALPIX_READ + (queryString ? `?${queryString}` : ''))
            .then(handleResponse)
            .catch((error) => {
                const errorMessage = getErrors(error);
                snackBar(errorMessage, 'error', {
                    anchorOrigin: {horizontal: 'center', vertical: 'top'}
                })
            })
    }

    async importAlpix(parameters) {
        return axios
            .post(ENDPOINTS.IMPORT_ALPIX, parameters)
            .then(handleResponse)
    }

    async importTablet(parameters) {
        return axios
            .post(ENDPOINTS.IMPORT_TABLET, parameters)
            .then(handleResponse)
    }

    async importFile(parameters) {
        return axios
            .post(ENDPOINTS.IMPORT_FILE, parameters)
            .then(handleResponse)
    }
}

export const importService = new ImportService();
