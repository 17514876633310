import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {siloService} from "../../../../services/silo.service";
import {PARAMETERS} from "../../../../constants/parameters";
import * as Yup from "yup";
import Loader from "../../../Loader";
import ReturnButton from "../../../../components/Mui/RedirectButton";
import {Form, Formik} from "formik";
import snackBar from "../../../../components/SnackBar";
import {getViolations} from "../../../../utils/axios";
import {hydraService} from "../../../../utils/hydra";
import {Grid, MenuItem, Stack} from "@mui/material";
import TextField from "../../../../components/Formik/TextField";
import Button from "../../../../components/Formik/SubmitButton";
import {FORM} from "../../../../constants/form";
import ModalDelete from "../ModalDelete";
import Select from "../../../../components/Formik/Select";
import Box from "@mui/material/Box";
import {run} from "../../../../utils/services";
import NumberField from "../../../../components/Formik/NumberField";
import BackButton from "../../../../components/BackButton";

const SilosPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const isCreation = !id;
    const [loading, setLoading] = useState(!!id)
    const [data, setData] = useState({
        code: null,
        name: "",
        address: "",
        additionalAddress: "",
        postcode: "",
        city: "",
        contact: "",
        phone: "",
        email: "",
        type: null,
        attachmentSite: "",
        status: true
    });
    const [catalog, setCatalog] = useState({
        siloTypes: []
    });

    useEffect(() => {
        Promise.all([
            run(siloService.getAllSiloTypes)
        ]).then((response) => {
            const [siloTypes] = response.map(el => el.responseData);
            setCatalog({siloTypes});
            if (!isCreation) {
                run(siloService.getSilo, id)
                    .then((response) => {
                        setData(response.responseData);
                        setLoading(false);
                    })
            }
        });
    }, []);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (!isCreation) {
            navigate(PARAMETERS.BACK_ROUTE);
            await run(siloService.removeSilo, {id});
        }
    }

    const FORM_VALIDATION = Yup.object().shape({});

    return (
        loading ? <Loader/> :
            <>
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        if (!id) {
                            run(siloService.addSilo, values, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                    navigate(PARAMETERS.BACK_ROUTE);
                                });
                        } else {
                            const id = hydraService.getIdFromIri(values);
                            run(siloService.editSilo, {id, ...values}, {setFieldError, setSubmitting})
                                .then(() => {
                                    snackBar('Enregistrement effectué');
                                });
                        }
                    }}
                >
                    <Form>
                        <Grid container item xs={12} justifyContent={'flex-end'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'}>{isCreation ? FORM.ADD : FORM.EDIT}</Button>
                                <BackButton/>
                            </Stack>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                            <Grid xs={12} md={4} item>
                                <NumberField label={'Code'} name={'code'} nullIfEmpty/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Nom du silo'} name={'name'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Adresse'} name={'address'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Adresse additionnelle'} name={'additionalAddress'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Code Postal'} name={'postcode'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Ville'} name={'city'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Nom du contact'} name={'contact'}/>
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <TextField label={'Téléphone'} name={'phone'}/>
                            </Grid>
                            <Grid xs={12} md={8} item>
                                <TextField label={'Email'} name={'email'}/>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <Select name={'type'} label={'Type de silo'} nullIfEmpty>
                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                    {catalog.siloTypes.map((el, i) => (
                                        <MenuItem key={i} value={el}>{el}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid xs={12} md={12} item>
                                <TextField label={'Site de rattachement'} name={'attachmentSite'}/>
                            </Grid>
                        </Grid>

                        {!isCreation && <ModalDelete title="du silo" handleDelete={handleDelete}/>}
                    </Form>
                </Formik>
                <Box>

                </Box>
            </>
    )
}

export default SilosPage