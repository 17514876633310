import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class ChevenisPaymentConditionService {
    async getAllChevenisPaymentConditions(parameters) {
        const queryString = (new URLSearchParams(parameters)).toString();
        return axios
            .get(ENDPOINTS.CHENEVIS_PAYMENT_CONDITIONS + (queryString ? `?${queryString}` : ''))
            .then(handleResponse);
    }

    async getChevenisPaymentCondition(id) {
        return axios
            .get(`${ENDPOINTS.CHENEVIS_PAYMENT_CONDITIONS}/${id}`)
            .then(handleResponse);
    }

    async addChevenisPaymentCondition(parameters) {
        return axios
            .post(ENDPOINTS.CHENEVIS_PAYMENT_CONDITIONS, parameters)
            .then(handleResponse);
    }

    async editChevenisPaymentCondition(parameters) {
        return axios
            .put(`${ENDPOINTS.CHENEVIS_PAYMENT_CONDITIONS}/${parameters.id}`, parameters)
            .then(handleResponse);
    }

    async removeChevenisPaymentCondition(parameters) {
        return axios
            .delete(`${ENDPOINTS.CHENEVIS_PAYMENT_CONDITIONS}/${parameters.id}`)
            .then(handleResponse);
    }
}

export const chenevisPaymentConditionService = new ChevenisPaymentConditionService();
