import _ from "lodash"
import snackBar from "../SnackBar";

const DownloadStream = (data, type = 'application/pdf', prefix = '') => {

    try {
        const file = new Blob(
            [data],
            {type});
        const fileURL = URL.createObjectURL(file);
        const basename = `${prefix}${_.last(fileURL.split('/'))}`;
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = basename;
        alink.click();
        alink.remove();
        URL.revokeObjectURL(fileURL);
    } catch (error) {
        snackBar('Une erreur est survenue. Merci de contacter votre administrateur.', 'error');
    }
}

export default DownloadStream;