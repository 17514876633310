import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button as MuiButton, Button, Grid, Stack} from "@mui/material";
import {useEffect, useState, useMemo} from "react";
import {ticketService} from "../../../services/ticket.service";
import {articleService} from "../../../services/article.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import FormExport from "./FormExport";
import {run} from "../../../utils/services";
import {hydraService} from "../../../utils/hydra";
import {useDialog} from "../../../feature/DialogProvider";
import {ARTICLE_TYPE_CODES} from "../../../constants/types";
import HarvestYearFilter from "../../../components/Filters/HarvestYearFilter";
import MemberFilter from "../../../components/Filters/MemberFilter";
import NumberInvoiceFilter from "../../../components/Filters/NumberInvoiceFilter";
import ArticleTypeFilter from "../../../components/Filters/ArticleTypeFilter";
import DepositFilter from "../../../components/Filters/DepositFilter";
import ImportMenu from "../../../feature/ImportMenu";
import {depositService} from "../../../services/deposit.service";
import {articleTypeService} from "../../../services/articleType.service";
import SupplyValidate from "./SupplyValidate";

const SupplyTickets = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isGranted, currentUser} = useUserContext();
    const [filters, setFilters] = useState({});
    const [catalog, setCatalog] = useState({
        'articles': [],
        'articleTypeList': [],
        'depositList': [],
    });


    const [openDialog] = useDialog();

    let columns = [
        {field: 'number', headerName: 'N° Bon', flex: 1},
        {field: 'harvestYear', headerName: 'Année', flex: 1},
        {field: 'member.code', headerName: 'Code', flex: 1, valueGetter: (params) => params.row.member?.code},
        {field: 'member.company', headerName: 'Nom', flex: 1, valueGetter: (params) => params.row.member?.company},
        {
            field: 'articleType.label',
            headerName: 'Type article',
            flex: 1,
            valueGetter: (params) => params.row.articleType?.label
        },
        {field: 'deposit', headerName: 'Dépôt', flex: 1},

    ];

    if (!isGranted(ROLES.MEMBER)) {
        columns = columns.concat([
            {
                field: 'isVisible',
                headerName: 'Visible ?',
                disableReorder: false,
                type: 'boolean',
                flex: 1
            }
        ]);
    }

    const importMenu = useMemo(() => {
        return [
            {title: 'Import extranet', type: "supply_tickets"},
        ]
    }, []);

    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.APPROVISIONNEMENT}),
            run(depositService.getAllDeposits, {pagination: false}),
            run(articleTypeService.getAllArticleTypes, {pagination: false, 'order[label]': 'ASC'})
        ]).then((values) => {
            const [articles, depositList, articleTypeList] = values.map((value) => hydraService.getMembers(value.responseData));
            setCatalog(prev => ({...prev, articles, depositList, articleTypeList}));
        });
    }, []);

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={8} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <HarvestYearFilter filters={filters} setFilters={setFilters}/>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MemberFilter filters={filters} setFilters={setFilters}/>
                        </ProtectedComponent>
                        <NumberInvoiceFilter filters={filters}
                                             setFilters={setFilters}
                                             request={ticketService.getAllSupplyTicket}
                        />
                        <ArticleTypeFilter filters={filters} setFilters={setFilters}
                                           articleTypeList={catalog.articleTypeList}/>
                        <DepositFilter filters={filters} setFilters={setFilters} depositList={catalog.depositList}/>
                    </Stack>
                </Grid>
                <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MuiButton onClick={() => openDialog({
                                fullWidth: true,
                                maxWidth: false,
                                children: <SupplyValidate catalog={catalog}
                                                          title={'Activation des tickets suivants avec date d\'enlèvement'}/>
                            })}>
                                Rendre visible les tickets
                            </MuiButton>
                            <ImportMenu data={importMenu} callback={() => {
                                setFilters({...filters});
                            }}/>
                        </ProtectedComponent>
                        <MuiButton onClick={() => openDialog({
                            fullWidth: true,
                            maxWidth: 'md',
                            children: <FormExport catalog={catalog}
                                                  isGranted={isGranted}
                                                  currentUser={currentUser}
                                                  title={'Exporter un/des ticket(s) approvisionnement'}/>
                        })}>
                            Edition
                        </MuiButton>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <Button component={Link} variant="contained" to={`${location.pathname}/add`} sx={{mb: 0}}>
                                Ajouter un ticket approvisionnement</Button>
                        </ProtectedComponent>
                    </Stack>
                </Grid>
            </Grid>
            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={ticketService.getAllSupplyTicket}
                filters={filters}
                customPagination
            />
        </>
    );
}

export default SupplyTickets