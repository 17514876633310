import snackBar from "../../../components/SnackBar";
import {getErrors, getViolations} from "../../../utils/axios";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Button as MuiButton,
    Box
} from "@mui/material";
import {Form, Formik} from "formik";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import SubmitButton from "../../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {strawInvoiceService} from "../../../services/strawInvoice.service";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {useDialog} from "../../../feature/DialogProvider";
import {INVOICE_TYPE} from "../../../constants/types";
import Switch from "../../../components/Formik/Switch";
import {chenevisInvoiceService} from "../../../services/chenevisInvoice.service";
import NumberField from "../../../components/Formik/NumberField";
import {run} from "../../../utils/services";

const GenerationDialog = ({open, catalog, setFilters}) => {
    const INITIAL_FORM_STATE = {
        invoice_type: null,
        article_id: null,
        from_member_id: null,
        to_member_id: null,
        contract_year: null,
        invoice_date: null,
        expiry_date: null,
        deposit_number: 3,
        generateMail: false
    }
    const FORM_VALIDATION = Yup.object().shape({});

    const [, closeDialog] = useDialog();

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={INITIAL_FORM_STATE}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(strawInvoiceService.generatePaymentPdf, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar('Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.');
                        setFilters(prevState => ({...prevState}));
                        closeDialog();
                    });
            }}
        >
            {({values}) => (
                <Form>
                    <DialogTitle className="headerModal">
                        <div><h3>Génération</h3></div>
                    </DialogTitle>
                    <DialogContent className={"contentModal"}>
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={values.invoice_type === INVOICE_TYPE.X_DEPOSIT ? 4: 6} className="Grid-grid">
                                    <Select name={'invoice_type'}
                                            label={'Type de facture'}
                                            nullIfEmpty
                                    >
                                        {catalog.invoiceTypes
                                            .filter(el => el !== INVOICE_TYPE.CREDIT && el !== INVOICE_TYPE.APPRO)
                                            .map((el, i) => {
                                                return <MenuItem key={i} value={el}>{el}</MenuItem>
                                            })}
                                    </Select>
                                </Grid>
                                {values.invoice_type === INVOICE_TYPE.X_DEPOSIT &&
                                <Grid item xs={12} md={2}>
                                    <NumberField name={'deposit_number'}
                                                 label={'N° acc.'}
                                                 type={'number'}
                                                 nullIfEmpty
                                    />
                                </Grid>}
                                {values.invoice_type !== INVOICE_TYPE.CREDIT &&
                                    <>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            {![INVOICE_TYPE.SUBSCRIPTION, INVOICE_TYPE.TRANSPORT].includes(values.invoice_type) &&
                                                <Select name={'article_id'} label={'Article'}>
                                                    {catalog.articles.map((el, i) => {
                                                        return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                                                    })}
                                                </Select>}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AutocompleteAsync
                                                name={'from_member_id'}
                                                label={'De l\'adhérent...'}
                                                filterName={'company'}
                                                request={memberService.getAllMembers}
                                                getOptionLabel={(option) => `${option.company} ${option.code}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                filtersOnLoading={{'order[company]': 'ASC'}}
                                                hydra
                                                className="Autocomplete"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AutocompleteAsync
                                                name={'to_member_id'}
                                                label={'A l\'adhérent...'}
                                                filterName={'company'}
                                                request={memberService.getAllMembers}
                                                getOptionLabel={(option) => `${option.company} ${option.code}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                                hydra
                                                className="Autocomplete"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            <SelectYear
                                                name={'contract_year'}
                                                label={'Année'}
                                                end={new Date().getFullYear() + 3}
                                                reverse
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}/>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'invoice_date'} label={'Date de facture'} nullIfEmpty/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'expiry_date'} label={'Date d\'échéance'} nullIfEmpty/>
                                        </Grid>
                                    </>}
                                {values.invoice_type === INVOICE_TYPE.CREDIT &&
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'invoice'}
                                            label={'Facture...'}
                                            filterName={'number'}
                                            request={chenevisInvoiceService.getAllChevenisInvoices}
                                            getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            hydra
                                            className="Autocomplete"
                                        />
                                    </Grid>}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default GenerationDialog;