import ReferentialTable from "../../../../../components/Parameters/ReferentialTable";
import {chenevisKilometricDryerService} from "../../../../../services/chenevisKilometricDryer.service";
import {ENTITY} from "../../../../../constants/entity";

const KilometricDryer = () => {
    const columns = [
        {field: 'year', headerName: 'Année', flex: 0.8},
        {field: 'startScale', headerName: 'De', flex: 1},
        {field: 'endScale', headerName: 'À', flex: 1},
        {field: 'amount', headerName: 'Indemnité (€/T)', flex: 1},
    ];

    return <ReferentialTable
        columns={columns}
        callback={chenevisKilometricDryerService.getAllChenevisKilometricDryers}
        addLabel={'Ajouter un barème'}
        cloneEntity={String(ENTITY.CHENEVIS_KILOMETRIC_DRYER.namespace)}
    />
}

export default KilometricDryer;